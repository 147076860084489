import React from 'react';
import { AccountCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { TestID } from 'testID';

export const UserMenuButton: React.FC<{
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isOpen: boolean;
}> = ({ handleOpenMenu, isOpen }) => (
  <IconButton
    size="small"
    aria-label="user menu"
    aria-controls="menu-appbar"
    aria-haspopup="true"
    onClick={handleOpenMenu}
    data-testid={TestID.ToggleUserMenuButton}
    color="info"
  >
    <AccountCircle sx={{ fontSize: 40 }} color={isOpen ? 'action' : 'info'} />
  </IconButton>
);
