import React from 'react';
import { TestID } from 'testID';
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog.view';

export const DisconnectDialog: React.FC<{
  open: boolean;
  connectorName: string;
  handleClose: () => void;
  onDisconnect: () => void;
  loading: boolean;
}> = ({ open, connectorName, handleClose, onDisconnect, loading }) => (
  <ConfirmDialog
    open={open}
    destructive={true}
    title="Disconnect"
    description={`Are you sure you want to permanently disconnect ${connectorName}?`}
    cancelText="Cancel"
    confirmText="Disconnect"
    handleClose={handleClose}
    handleConfirm={onDisconnect}
    loading={loading}
    testId={TestID.DisconnectDialog}
  />
);
