import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { logger } from 'utils';
import { getZendeskToken } from 'api';
import { ZendeskToken } from 'types';

export const useZendesk = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true;
    if (isAuthenticated && window.zE) {
      window.zE('messenger', 'loginUser', (callback: ZendeskCallbackFn) => {
        getAccessTokenSilently()
          .then((auth0Token) => {
            return getZendeskToken(auth0Token);
          })
          .then((zendeskToken: ZendeskToken) => {
            if (isMounted && callback) {
              callback(zendeskToken?.token);
            }
          })
          .catch(() => {
            logger.error('An error occurred when trying to fetch the zendesk token.');
          });
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated, getAccessTokenSilently]);
};
