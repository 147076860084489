import { CompactFlowApiKeyPage } from './CompactFlowApiKey.page';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Stack } from '@mui/material';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { useFetchTenant } from 'hooks/useFetchTenant';
import {
  calculateCompactFlowSecondConnectorAction,
  getConnectorAuthType,
  getSendEventToGAFn,
} from 'utils';
import { oauth2Login } from 'api';
import { FullScreenLoader } from 'views';
import { TestID } from 'testID';
import { CompactFlowActions } from 'enums';

const sendEventToGA = getSendEventToGAFn();

const getGATitle = (connectorId: string) =>
  `user start connect to ${connectorId} as second connector via compact onboarding flow.`;

/**
 * Wrapper that responsible to detect and trigger second oAuth2 connector as part of the compact flow.
 */
const CompactFlowSecondConnectorPage: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { tenant, status: fetchTenantStatus } = useFetchTenant();
  const { connectors, status: fetchConnectorsStatus } = useFetchConnectors();
  const urlParams = useParams();
  const secondConnectorId = urlParams.secondConnectorId;
  const nextConnector = tenant?.ui_state?.next_connector ?? '';
  const isCompact = tenant?.ui_state?.is_compact ?? false;
  const connectorType = getConnectorAuthType(connectors?.[nextConnector ?? '']);
  const compactFlowAction = calculateCompactFlowSecondConnectorAction({
    nextConnector,
    isCompact,
    secondConnectorId: secondConnectorId,
    fetchTenantStatus,
    fetchConnectorsStatus,
    connectorType: connectorType ?? 'none',
    connectors: connectors ?? {},
  });

  useEffect(() => {
    const oAuth2ConnectFlow = (connectorId: string) => {
      sendEventToGA({
        action: tenant?.ui_state?.next_connector
          ? getGATitle(tenant?.ui_state?.next_connector)
          : getGATitle(connectorId),
        category: 'connect step',
        label: 'Compact connect flow: Second connector',
      });

      getAccessTokenSilently({
        authorizationParams: {
          scope: 'write:connectors',
        },
      }).then(async (token) => {
        return await oauth2Login({
          connectorId,
          token,
          redirectPath: '/',
          accountId: '',
          additionalValues: {},
        });
      });
    };

    if (
      compactFlowAction === CompactFlowActions.StartOAuth2SecondCompactFlow &&
      tenant?.ui_state?.next_connector
    ) {
      oAuth2ConnectFlow(tenant.ui_state.next_connector);
    }
  }, [
    compactFlowAction,
    getAccessTokenSilently,
    urlParams.secondConnectorId,
    tenant?.ui_state?.next_connector,
  ]);

  if (compactFlowAction === CompactFlowActions.StartSecondApiKeyCompactFlow) {
    return (
      <Stack justifyContent="center" alignItems="center" sx={{ height: '80vh' }}>
        <Stack sx={{ width: '50%' }}>
          {connectors?.[nextConnector] && (
            <CompactFlowApiKeyPage connector={connectors?.[nextConnector]} />
          )}
        </Stack>
      </Stack>
    );
  }

  if (
    compactFlowAction === CompactFlowActions.DisplayLoader ||
    compactFlowAction === CompactFlowActions.StartOAuth2SecondCompactFlow
  ) {
    return <FullScreenLoader testId={TestID.CompactOnboardingFlowPage} />;
  }

  return <>{children}</>;
};

const Wrapped = withAuthenticationRequired(CompactFlowSecondConnectorPage, {
  onRedirecting: () => <FullScreenLoader />,
  returnTo: window.location.pathname.replace('/onboarding', '') + window.location.search,
});

export { Wrapped as CompactFlowSecondConnectorPage };
