import React from 'react';
import { Box, Stack } from '@mui/material';
import { PageTitle } from 'views/PageTitle/PageTitle.view';

export const ConnectFlowHeader: React.FC<{ connectorTitle: string; connectorLogo: string }> = ({
  connectorTitle,
  connectorLogo,
}) => (
  <Stack direction="row" alignItems="center" gap={1} mb={4}>
    <Box sx={{ width: '4rem' }}>
      <img src={connectorLogo} alt={connectorTitle} width="100%" />
    </Box>
    <PageTitle gutterBottom>{connectorTitle}</PageTitle>
  </Stack>
);
