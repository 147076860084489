import React, { useEffect } from 'react';
import { Location } from 'react-router-dom';
import { AlertColor, Stack, Typography } from '@mui/material';
import { useConnectors } from 'hooks/useConnectors';
import { getSupportedConnectors } from 'utils';
import { snackbarSignal } from 'signals/snackbars.signal';
import { AddConnectorCardContainer } from './AddConnectorCard.container';
import { DashboardCardsContainer } from './DashboardCards.container';
import { DashboardCardsWrapper, DashboardSectionHeader } from 'views';
import { TestID } from 'testID';
import { Connectors } from 'types/connector';
import { Tenant } from 'types/tenant';

export const DashboardContainer: React.FC<{
  location: Location;
  tenantInfo: Tenant;
  connectors: Connectors;
}> = ({ location, tenantInfo, connectors }) => {
  const vendorId = tenantInfo?.vendor_config?._id;
  const tenantId = tenantInfo?.vendor_info?.connector_id;
  const secondConnectorId = tenantInfo?.ui_state?.next_connector || '';

  const showSnackMessage = (message: string, severity: AlertColor) => {
    snackbarSignal.emit({
      open: true,
      message,
      severity,
      testId: TestID.ConnectorDisconnectedSuccessfullySnackbarMessage,
    });
  };

  useEffect(() => {
    if (location.state) {
      Object.keys(location.state).forEach((key) => {
        const message = location.state[key];
        showSnackMessage(message, key as AlertColor);
      });
    }
  }, [location.state]);

  const {
    connectedConnectors,
    availableConnectors,
    noConnectorsFound,
    showConnectedConnectors,
    showDisconnectedConnectors,
  } = useConnectors(connectors, vendorId);

  if (noConnectorsFound) {
    return <Typography data-testid={TestID.NoConnectorsFoundLabel}>No systems found.</Typography>;
  }

  return (
    <Stack>
      <Stack ml={{ xs: '0' }} mb={10} data-testid={TestID.ConnectedConnectorsSection}>
        {showConnectedConnectors && (
          <DashboardSectionHeader
            title="Connected systems"
            description="These systems have already been connected."
          />
        )}
        <Stack direction="row">
          <AddConnectorCardContainer
            connectors={connectors}
            tenantId={tenantId}
            secondConnectorId={secondConnectorId}
            connectedConnectors={connectedConnectors}
          />
        </Stack>
      </Stack>
      {showDisconnectedConnectors && (
        <Stack id={TestID.AvailableSystemsSection}>
          <DashboardSectionHeader
            title="Available systems"
            description="Connect to these systems to synchronise your data."
            titleTestid={TestID.HeaderAvailableSystem}
            descriptionTestid={TestID.ConnectInstructionsLabel}
          />
          <DashboardCardsWrapper testId={TestID.AvailableSystemsSection}>
            <DashboardCardsContainer
              connectors={getSupportedConnectors(
                availableConnectors,
                connectors[vendorId]?.vendor?.supported_connectors
              )}
            />
          </DashboardCardsWrapper>
        </Stack>
      )}
    </Stack>
  );
};
