import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConnectFlowStepper } from 'hooks/useConnectFlowStepper';
import { useTOSAccepted } from 'hooks/useTOSAccepted';
import { connectFlowStepsSignal } from 'signals/connectFlowSteps.signal';
import { ConnectFlowPageWrapperContainer } from 'containers/ConnectFlowPageWrapper.container';
import { useContextVendor } from 'containers/ValidConnectFlowRoute.container';
import { ConnectFlowSummary } from 'views';
import { ConnectFlowSteps } from 'enums';

export const ConnectFlowSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { connectors, tenant: tenantInfo, connectorId } = useContextVendor();
  const connector = connectors[connectorId || ''];
  const hasVendor = Boolean(tenantInfo?.vendor_info);
  const isReauthoriseFlow = location?.state?.isReauthoriseFlow;
  const tosAccepted = useTOSAccepted();
  const connectFlowStepper = useConnectFlowStepper(
    hasVendor,
    isReauthoriseFlow,
    tosAccepted,
    ConnectFlowSteps.Summary
  );

  useEffect(() => {
    connectFlowStepsSignal.emit(ConnectFlowSteps.Summary);
  }, [connectFlowStepper]);

  const onConnectorSummaryClick = () => {
    connectFlowStepper().resetState();
    navigate('/');
  };

  const connectorTitle = connector?.title;

  return (
    <ConnectFlowPageWrapperContainer
      step={connectFlowStepper().getStepIndex()}
      connectorTitle={connectorTitle}
      hasVendor={hasVendor}
      showScreenLoader={false}
    >
      <ConnectFlowSummary
        onClick={onConnectorSummaryClick}
        connectorTitle={connectorTitle}
        vendorTitle={tenantInfo?.vendor_config?.title}
      />
    </ConnectFlowPageWrapperContainer>
  );
};
