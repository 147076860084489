import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { TestID } from 'testID';
import { lightTheme } from 'themes';

export const DatatypeEntity: React.FC<{
  connectorIcon: string;
  vendorName?: string;
}> = ({ connectorIcon, vendorName }) => (
  <Stack
    style={{ backgroundColor: lightTheme.palette.background.lighterGrey }}
    flexDirection="row"
    display="inline-flex"
    alignItems="center"
    p={1}
    mr={2}
    mb={2}
    sx={{
      width: '150px',
      [lightTheme.breakpoints.down('md')]: {
        width: '120px',
      },
      height: '50px',
    }}
    data-testid={TestID.DatatypeEntity}
  >
    <Box
      component="img"
      sx={{ maxWidth: '1.5rem', objectFit: 'contain' }}
      src={connectorIcon}
      alt="Vendor"
      loading="lazy"
      mr={1}
    />
    <Typography variant="body1" title={vendorName} noWrap={true}>
      {vendorName}
    </Typography>
  </Stack>
);
