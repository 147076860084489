import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getConnectorAccountInfo } from 'api';
import { ConnectorAccountInfo, ConnectorAccountInfoResponse } from 'types/connectorAccountInfo';

export const useFetchConnectorAccountInfo = (connectorId: string) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const queryClient = useQueryClient();

  const useQueryResult = useQuery({
    queryKey: ['ConnectorAccountInfo'],
    enabled: isAuthenticated,
    retry: false,
    queryFn: () =>
      getAccessTokenSilently({
        authorizationParams: {
          scope: 'read:connectors',
        },
      })
        .then((token) => getConnectorAccountInfo(token, connectorId))
        .then(
          (connectorAccountInfo: ConnectorAccountInfoResponse): ConnectorAccountInfo => ({
            maskedApiKey: connectorAccountInfo?.masked_api_key,
          })
        ),
  });

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['ConnectorAccountInfo'] });
  };

  return { ...useQueryResult, connectorAccountInfo: useQueryResult.data, invalidate };
};
