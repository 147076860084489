import { useEffect } from 'react';
import capitalize from 'lodash/capitalize';

export const useConnectDocumentTitle = (banner?: string) => {
  const pathname = window.location.pathname;

  useEffect(() => {
    const parts = pathname.split('/').filter(Boolean).map(capitalize);
    const title = [...parts, banner ?? 'Sesam Talk'].join(' | ');
    document.title = title;
  }, [banner, pathname]);
};
