import { useIsAnHourPastSinceConnection } from './useIsAnHourPastSinceConnection';
import React from 'react';
import { Box } from '@mui/material';
import get from 'lodash/get';
import { useInitialSystemStatus } from 'hooks/useInitialSystemStatus';
import { useRuntimeInfo } from 'hooks/useRuntimeInfo';
import { getSystemStatus, useSystemStatus } from 'hooks/useSystemStatus';
import {
  getFirstAccount,
  makeFilterHiddenEntityTypes,
  shouldDisplayInitialSyncInProgressMessage,
} from 'utils';
import { DatatypesContainer } from './Datatypes.container';
import { ConnectorStatus, Section, SyncPageVendorText, SynchronisingText } from 'views';
import { Connectors } from 'types/connector';
import { ModelMapping } from 'types/modelMapping';
import { Tenant } from 'types/tenant';
import { HiddenEntityTypeOptions } from 'types/vendor';
import { SystemStatus } from 'enums';

export const ConnectorSynchronisationContainer: React.FC<{
  connectorId: string;
  connectors: Connectors;
  tenantInfo: Tenant;
  modelMapping: ModelMapping;
  invalidateConnectors: () => void;
}> = ({ connectorId, connectors, tenantInfo, modelMapping, invalidateConnectors }) => {
  const connector = connectors?.[connectorId];
  const connectorTitle = connector?.title;

  const baseConnectorId = get(connector, 'connector_base', connectorId);
  const firstAccount = getFirstAccount(connector?.accounts);
  const { runtimeData, systemStatus } = useRuntimeInfo(firstAccount);
  const systemState = useSystemStatus(systemStatus);

  const vendorId = tenantInfo?.vendor_config?._id;
  const baseVendorConnectorId = get(tenantInfo, 'vendor_config.connector_base', vendorId);
  const vendorTitle = connectors[vendorId]?.vendor?.title;
  const isVendorConnector = connectorId === vendorId;

  const vendorDatatypes = connectors?.[vendorId]?.['available_datatypes'] ?? {};
  const availableDatatypes = connectors?.[connectorId]?.['available_datatypes'] ?? {};
  const hiddenEntityTypeOptions: HiddenEntityTypeOptions =
    connectors?.[vendorId]?.vendor?.['hidden_entity_type_options'] ?? [];
  const datatypes = firstAccount?.datatypes;
  const runtimeInfo = firstAccount?.['runtime_info'];
  const vendorAccountId = tenantInfo?.vendor_info?.account_id;
  const accountId = firstAccount?.['account_id'] ?? vendorAccountId;
  const timestamp = accountId && connector?.accounts?.[accountId]?.audit?.configured_by?.timestamp;
  const isAnHourPastSinceConnection = useIsAnHourPastSinceConnection(timestamp);
  const filterHiddenEntityTypes = makeFilterHiddenEntityTypes(
    get(connector, 'hidden_entity_types', [])
  );

  const systemStateToDisplay = useInitialSystemStatus({
    isAnHourPastSinceConnection,
    systemStatus,
    systemState,
  });

  return (
    <Box>
      {isVendorConnector && <SyncPageVendorText connectorTitle={connectorTitle} />}
      <ConnectorStatus systemState={systemStateToDisplay} />
      {shouldDisplayInitialSyncInProgressMessage({
        isAnHourPastSinceConnection,
        vendorId,
        connectorId,
        systemStatus,
      }) && (
        <ConnectorStatus
          systemState={getSystemStatus(SystemStatus.InitialSync)}
          readMoreButton={true}
        />
      )}
      <Section header="Entity type status" mt={2}>
        <SynchronisingText
          isVendorConnector={isVendorConnector}
          vendorTitle={vendorTitle}
          connectorTitle={connectorTitle}
        />
        <DatatypesContainer
          vendorTitle={vendorTitle}
          filterHiddenEntityTypes={filterHiddenEntityTypes}
          datatypes={datatypes}
          availableDatatypes={availableDatatypes}
          modelMapping={modelMapping}
          baseConnectorId={baseConnectorId}
          vendorId={vendorId}
          baseVendorConnectorId={baseVendorConnectorId}
          runtimeData={runtimeData}
          vendorDatatypes={vendorDatatypes}
          connectorId={connectorId}
          invalidateConnectors={invalidateConnectors}
          runtimeInfo={runtimeInfo}
          hiddenEntityTypeOptions={hiddenEntityTypeOptions}
        />
      </Section>
    </Box>
  );
};
