import React, { useEffect, useState } from 'react';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { Banner, BannerWrapper } from 'views';
import type { BannerState } from 'types';

export const ReauthoriseBannerContainer: React.FC = () => {
  const [bannerState, setBannerState] = useState<BannerState[]>([]);
  const { connectors, connectorsLoading } = useFetchConnectors();

  useEffect(() => {
    if (!connectorsLoading && connectors) {
      const needAttentionBannerState = Object.values(connectors).map((connector) => {
        return connector.reauthoriseState;
      });

      const cleaned = needAttentionBannerState.filter(Boolean) as BannerState[];

      if (cleaned) {
        setBannerState(cleaned);
      }
    }
  }, [connectors, connectorsLoading]);

  if (!bannerState?.length) {
    return null;
  }

  return (
    <BannerWrapper>
      <Banner
        key={bannerState[0].id}
        text={bannerState[0].text}
        primaryActionText={bannerState[0].primaryActionText}
        onPrimaryAction={bannerState[0].onPrimaryAction}
        secondaryActionText={bannerState[0].secondaryActionText}
        onSecondaryAction={bannerState[0].onSecondaryAction}
        testId={bannerState[0].testId}
      />
    </BannerWrapper>
  );
};
