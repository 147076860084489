import React from 'react';
import { Typography } from '@mui/material';
import { Tooltip } from '../Tooltip/Tooltip.view';

export const SinceTimestampText: React.FC<{ timestamp: string; since: string }> = ({
  timestamp,
  since,
}) => (
  <Tooltip title={new Date(timestamp).toLocaleString('en-GB')} placement="top">
    <Typography component="span">{since}</Typography>
  </Tooltip>
);
