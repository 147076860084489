import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, FormGroup, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useBuildAdditionalProperties } from '../hooks/useBuildAdditionalProperties';
import {
  getAPIAuthFormText,
  getAdditionalPropertyValueFn,
  getSystemConnectorUrl,
  isAdditionalValuesValid,
} from 'utils';
import { CustomSectionsContainer } from './CustomSections.container';
import { ConnectFlowButtons, ConnectorAuthorisationSectionHeader, ConnectorDetails } from 'views';
import { AdditionalPropertiesInputs } from 'views/AdditionalPropertiesInputs/AdditionalPropertiesInputs.view';
import { TestID } from 'testID';
import { AdditionalProperties, ConnectFlowCustomSection, Connector } from 'types/connector';

export const ConnectApikeyContainer: React.FC<{
  connector: Connector;
  onConnect: (apiKey: string, additionalValues: AdditionalProperties) => void;
  vendorTitle: string;
  hasVendor: boolean;
  skipApiKey: boolean;
  isReauthoriseFlow: boolean;
  showButtonLoader: boolean;
  customSections?: ConnectFlowCustomSection[];
}> = ({
  connector,
  onConnect,
  showButtonLoader,
  vendorTitle,
  hasVendor,
  skipApiKey,
  isReauthoriseFlow,
  customSections,
}) => {
  const navigate = useNavigate();
  const [apiKey, setApiKey] = useState('');
  const [firstBlur, setFirstBlur] = useState(false);
  const [additionalValues, setAdditionalValues] = useState<AdditionalProperties>({});
  const additionalProperties = get(connector, 'additional-properties');
  const auth = connector.auth;
  const connectorLogo = connector?.logo_symbol ?? connector?.logo;

  const getAdditionalPropertyValue = getAdditionalPropertyValueFn(connector);

  const readOnlyAdditionalValues = useBuildAdditionalProperties(
    isReauthoriseFlow,
    additionalProperties,
    getAdditionalPropertyValue
  );

  useEffect(
    () => () => {
      setApiKey('');
      setAdditionalValues({});
    },
    []
  );

  const isFormValid = () => {
    if (apiKey === '' && !skipApiKey) {
      return false;
    }

    if (isReauthoriseFlow) {
      return isAdditionalValuesValid(additionalProperties, readOnlyAdditionalValues);
    }

    if (isEmpty(additionalProperties)) {
      return true;
    }

    for (const key in additionalProperties) {
      if (!get(additionalValues, `${key}.value`)) {
        return false;
      }
    }

    return true;
  };

  const connect = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    onConnect(apiKey, isReauthoriseFlow ? readOnlyAdditionalValues : additionalValues);
  };

  const authFormText = getAPIAuthFormText({
    label: auth.label,
    connectorName: connector?.title,
    connectorId: connector?._id,
  });

  return (
    <Box>
      <Stack>
        <ConnectorDetails
          connectorTitle={connector?.title}
          connectorLogo={connectorLogo}
          vendorTitle={vendorTitle}
          hasVendor={hasVendor}
          isReauthoriseFlow={isReauthoriseFlow}
        />
        <CustomSectionsContainer customSections={customSections} />
        <ConnectorAuthorisationSectionHeader
          linkToDocumentation={
            connector.docs_url ? connector.docs_url : getSystemConnectorUrl(connector._id)
          }
          connectorTitle={connector?.title}
          isReauthoriseFlow={isReauthoriseFlow}
        />
        <form>
          <FormGroup>
            {skipApiKey ? (
              <Typography variant="body1" gutterBottom>
                {authFormText.skipApiKeyText}
              </Typography>
            ) : (
              <TextField
                autoFocus
                onBlur={() => setFirstBlur(true)}
                margin="normal"
                id="apiKey"
                required
                label={authFormText.inputText}
                type="text"
                variant="outlined"
                value={apiKey}
                error={!apiKey && firstBlur}
                onChange={({ target: { value } }) => setApiKey(value)}
                data-testid={TestID.APIKeyTextField}
                disabled={showButtonLoader}
              />
            )}
            <AdditionalPropertiesInputs
              additionalProperties={additionalProperties}
              additionalValues={additionalValues}
              getAdditionalPropertyValue={getAdditionalPropertyValue}
              isReauthoriseFlow={isReauthoriseFlow}
              firstBlur={firstBlur}
              setFirstBlur={setFirstBlur}
              setAdditionalValues={setAdditionalValues}
              shouldDisable={showButtonLoader}
            />
            <ConnectFlowButtons
              onConnect={connect}
              onCancel={() => navigate('/')}
              isConnectButtonDisabled={!isFormValid()}
              showButtonLoader={showButtonLoader}
              isReauthoriseFlow={isReauthoriseFlow}
            />
          </FormGroup>
        </form>
      </Stack>
    </Box>
  );
};
