import React from 'react';
import { Stack, Typography } from '@mui/material';
import type { Datatypes } from 'types/connector';
import type { DatatypeRelationsArray, ModelMapping } from 'types/modelMapping';
import { DatatypesCardRelations } from './DatatypeCardRelations.view';
import { Link } from '../Link/Link.view';

export const DatatypeCardContent: React.FC<{
  description?: string;
  relations: DatatypeRelationsArray;
  vendorSystem: string;
  system: string;
  vendorId: string;
  vendorTitle: string;
  vendorDatatypes?: Datatypes;
  modelMapping: ModelMapping;
}> = ({
  description,
  relations,
  vendorSystem,
  system,
  vendorId,
  vendorTitle,
  vendorDatatypes,
  modelMapping,
}) => {
  return (
    <>
      <Typography color={'text.secondary'}>{description}</Typography>
      {relations && vendorSystem !== system && (
        <Stack mb={2} mt={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography gutterBottom>
              Maps to these entity types in <Link to={`/connector/${vendorId}`}>{vendorTitle}</Link>
              :
            </Typography>
          </Stack>
          <DatatypesCardRelations
            relations={relations}
            vendorDatatypes={vendorDatatypes}
            vendorSystem={vendorSystem}
            modelMapping={modelMapping}
          />
        </Stack>
      )}
    </>
  );
};
