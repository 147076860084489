import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchConnectorAccountInfo } from 'hooks/useFetchConnectorAccountInfo';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { useFetchTenant } from 'hooks/useFetchTenant';
import { ConnectorConfigurationContainer } from 'containers/ConnectorConfiguration.container';
import { ErrorView, FullScreenLoader } from 'views';
import { ErrorMessages } from 'commonConstants';

export const ConnectorConfigurationPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const connectorId = params.connectorId;

  const { connectors, connectorsLoading, invalidate: invalidateConnectors } = useFetchConnectors();
  const { tenant, isLoading, invalidate: invalidateTenant } = useFetchTenant();
  const { connectorAccountInfo, isLoading: isConnectorAccountInfoLoading } =
    useFetchConnectorAccountInfo(connectorId ?? '');

  const refresh = () => {
    invalidateConnectors();
    invalidateTenant();
  };

  if (connectorsLoading || isLoading || isConnectorAccountInfoLoading) {
    return <FullScreenLoader height="65vh" />;
  }

  if (!connectorId) {
    return (
      <ErrorView
        errorMessage={ErrorMessages.ConnectorIdNotFound}
        actionButtonText="Go back"
        onActionClick={() => navigate('/')}
      />
    );
  }
  if (!connectors) {
    return <ErrorView errorMessage={ErrorMessages.ConnectorsNotLoaded} onActionClick={refresh} />;
  }
  if (!tenant) {
    return <ErrorView errorMessage={ErrorMessages.TenantNotLoaded} onActionClick={refresh} />;
  }

  return (
    <ConnectorConfigurationContainer
      navigate={navigate}
      connectorId={connectorId}
      connectors={connectors}
      tenantInfo={tenant}
      connectorAccountInfo={connectorAccountInfo}
    />
  );
};
