import React from 'react';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { useFetchTenant } from 'hooks/useFetchTenant';
import { useScrollToPageTop } from 'hooks/useScrollToPageTop';
import { MyAccount } from 'containers/MyAccount.container';
import { CircularLoading, ErrorView } from 'views';
import { ErrorMessages } from 'commonConstants';

export const MyAccountPage = () => {
  const {
    connectors,
    connectorsLoading: isConnectorsLoading,
    invalidate: invalidateConnectors,
  } = useFetchConnectors();
  const { tenant, isLoading: isTenantLoading, invalidate: invalidateTenant } = useFetchTenant();

  useScrollToPageTop<boolean>(isConnectorsLoading || isTenantLoading);

  const refresh = () => {
    invalidateConnectors();
    invalidateTenant();
  };

  if (isConnectorsLoading || isTenantLoading) {
    return <CircularLoading />;
  }

  if (!connectors) {
    return <ErrorView errorMessage={ErrorMessages.ConnectorsNotLoaded} onActionClick={refresh} />;
  }

  if (!tenant) {
    return <ErrorView errorMessage={ErrorMessages.TenantNotLoaded} onActionClick={refresh} />;
  }

  return <MyAccount tenant={tenant} connectors={connectors} />;
};
