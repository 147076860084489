import React from 'react';
import { Typography } from '@mui/material';
import { TestID } from 'testID';
import { Link } from '../Link/Link.view';

export const ConnectorAuthorisationSectionHeader: React.FC<{
  connectorTitle: string;
  linkToDocumentation?: string;
  isReauthoriseFlow?: boolean;
}> = ({ connectorTitle, linkToDocumentation, isReauthoriseFlow = false }) => (
  <>
    <Typography variant="h5" gutterBottom>
      Authorisation
    </Typography>
    <Typography variant="body1" gutterBottom>
      Please fill out the fields below to {isReauthoriseFlow ? 'reconnect' : 'connect'} your{' '}
      {connectorTitle} account.{' '}
      {linkToDocumentation && (
        <Link to={linkToDocumentation} target="_blank" data-testid={TestID.ConnectApiKeyInfoLink}>
          Where do I find this information?
        </Link>
      )}
    </Typography>
  </>
);
