import React, { PropsWithChildren } from 'react';
import { Grid } from '@mui/material';

export const DashboardCardsWrapper: React.FC<PropsWithChildren & { testId?: string }> = ({
  children,
  testId,
}) => (
  <Grid
    container
    mt={0}
    spacing={4}
    justifyContent={{ xs: 'center', md: 'center' }}
    data-testid={testId}
  >
    {children}
  </Grid>
);
