import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { TestID } from 'testID';
import { AccountOptions } from 'enums';
import { Section } from '../Section/Section.view';

export const DoYouHaveAWaveAccountSection: React.FC<{
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ handleRadioChange }) => (
  <Section
    showBorder
    header="Do you have a Wave account?"
    data-testid={TestID.DoYouHaveAWaveAccountSection}
  >
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="female"
      name="radio-buttons-group"
      onChange={handleRadioChange}
    >
      <FormControlLabel
        value={AccountOptions.HaveAccount}
        control={<Radio size="small" />}
        label="Yes"
        data-testid={TestID.WaveAdvanceConnectFlowHaveAccountRadioButton}
      />
      <FormControlLabel
        value={AccountOptions.DoNotHaveAccount}
        control={<Radio size="small" />}
        label="No"
        data-testid={TestID.WaveAdvanceConnectFlowDoNotHaveAccountRadioButton}
      />
    </RadioGroup>
  </Section>
);
