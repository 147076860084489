import { NavigateFunction } from 'react-router-dom';
import datejs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { SesamTalkLinks } from 'commonConstants';

datejs.extend(relativeTime);

export const stringCompare = (a: string, b: string): number => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const getBasename = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return '/';
  }
  return '/onboarding';
};

export const getFormatNumber = (formatter: Intl.NumberFormat) => (amount: number) => {
  if (!formatter) {
    throw new TypeError('Number formatter is undefined!');
  }
  return formatter.format(amount);
};

export const formatNumber = getFormatNumber(new Intl.NumberFormat());

export const getTimeSince = (timestamp: string) => datejs().to(timestamp);

export const getSystemConnectorUrl = (
  connectorId: string,
  SesamTalkDocsUrl: string = SesamTalkLinks.SesamTalkDocs
) => `${SesamTalkDocsUrl}/systems/${connectorId}.html`;

export const isTimePassedSince = (args: {
  nowTime: number;
  timeRange: number;
  startTime: number;
}) => (args.nowTime - args.startTime < args.timeRange ? false : true);

export async function sha256(message: string): Promise<string> {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

type NavigateToPreviousPageFnArgs = {
  navigate: NavigateFunction;
  location: Location;
  vendorId?: string;
  homepage: {
    connectorId: string;
    url: string;
  };
};

export const getNavigateToPreviousPageFn =
  (args: NavigateToPreviousPageFnArgs) => (referrer: string, connectorId?: string) => {
    const { navigate, location, homepage, vendorId } = args;

    if (vendorId === connectorId || (!vendorId && homepage.connectorId === connectorId)) {
      location.href = homepage.url;
    } else {
      referrer ? navigate(-1) : navigate('/');
    }
  };

export const getConnectorIdFromURL = (path: string) => {
  const match = path.match(/\/(\w+)+(-\w+)*\/connect/);

  const connectorId = match?.[1] ? match[1] : undefined;

  return match?.[2] ? `${match[1]}${match[2]}` : connectorId;
};
