import React from 'react';
import { Stack, Typography } from '@mui/material';

export const DashboardSectionHeader: React.FC<{
  title: string;
  description: string;
  titleTestid?: string;
  descriptionTestid?: string;
  id?: string;
}> = ({ title, description, titleTestid, descriptionTestid, id }) => (
  <Stack alignItems={{ xs: 'center', md: 'center' }} id={id}>
    <Typography variant="h5" data-testid={titleTestid}>
      {title}
    </Typography>
    <Typography
      variant="body1"
      textAlign={{ xs: 'center', md: 'start' }}
      data-testid={descriptionTestid}
    >
      {description}
    </Typography>
  </Stack>
);
