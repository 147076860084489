import React from 'react';
import { Stack, Typography } from '@mui/material';
import { getApiKeyConnectorFieldName } from 'utils';
import { TestID } from 'testID';
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog.view';

export const ConfirmApiKeyDialog: React.FC<{
  maskedApiKey: string;
  fieldName?: string;
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  loading: boolean;
}> = ({ maskedApiKey, open, fieldName, handleClose, handleConfirm, loading }) => {
  const name = getApiKeyConnectorFieldName(fieldName);

  return (
    <ConfirmDialog
      open={open}
      destructive={true}
      title={`Replace ${name}`}
      description={
        <Stack>
          <Typography>You're about to replace the existing {name}.</Typography>
          <Stack pt={1} pb={1} alignItems="center">
            <Stack justifyContent="center" pt={2}>
              <code>{maskedApiKey}</code>
            </Stack>
          </Stack>
          <Typography sx={{ mt: 1 }}>Are you sure you want to replace the {name}?</Typography>
        </Stack>
      }
      cancelText="Cancel"
      confirmText="Yes"
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      loading={loading}
      testId={TestID.ConfirmApiKeyDialog}
    />
  );
};
