import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AlertColor } from '@mui/material';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { snackbarSignal } from 'signals/snackbars.signal';
import { ConnectorContainer } from 'containers/Connector.container';
import { ConnectorErrorContainer } from 'containers/ConnectorError.container';
import { CircularLoading } from 'views';
import { TestID } from 'testID';

export const ConnectorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const snackMessage = searchParams.get('message');
  const params = useParams();
  const { connectors, connectorsLoading } = useFetchConnectors();
  const connectorId = params?.connectorId;

  const showSnackMessage = (message: string, severity: AlertColor) => {
    snackbarSignal.emit({
      message,
      severity,
      open: true,
      testId: TestID.ConnectorAlreadyConnectedWarningMessage,
    });
  };

  useEffect(() => {
    if (snackMessage) {
      showSnackMessage(snackMessage, 'warning');
    }
  }, [snackMessage]);

  if (snackMessage) {
    return <Navigate to="/" />;
  }

  if (connectorsLoading) {
    return <CircularLoading />;
  }

  if (!connectorId || !connectors || !connectors?.[connectorId || '']?.connected) {
    return <ConnectorErrorContainer connectorId={connectorId} connectors={connectors} />;
  }

  return (
    <ConnectorContainer
      navigate={navigate}
      location={location}
      connectorId={connectorId}
      connectors={connectors}
    />
  );
};
