import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { Button } from 'views';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Grid container direction="column" alignItems="center" pt={5}>
      <Typography variant="h4" gutterBottom>
        Page not found!
      </Typography>

      <Grid item mt={2}></Grid>
      <Button
        variant="contained"
        onClick={() => {
          navigate('/login');
        }}
      >
        Go back to homepage
      </Button>
    </Grid>
  );
};
