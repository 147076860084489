import React from 'react';
import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import type { AlertColor, SnackbarProps } from '@mui/material';

export const Snackbars: React.FC<
  SnackbarProps & {
    testId?: string;
    severity?: AlertColor;
    onClose?: () => void;
  }
> = ({ anchorOrigin, autoHideDuration, open, message, severity = 'info', onClose, testId }) => {
  const handleClose = (_event: React.SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose?.();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      sx={{ mb: 5 }}
      anchorOrigin={anchorOrigin}
      data-testid={testId}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        elevation={6}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
