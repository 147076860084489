import React, { useMemo } from 'react';
import { capitalize } from 'lodash';
import { getDataTypesFromModelMapping } from 'utils';
import { DatatypeEntity } from 'views';
import { ModelMapping } from 'types/modelMapping';

export const DatatypeEntityContainer: React.FC<{
  connectorId: string;
  connectorIcon: string;
  modelMapping: ModelMapping;
  filterHiddenEntityTypes: (datatypeId: string) => boolean;
}> = ({ modelMapping, connectorId, connectorIcon, filterHiddenEntityTypes }) => {
  const modelMappingDatatypes = useMemo(
    () => getDataTypesFromModelMapping(modelMapping, connectorId).filter(filterHiddenEntityTypes),
    [connectorId, filterHiddenEntityTypes, modelMapping]
  );

  return modelMappingDatatypes.map((entity) => (
    <DatatypeEntity connectorIcon={connectorIcon} vendorName={capitalize(entity)} key={entity} />
  ));
};
