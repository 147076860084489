import React from 'react';
import { NavigateFunction, Outlet } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import Container from '@mui/material/Container';
import { HeaderContainer } from 'containers/Header.container';
import { ReauthoriseBannerContainer } from 'containers/ReauthoriseBanner.container';
import { AppFooter } from '../AppFooter/AppFooter.view';
import { ErrorBoundaryWithTheme } from '../ErrorBoundary/ErrorBoundary.view';

export const MainLayout: React.FC<{ navigate: NavigateFunction }> = ({ navigate }) => (
  <ErrorBoundaryWithTheme navigate={navigate}>
    <Stack direction="column" justifyContent={'space-between'} sx={{ height: '100%' }}>
      <Box mb={3}>
        <HeaderContainer />
        <ReauthoriseBannerContainer />
        <Container maxWidth="xl" sx={{ pt: 4, pb: 2 }}>
          <Outlet />
        </Container>
      </Box>
      <AppFooter />
    </Stack>
  </ErrorBoundaryWithTheme>
);
