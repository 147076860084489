import React from 'react';
import { Menu as MUIMenu } from '@mui/material';
import type { MenuProps } from '@mui/material';
import { lightTheme } from 'themes';

export const Menu: React.FC<MenuProps> = ({ ...props }) => (
  <MUIMenu
    {...props}
    sx={{
      '& .MuiPaper-root': {
        backgroundColor: lightTheme.palette.primary.light,
        boxShadow: '0 0 5px 3px rgba(45, 62, 80, 0.12)',
        borderRadius: '8px',
        marginTop: 3,
      },
    }}
  />
);
