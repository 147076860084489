import React from 'react';
import { Typography } from '@mui/material';
import { Section } from '../Section/Section.view';
import { SinceTimestampText } from '../SinceTimestampText/SinceTimestampText.view';

export const AuthorisationSection: React.FC<{
  accountId: string;
  since: string;
  timestamp: string;
  userEmail: string;
}> = ({ accountId, since, timestamp, userEmail }) => (
  <>
    <Section header="Account" showBorder>
      <Typography variant="body1" gutterBottom>
        Account ID: {accountId}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {userEmail && (
          <>
            {'Authorised '}
            {userEmail && userEmail !== 'None' ? `by ${userEmail}` : ''}{' '}
          </>
        )}
        {timestamp && <SinceTimestampText since={since} timestamp={timestamp} />}
      </Typography>
    </Section>
  </>
);
