// ConnectApikeyContainer
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AlertColor } from '@mui/material';
import { getCompactFlowSecondConnectorURL, getSendEventToGAFn, logger } from 'utils';
import { connectorLogin } from 'api';
import { ConnectApikeyContainer } from 'containers/ConnectApikey.container';
import { AdditionalProperties, Connector } from 'types/connector';
import { TOS_ACCEPT_LOCAL_STORAGE_KEY } from 'commonConstants';

const sendEventToGA = getSendEventToGAFn();

export const CompactFlowApiKeyPage: React.FC<{ connector: Connector }> = ({ connector }) => {
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const params = useParams();
  const secondConnectorId = params.secondConnectorId ?? '';
  const navigate = useNavigate();
  const connectorId = connector._id;
  const connectorType = connector?.auth?.type;
  const shouldSelectAccount = !!connector?.account_selection;

  const connectAPIKeyConnector = async (apiKey: string, additionalValues: AdditionalProperties) => {
    setShowButtonLoader(true);

    sendEventToGA({
      action: `user clicked on connect to ${connectorId} button during api_key compact flow`,
      category: 'api_key',
      label: 'Compact flow',
    });

    let status: AlertColor = 'success';

    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          scope: 'write:connectors',
        },
      });

      const response = await connectorLogin(
        {
          connectorId,
          type: connectorType,
          apiKey,
          additionalValues,
          accountId: '',
          contactInfo: {
            accepted_tos: 'true',
            first_name: '',
            last_name: '',
            company_name: '',
            company_email: '',
          },
          override: false,
        },
        token
      );

      if (response.status !== 200) {
        status = 'error';
      }

      localStorage.setItem(TOS_ACCEPT_LOCAL_STORAGE_KEY, 'true');
      setShowButtonLoader(false);

      if (status !== 'error') {
        if (shouldSelectAccount) {
          const url = secondConnectorId
            ? `/compact/${connectorId}/connect/${secondConnectorId}/select-account`
            : `/compact/${connectorId}/connect/select-account`;

          navigate(url, {
            state: {
              apiKey,
            },
          });
        } else {
          const navigateToUrl = getCompactFlowSecondConnectorURL({
            secondConnectorId,
            env: process.env.NODE_ENV,
          });

          navigate(navigateToUrl);
        }
      }
    } catch (e) {
      logger.error('An error occurred when trying to connect to the server.', e);
    } finally {
      setShowButtonLoader(false);
    }
  };

  return (
    <ConnectApikeyContainer
      connector={connector}
      onConnect={connectAPIKeyConnector}
      vendorTitle={connector.title}
      hasVendor={false}
      skipApiKey={false}
      isReauthoriseFlow={false}
      showButtonLoader={showButtonLoader}
    />
  );
};
