import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import { TestID } from 'testID';
import type { SystemNotifications } from 'types';
import { SystemNotificationsPanelItem } from './SystemNotificationsPanelItem.view';
import { CircularLoading } from '../CircularLoading/CircularLoading.view';
import { Menu } from '../Menu/Menu.view';
import styles from './SystemNotifications.module.scss';

export const SystemNotificationsPanel: React.FC<{
  systemNotifications: SystemNotifications;
  anchorEl?: Element;
  isOpen: boolean;
  handleCloseMenu?: () => void;
  isNotificationLoading: boolean;
  onHasReadToggle?: (notificationId: string, read: boolean) => void;
}> = ({
  anchorEl,
  isOpen,
  handleCloseMenu,
  onHasReadToggle,
  systemNotifications,
  isNotificationLoading,
}) => (
  <Menu
    id="menu-notifications"
    anchorEl={anchorEl}
    open={isOpen}
    onClose={handleCloseMenu}
    classes={{ list: styles.menu }}
    sx={{
      '& .MuiPaper-root': {
        width: '22.5rem',
      },
      '& .MuiList-root MuiMenu-list': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    }}
    PaperProps={{
      style: {
        maxHeight: '90vh',
        width: '22.5rem',
        paddingTop: 0,
        paddingBottom: 0,
      },
    }}
    data-testid={TestID.SystemNotificationPanel}
  >
    {isNotificationLoading ? (
      <MenuItem sx={{ height: '150px' }}>
        <CircularLoading
          text="Loading notifications..."
          size="3rem"
          data-testid={TestID.SystemNotificationsPanelLoader}
        />
      </MenuItem>
    ) : (
      systemNotifications.length === 0 && (
        <MenuItem
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '150px',
          }}
        >
          <Typography variant="body1">No messages to show</Typography>
        </MenuItem>
      )
    )}
    {systemNotifications.map((notification) => (
      <SystemNotificationsPanelItem
        key={notification.id}
        id={notification.id}
        notificationId={notification.id}
        dateTime={notification.time.dateTime}
        since={notification.time.since}
        title={notification.title}
        read={notification.read}
        severity={notification.severity}
        onHasReadToggle={onHasReadToggle}
      >
        {notification.text}
      </SystemNotificationsPanelItem>
    ))}
  </Menu>
);
