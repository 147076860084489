import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';
import { useScrollToPageTop } from 'hooks/useScrollToPageTop';
import { getAdvancedConnectFlowUrl } from 'utils';
import { connectFlowStepsSignal } from 'signals/connectFlowSteps.signal';
import {
  Button,
  DoYouHaveAWaveAccountSection,
  LoginWithYourWaveAccountSection,
  NewToWaveSection,
  NotReadyYetSection,
  NotWaveAdministratorSection,
} from 'views';
import { TestID } from 'testID';
import { AccountOptions, AdvancedConnectFlowTargetElement } from 'enums';
import { WaveSiteLinks } from 'commonConstants';

export const ConnectAccountWizardContainer: React.FC<{
  onConnect: React.MouseEventHandler<HTMLButtonElement>;
  showButtonLoader?: boolean;
  isConnectButtonDisabled: boolean;
  connectorId: string;
}> = ({ onConnect, connectorId, isConnectButtonDisabled, showButtonLoader }) => {
  const [waveAccountOption, setWaveAccountOption] = useState<AccountOptions>();
  const [email, setEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [sendActivateButtonText, setSendActivateButtonText] = useState('Send Activation Request');
  const navigate = useNavigate();

  useScrollToPageTop(waveAccountOption);

  const handleOnConnect: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick('');
    onConnect(event);
  };

  const onClick = (urlToOpen: string) => {
    connectFlowStepsSignal.emit(
      getAdvancedConnectFlowUrl({
        targetElement: AdvancedConnectFlowTargetElement.OpenWindowButton,
        accountOption: waveAccountOption,
        urlToOpen,
      })
    );

    if (urlToOpen) {
      window.open(urlToOpen, '_blank', 'noopener,noreferrer');
    }
  };

  const sendEmailPageViewEvent = () => {
    connectFlowStepsSignal.emit(
      getAdvancedConnectFlowUrl({
        targetElement: AdvancedConnectFlowTargetElement.SendEmailButton,
        email,
      })
    );

    setIsDisabled(true);
    setSendActivateButtonText('Activation Request is sent');
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = (event.target as HTMLInputElement).value as AccountOptions;

    connectFlowStepsSignal.emit(
      getAdvancedConnectFlowUrl({
        targetElement: AdvancedConnectFlowTargetElement.RadioButton,
        accountOption: eventValue,
      })
    );
    setWaveAccountOption(eventValue);
  };

  const onBackButtonClick = () => {
    if (!waveAccountOption) {
      navigate(`/${connectorId}/connect`);
    } else {
      setWaveAccountOption(undefined);
    }
  };

  return (
    <Stack>
      {!waveAccountOption && <DoYouHaveAWaveAccountSection handleRadioChange={handleRadioChange} />}
      {waveAccountOption === AccountOptions.HaveAccount && (
        <>
          <LoginWithYourWaveAccountSection
            handleOnConnect={handleOnConnect}
            isConnectButtonDisabled={isConnectButtonDisabled}
            showButtonLoader={showButtonLoader}
          />
          <NotWaveAdministratorSection
            email={email}
            setEmail={setEmail}
            isDisabled={isDisabled}
            sendActivateButtonText={sendActivateButtonText}
            sendEmailPageViewEvent={sendEmailPageViewEvent}
          />
          <NotReadyYetSection onClick={() => onClick(WaveSiteLinks.SetUpWaveWithATestAccount)} />
        </>
      )}
      {waveAccountOption === AccountOptions.DoNotHaveAccount && (
        <NewToWaveSection
          onClick={() => onClick(WaveSiteLinks.SimpleGuideToGettingStartedWithWave)}
        />
      )}
      <Stack direction="row" spacing={2}>
        <Button onClick={onBackButtonClick} data-testid={TestID.WaveAdvanceConnectFlowBackButton}>
          Back
        </Button>
      </Stack>
    </Stack>
  );
};
