import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ReportIcon from '@mui/icons-material/Report';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SyncIcon from '@mui/icons-material/Sync';
import { Typography, useTheme } from '@mui/material';
import { ReauthoriseButtonContainer } from 'containers/ReauthoriseButton.container';
import { Link } from 'views';
import { TestID } from 'testID';
import type { AllSystemStatus, SystemState, SystemStatuses } from 'types';
import { SystemStatus, SystemTokenStatus } from 'enums';
import { lightTheme } from 'themes';
import { lightInfoBorderColor } from 'themes/variables';

const currentTheme = { current: lightTheme };

export const SystemStatusesTable: SystemStatuses = {
  [SystemStatus.InitialisingSystem]: {
    icon: SyncIcon,
    iconFill: lightInfoBorderColor,
    title: 'Initialising',
    notificationType: 'info',
    testId: TestID.InitialisingSystemStatusIndicator,
  },
  [SystemStatus.Connected]: {
    icon: CheckIcon,
    iconFill: currentTheme.current.palette.success.main,
    title: 'Connected',
    notificationType: 'success',
    testId: TestID.ConnectedSystemStatusIndicator,
  },
  [SystemStatus.NotConnected]: {
    icon: PowerSettingsNewIcon,
    iconFill: currentTheme.current.palette.text.primary,
    title: 'Not connected',
    notificationType: 'plain',
  },
  [SystemStatus.NeedsAttention]: {
    icon: ReportIcon,
    iconFill: currentTheme.current.palette.warning.main,
    title: 'Needs attention',
    description: (
      <Typography>
        The refresh token failed, please{' '}
        <Link relative="route" to="../configure" data-testid={TestID.ReauthoriseNeedsAttentionLink}>
          reauthorise
        </Link>
        .
      </Typography>
    ),
    component: (connectorId: string) => (
      <Typography>
        The refresh token failed, please{' '}
        <ReauthoriseButtonContainer connectorId={connectorId} asLinkButton />.
      </Typography>
    ),
    notificationType: 'attention',
    testId: TestID.NeedsAttentionStatusIndicator,
  },
  [SystemStatus.LimitedService]: {
    icon: ReportIcon,
    iconFill: currentTheme.current.palette.warning.main,
    title: 'Limited service',
    description:
      'Limited functionality due to technical issues. We are working to restore full service.',
    notificationType: 'attention',
    testId: TestID.LimitedServiceStatusIndicator,
  },
  [SystemStatus.LookingIntoIt]: {
    icon: ReportIcon,
    iconFill: currentTheme.current.palette.warning.main,
    title: 'We are looking into it',
    tooltip: 'We are looking into it',
    notificationType: 'attention',
    testId: TestID.LookingIntoItStatusIndicator,
  },
  [SystemTokenStatus.RefreshFailed]: {
    icon: ReportProblemIcon,
    iconFill: currentTheme.current.palette.error.main,
    title: 'Refresh failed',
    notificationType: 'attention',
  },
  [SystemStatus.InitialSync]: {
    icon: ReportIcon,
    iconFill: currentTheme.current.palette.warning.main,
    title: 'Initial synchronisation in progress',
    description: (
      <Typography>
        We're collecting and enhancing your data before syncing it to your systems. Depending on
        data amount and API, this should not take more than a couple of hours.
        <Typography sx={{ mt: 2, display: 'block' }} variantMapping={{ body1: 'span' }}>
          Sit back and let us do the heavy lifting for you!
        </Typography>
      </Typography>
    ),
    notificationType: 'info',
    testId: TestID.InitialSynchronisationInProgressStatusIndicator,
  },
};

export const getSystemStatus = (status: AllSystemStatus): SystemState => {
  const key = status?.toLocaleLowerCase();
  const currentStatus = SystemStatusesTable[key as AllSystemStatus];

  return (
    currentStatus ?? {
      icon: PowerSettingsNewIcon,
      iconFill: lightInfoBorderColor,
      title: status,
      notificationType: 'info',
      testId: TestID.DesignedSystemStatusIndicator,
    }
  );
};

export const useSystemStatus = (status: AllSystemStatus) => {
  const theme = useTheme();

  currentTheme.current = theme;

  return getSystemStatus(status);
};
