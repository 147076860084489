import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { getSendEventToGAFn } from 'utils';
import { oauth2Login } from 'api';
import { FullScreenLoader } from 'views';
import { TestID } from 'testID';
import type { Tenant } from 'types/tenant';

const sendEventToGA = getSendEventToGAFn();

const getGATitle = (connectorId: string) =>
  `user start connect to ${connectorId} via compact onboarding flow.`;

export const CompactFlowFirstConnectorContainer: React.FC<{ tenant: Tenant }> = ({ tenant }) => {
  const params = useParams();
  const currentConnectorId = params.connectorId ?? '';
  const secondConnectorId = params.secondConnectorId;
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let redirectPath = '/';

    if (secondConnectorId) {
      redirectPath = `?resume-signup-flow=${secondConnectorId}&compact=true`;
    }

    const oAuth2ConnectFlow = () => {
      sendEventToGA({
        action: tenant?.ui_state?.next_connector
          ? getGATitle(tenant?.ui_state?.next_connector)
          : getGATitle(currentConnectorId),
        category: 'connect step',
        label: 'Compact connect flow: First connector',
      });

      getAccessTokenSilently({
        authorizationParams: {
          scope: 'write:connectors',
        },
      }).then(async (token) => {
        return await oauth2Login({
          connectorId: currentConnectorId,
          token,
          redirectPath,
          accountId: '',
          additionalValues: {},
        });
      });
    };

    oAuth2ConnectFlow();
  }, [
    currentConnectorId,
    getAccessTokenSilently,
    secondConnectorId,
    tenant?.ui_state?.next_connector,
  ]);

  return <FullScreenLoader testId={TestID.CompactOnboardingFlowPage} />;
};
