import CheckIcon from '@mui/icons-material/Check';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ReportIcon from '@mui/icons-material/Report';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTheme } from '@mui/material';
import { TestID } from 'testID';
import type { SystemState, SystemStatuses } from 'types';
import { DatatypeStatus } from 'enums';
import { lightTheme } from 'themes';
import { lightInfoBorderColor } from 'themes/variables';

const currentTheme = { current: lightTheme };

export const EntityTypeStatusesTable: SystemStatuses = {
  [DatatypeStatus.OK]: {
    icon: CheckIcon,
    iconFill: currentTheme.current.palette.success.main,
    title: 'In sync',
    notificationType: 'success',
    testId: TestID.InSyncEntityTypeStatusIndicator,
  },
  [DatatypeStatus.Failing]: {
    icon: ReportIcon,
    iconFill: currentTheme.current.palette.error.main,
    title: 'Failing',
    notificationType: 'error',
    testId: TestID.FailingEntityTypeStatusIndicator,
  },
  [DatatypeStatus.Initialising]: {
    icon: SyncIcon,
    iconFill: lightInfoBorderColor,
    title: 'Initializing',
    notificationType: 'info',
  },
  [DatatypeStatus.Disabled]: {
    icon: PowerSettingsNewIcon,
    iconFill: currentTheme.current.palette.text.primary,
    title: 'Disabled',
    notificationType: 'plain',
  },
  [DatatypeStatus.ReadingOnly]: {
    icon: VisibilityIcon,
    iconFill: currentTheme.current.palette.text.primary,
    title: 'Reading only',
    notificationType: 'plain',
  },
  [DatatypeStatus.WritingOnly]: {
    icon: ModeEditIcon,
    iconFill: currentTheme.current.palette.text.primary,
    title: 'Writing only',
    notificationType: 'plain',
  },
};

export const getEntityTypeStatus = (status: DatatypeStatus): SystemState => {
  const key = status?.toLocaleLowerCase();
  const currentStatus = EntityTypeStatusesTable[key as DatatypeStatus];

  return (
    currentStatus ?? {
      icon: PowerSettingsNewIcon,
      iconFill: lightInfoBorderColor,
      title: status,
      notificationType: 'info',
      testId: TestID.SystemStatusIndicator,
    }
  );
};

export const useEntityTypeStatus = (status: DatatypeStatus) => {
  const theme = useTheme();

  currentTheme.current = theme;

  return getEntityTypeStatus(status);
};
