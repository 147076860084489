import React from 'react';
import { Typography } from '@mui/material';
import { formatNumber } from 'utils';

export const DatatypeCardStatusLabel: React.FC<{ numOfSynced?: number; numOfFailed?: number }> = ({
  numOfSynced = 0,
  numOfFailed = 0,
}) => (
  <>
    <Typography variant="body1">Status</Typography>
    {numOfSynced === 0 && (
      <Typography color={'text.secondary'} variant="body2">
        No synced entities yet
      </Typography>
    )}
    {numOfSynced > 0 && (
      <Typography color={'text.secondary'} variant="body2">
        {formatNumber(numOfSynced)} entities synced
      </Typography>
    )}
    {numOfFailed > 0 && (
      <Typography color={'error.main'} variant="body2">
        {formatNumber(numOfFailed)} entities failed.
      </Typography>
    )}
  </>
);
