import React from 'react';
import { Box, Typography } from '@mui/material';
import { TestID } from 'testID';
import { Button } from '../Button/Button.view';
import { Section } from '../Section/Section.view';

export const NotReadyYetSection: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <Section
    showBorder
    header="Not ready yet?"
    data-testid={TestID.WaveAdvanceConnectFlowNotReadyYetSection}
  >
    <Typography sx={{ mb: 3 }}>Test without having to log in to see how it works.</Typography>
    <Box sx={{ display: 'block' }}>
      <Button
        variant="contained"
        fullWidth={false}
        sx={{ display: 'block' }}
        onClick={onClick}
        data-testid={TestID.WaveAdvanceConnectFlowTestButton}
      >
        Test
      </Button>
    </Box>
  </Section>
);
