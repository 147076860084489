import type {
  ConnectFlowStepperServiceAPI,
  ConnectFlowStepperState,
} from './connectFlowStepperServiceTypes';
import cloneDeep from 'lodash/cloneDeep';
import { ConnectFlowSteps } from 'enums';

export const ConnectFlowStepsMap: { [key in ConnectFlowSteps]: number } = {
  [ConnectFlowSteps.TermsOfService]: 0,
  [ConnectFlowSteps.InformationReview]: 1,
  [ConnectFlowSteps.Authentication]: 2,
  [ConnectFlowSteps.Summary]: 3,
};

const resetToInitialState = (state: ConnectFlowStepperState) => cloneDeep(state);

export const initConnectFlowStepperService = (
  initialConnectFlowStepperState: ConnectFlowStepperState
) => {
  const _currentState = { current: resetToInitialState(initialConnectFlowStepperState) };
  const _step = {
    current: _currentState.current.currentStep ?? ConnectFlowSteps.InformationReview,
  };

  return (): ConnectFlowStepperServiceAPI => {
    const resetState = () => {
      _currentState.current = resetToInitialState(initialConnectFlowStepperState);
    };

    const getNextStep = (currentStep?: ConnectFlowSteps) => {
      if (_currentState.current?.connectFlowType === 'reauthorise') {
        return currentStep === ConnectFlowSteps.Authentication
          ? ConnectFlowSteps.Summary
          : ConnectFlowSteps.Authentication;
      }

      if (
        _currentState.current?.connectFlowType === 'noVendor' &&
        _currentState.current?.tosAccepted === false &&
        !currentStep
      ) {
        _step.current = ConnectFlowSteps.TermsOfService;
      }

      switch (currentStep) {
        case ConnectFlowSteps.TermsOfService:
          _step.current = ConnectFlowSteps.InformationReview;
          break;
        case ConnectFlowSteps.InformationReview:
          _step.current = ConnectFlowSteps.Authentication;
          break;
        case ConnectFlowSteps.Authentication:
          _step.current = ConnectFlowSteps.Summary;
          break;
      }

      return _step.current;
    };

    const getStepIndex = () => {
      if (_step.current) {
        const stepperIndex = ConnectFlowStepsMap[_step.current];

        if (_currentState.current.connectFlowType === 'vendor') {
          return stepperIndex === 0 ? 0 : stepperIndex - 1;
        }

        return stepperIndex;
      }

      return 0;
    };

    return {
      getNextStep,
      getStepIndex,
      resetState,
      get currentStep() {
        return _step.current;
      },
      get currentState() {
        return _currentState.current;
      },
    };
  };
};
