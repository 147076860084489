import React from 'react';

export const BySesamSVG: React.FC<{ width?: number | string }> = ({ width }) => (
  <svg
    preserveAspectRatio="xMidYMid meet"
    data-bbox="204.19 204.21 1001.813 205.601"
    viewBox="204.19 204.21 1001.813 205.601"
    xmlns="http://www.w3.org/2000/svg"
    data-type="color"
    role="presentation"
    aria-hidden="true"
    width={width}
  >
    <g>
      <path
        d="M1193.33 232c-11.29-16.82-29.85-26.12-66.1-26.12-38.53 0-58 11.05-68.72 30.84-10.67-19.79-29.89-30.84-69-30.84-35.63 0-54.56 9.3-65.85 26.12h50.68a114.51 114.51 0 0 1 15.31-1.36 119 119 0 0 1 19.9 2.14c19.85 5.24 33.71 19.94 35.32 42.63v129.87h27.19V276.05c1.4-23.05 15.34-38 35.36-43.26a119.24 119.24 0 0 1 19.9-2.14 114.37 114.37 0 0 1 15.31 1.36Z"
        fill="#009fdc"
        data-color="1"
      ></path>
      <path
        d="M912.59 263.15a157.29 157.29 0 0 0-1.59 23.29v118.84h24.33l2-22.73c.18-1.51.81-23.92.81-23.92l.1-81.09a70.18 70.18 0 0 1 1.94-14.39Z"
        fill="#009fdc"
        data-color="1"
      ></path>
      <path
        d="M1176.87 263.15c2.17 5.93 2 17.62 2 17.71v77.77s.63 22.41.81 23.92l2 22.73H1206V286.44a155.89 155.89 0 0 0-1.6-23.29Z"
        fill="#009fdc"
        data-color="1"
      ></path>
      <path
        d="M599 232a44.77 44.77 0 0 1 16.59-3.26c14.36 0 28.71 5.59 39.48 14l14.75-18.34c-15.15-12.36-33.09-18.74-53.43-18.74-23 0-43.53 9.27-53.76 26.38Z"
        fill="#009fdc"
        data-color="1"
      ></path>
      <path
        d="M631.09 296.55c-21.76-9.12-43.8-16.1-48.78-33.4H556c2.63 31.36 28.58 42.31 52.78 52.14 21.93 8.78 42.67 16.75 42.67 39.08s-21.93 32.31-40.68 32.31c-18.34 0-33.09-8-44.66-20.34l-16.35 17.15A81.39 81.39 0 0 0 610 409.81c32.7 0 68.59-20.74 68.59-55-.04-34.73-23.59-48.29-47.5-58.26Z"
        fill="#009fdc"
        data-color="1"
      ></path>
      <path
        d="M250.25 232a44.16 44.16 0 0 1 19.75-4.7c14.35 0 28.71 5.58 39.48 14l14.75-18.35c-15.15-12.36-33.1-18.74-53.44-18.74-23.6 0-44.64 9.81-54.56 27.83Z"
        fill="#009fdc"
        data-color="1"
      ></path>
      <path
        d="M285.55 295.11c-21.13-8.87-42.5-15.72-48.28-32h-26.72c3.28 30.28 28.83 41 52.67 50.7 21.93 8.77 42.66 16.75 42.66 39.08s-21.93 32.3-40.67 32.3c-18.35 0-33.1-8-44.67-20.34L204.19 382a81.38 81.38 0 0 0 60.22 26.32c32.7 0 68.59-20.74 68.59-55 0-34.69-23.52-48.25-47.45-58.21Z"
        fill="#009fdc"
        data-color="1"
      ></path>
      <path
        d="M790 204.84c-36.06 0-54.62 9.4-65.87 26.39h49.31a115.87 115.87 0 0 1 16.86-1.63 120.31 120.31 0 0 1 19.9 2.14c20.07 4.53 31.58 21.49 31.58 47.81l.08 35.3h-103.1v-35a95.62 95.62 0 0 1 1.76-17.44H713.2a156.75 156.75 0 0 0-1.56 23v118.83H736l2-22.74c.18-1.51.81-23.92.81-23.92v-19.67h103.07v19.67s.63 22.41.81 23.92l2 22.74H869V285.4c0-50.25-16.42-80.56-79-80.56Z"
        fill="#009fdc"
        data-color="1"
      ></path>
      <path
        d="M490.3 369.32c-16.15 21.06-52.3 17.56-52.3 17.56-34.5-3.67-46.88-26.72-48.25-54.21l-.08-18.81h131.19v-14.63c0-44.08.82-94.62-79.87-94.62-32.39 0-52.71 7.47-65.27 26.41h49a71.8 71.8 0 0 1 14.93-1.4h4.93c50 0 49.73 40.87 49.73 46.56 0 .74.06 14.65.06 14.65h-104.7l.05-14.62a64.46 64.46 0 0 1 1.64-14.06H364.3c-.74 5.64-1 16.92-1 17.54 0 1.51-.09 43.87-.09 43.87 0 66 34.2 85.31 75.14 85.31 2.12 0 4.62-.1 6.36-.1 32.71 0 65.07-10.81 73.68-39.22Z"
        fill="#009fdc"
        data-color="1"
      ></path>
    </g>
  </svg>
);
