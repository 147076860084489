import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';
import { TestID } from 'testID';
import { AdditionalProperties } from 'types/connector';
import { AdditionalPropertiesView } from '../AdditionalProperties/AdditionalProperties.view';
import { Section } from '../Section/Section.view';

export const AdditionalParametersSection: React.FC<{
  additionalValues: AdditionalProperties;
  disabled: boolean;
  handleSaveAdditionalProperties: () => void;
  loading: boolean;
  setAdditionalValues: React.Dispatch<React.SetStateAction<AdditionalProperties | undefined>>;
}> = ({
  additionalValues,
  disabled,
  handleSaveAdditionalProperties,
  loading,
  setAdditionalValues,
}) => (
  <Section
    header="Additional Parameters"
    data-testid={TestID.AdditionalParametersSection}
    showBorder
  >
    <Stack direction="column">
      <Typography variant="body1" gutterBottom>
        You've configured this system with these underlying parameters. If the parameters are
        editable you will be able to modify them in this section below.
      </Typography>
      <AdditionalPropertiesView
        additionalValues={additionalValues}
        setAdditionalValues={setAdditionalValues}
        loading={loading}
      />
      <Grid item mt={3}>
        <LoadingButton
          variant="contained"
          onClick={() => handleSaveAdditionalProperties()}
          loading={loading}
          disabled={disabled}
          data-testid={TestID.SaveAdditionalPropertiesButton}
        >
          Save
        </LoadingButton>
      </Grid>
    </Stack>
  </Section>
);
