import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { TestID } from 'testID';
import { Button } from '../Button/Button.view';
import { Section } from '../Section/Section.view';

export const DisconnectSection: React.FC<{
  connectorName: string;
  showDisconnectDialog: () => void;
}> = ({ connectorName, showDisconnectDialog }) => (
  <Section header="Disconnect" data-testid={TestID.DisconnectSection} showBorder danger>
    <Stack direction="column">
      <Typography variant="body1" gutterBottom>
        This action will disconnect your {connectorName} account. You can find and reconnect it
        later from the marketplace.
      </Typography>
      <Grid item mt={2}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            showDisconnectDialog();
          }}
          data-testid={TestID.DisconnectButton}
        >
          Disconnect
        </Button>
      </Grid>
    </Stack>
  </Section>
);
