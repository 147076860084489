import React from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getLogoInset } from 'utils';
import { TestID } from 'testID';
import { lightTheme } from 'themes';
import { PoweredBySesam } from './PoweredBySesam.view';
import { Link } from '../Link/Link.view';

export const BrandHeaderLogoHorizontal: React.FC<{
  brandingLogo: string;
  connectorId?: string;
  homepageUrl: string;
  vendorTitle: string;
}> = ({ brandingLogo, connectorId = '', homepageUrl, vendorTitle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      data-testid={TestID.SuperOfficeLogo}
      flexDirection="row"
      alignItems="center"
      mt={isMobile ? 0 : '-6px'}
    >
      <Link to={homepageUrl} underline="none" title={vendorTitle} height="64px">
        <Stack flexDirection="row" justifyContent="center" alignItems="center">
          <Stack
            sx={{
              width: getLogoInset(connectorId).desktop.width,
              [lightTheme.breakpoints.between('xs', 'sm')]: {
                width: getLogoInset(connectorId).mobile.width,
              },
            }}
          >
            <img src={brandingLogo} alt="Site logo" />
          </Stack>
        </Stack>
      </Link>
      <Stack sx={{ borderRight: '1px solid rgba(0, 0, 0, 12%)' }} height="64px" mr={2} ml={2} />
      <Stack alignItems="center">
        <Typography variant="body1" color="#333" fontWeight={600} fontSize="18px">
          DataSync
        </Typography>
        <PoweredBySesam text="by" />
      </Stack>
    </Stack>
  );
};
