import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AlertColor } from '@mui/material';
import { useFetchNotifications } from 'hooks/useFetchNotifications';
import { useSystemNotifications } from 'hooks/useSystemNotifications';
import { useUnreadMessagesTitle } from 'hooks/useUnreadMessagesTitle';
import { updateNotificationAsRead } from 'api';
import { snackbarSignal } from 'signals/snackbars.signal';
import { SystemNotificationsButton, SystemNotificationsPanel } from 'views';
import { TestID } from 'testID';

const originalDocumentTitle = document.title;

export const SystemNotificationsContainer: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [notifications, isNotificationLoading, invalidateSystemNotifications] =
    useFetchNotifications();
  const systemNotifications = useSystemNotifications(notifications);
  const [isOpen, setIsOpen] = useState(false);
  const [hasRead, setHasRead] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element>();
  const unreadMessages = notifications?.filter((n) => !n.read).length;

  const openNotificationPanel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOpen((previous) => !previous);
    setAnchorEl(event.currentTarget);

    if (!isOpen) {
      setHasRead(true);
      document.title = originalDocumentTitle;
    }
  };

  useUnreadMessagesTitle(setHasRead, originalDocumentTitle, unreadMessages);

  const showSnackMessage = (message: string, severity: AlertColor, testId: string) => {
    snackbarSignal.emit({
      message,
      severity,
      open: true,
      testId,
    });
  };

  const onHasReadToggle = (notificationId: string, read: boolean) => {
    getAccessTokenSilently({
      authorizationParams: {
        scope: 'write:connectors',
      },
    })
      .then((token) =>
        updateNotificationAsRead(token, {
          _id: notificationId,
          read,
        })
      )
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response?.statusText);
        }

        invalidateSystemNotifications();
      })
      .catch(() => {
        showSnackMessage(
          'Failed to mark message as unread',
          'error',
          TestID.SystemNotificationReadToggleErrorMessage
        );
      });
  };

  return (
    <>
      <SystemNotificationsButton
        unreadMessages={unreadMessages ?? 0}
        openNotificationPanel={openNotificationPanel}
        isOpen={isOpen}
        invisible={hasRead}
      />
      <SystemNotificationsPanel
        systemNotifications={systemNotifications}
        anchorEl={anchorEl}
        isOpen={isOpen}
        handleCloseMenu={() => setIsOpen(false)}
        isNotificationLoading={isNotificationLoading}
        onHasReadToggle={onHasReadToggle}
      />
    </>
  );
};
