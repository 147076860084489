export const TestID = {
  AccountIDTextField: 'account-id-text-field',
  AddConnectorCard: 'add-connector-card',
  AdditionalParametersField: 'additional-parameters-field',
  AdditionalParametersSection: 'additional-parameters-section',
  AdditionalPropertiesSnackbar: 'additional-properties-snackbar',
  AgreementCheckbox: 'agreement-checkbox',
  APIKeyTextField: 'api-key-text-field',
  AppFooter: 'app-footer',
  AvailableSystemsSection: 'available-systems-section',
  BannerPrimaryActionButton: 'banner-primary-action-button',
  BannerSecondaryActionButton: 'banner-secondary-action-button',
  CentralConnectedConnector: 'central-connected-connector',
  CompactOnboardingFlowPage: 'compact-onboarding-flow-page',
  ConfirmApiKeyDialog: 'confirm-api-key-dialog',
  ConfirmDialogCancelButton: 'confirm-dialog-cancel-button',
  ConfirmDialogConfirmButton: 'confirm-dialog-confirm-button',
  ConnectApiKeyInfoLink: 'connect-api-key-info-link',
  ConnectedConnectorsSection: 'connected-connectors-section',
  ConnectedSystemsCard: 'connected-systems-card',
  ConnectedSystemStatusIndicator: 'connected-system-status-indicator',
  ConnectFlowCustomSection: 'connect-flow-custom-section',
  ConnectFlowInfoCancelButton: 'connect-flow-info-cancel-button',
  ConnectFlowInfoPage: 'connect-flow-info-page',
  ConnectInstructionsLabel: 'label-connect-instructions',
  ConnectorAlreadyConnectedWarningMessage: 'connector-already-connected-warning-message',
  ConnectorConfigurationLink: 'connector-configuration-link',
  ConnectorDisconnectedErrorSnackbarMessage: 'connector-disconnected-error-snackbar-message',
  ConnectorDisconnectedSuccessfullySnackbarMessage:
    'connector-disconnected-successfully-snackbar-message',
  ConnectorSynchronisationLink: 'connector-synchronisation-link',
  ConnectorSyncPageEntityTypesLink1: 'connector-sync-page-entity-types-link-1',
  ConnectorSyncPageEntityTypesLink2: 'connector-sync-page-entity-types-link-2',
  ConnectSystemButton: 'connect-system-button',
  ContactEmailSection: 'contact-email-section',
  ContinueButton: 'continue-button',
  DashboardCardVendorStarIcon: 'dashboard-card-vendor-star-icon',
  DatatypeEntity: 'datatype-entity',
  DatatypeExpendablePanel: 'datatype-expendable-panel',
  DatatypeOptionFullSync: 'datatype-option-full-sync',
  DatatypeOptionsContainer: 'datatype-options-container',
  datatypeOptionShareEnabled: 'datatype-option-share-enabled',
  DatatypeOptionsStack: 'datatype-options',
  DatatypeRelationItem: 'datatype-relation-item',
  DefaultSiteLogo: 'default-site-logo',
  DesignedSystemStatusIndicator: 'designed-system-status-indicator',
  DisconnectButton: 'disconnect-button',
  DisconnectDialog: 'disconnect-dialog',
  DisconnectSection: 'disconnect-section',
  DocsUrl: 'docs-url',
  DocumentationSection: 'documentation-section',
  DoYouHaveAWaveAccountSection: 'do-you-have-a-wave-account-section',
  EntityTypeStatusIndicator: 'entity-type-status-indicator',
  ErrorPageTryAgainButton: 'error-page-try-again-button',
  FailingEntityTypeStatusIndicator: 'failing-entity-type-status-indicator',
  FinishConnectButton: 'finish-connect-button',
  FooterContactSupportLink: 'footer-contact-support-link',
  FullScreenLoader: 'full-screen-loader',
  GeneralSettingsSection: 'general-settings-section',
  GlobalSavePanel: 'global-save-panel',
  GlobalSavePanelCancelButton: 'global-save-panel-cancel-button',
  GlobalSavePanelChangesCountLabel: 'global-save-panel-changes-count-label',
  GlobalSavePanelSaveButton: 'global-save-panel-save-button',
  HeaderAvailableSystem: 'available-system-header',
  HelpMenuItemButton: 'help-menu-item-button',
  InitialisingSystemStatusIndicator: 'initialising-system-status-indicator',
  InitialSynchronisationInProgressStatusIndicator:
    'initial-synchronisation-in-progress-status-indicator',
  InSyncEntityTypeStatusIndicator: 'insync-entity-type-status-indicator',
  InvalidAPIKey: 'invalid-api-key',
  ISOCertificationUrl: 'iso-certification-url',
  LimitedServiceStatusIndicator: 'limited-service-status-indicator',
  LogoutMenuItemButton: 'logout-menu-item-button',
  LookingIntoItStatusIndicator: 'looking-into-it-status-indicator',
  MakingServiceTalkLogo: 'making-service-talk-logo',
  MyAccountMenuItemButton: 'my-account-menu-item-button',
  NeedsAttentionStatusIndicator: 'needs-attention-system-status-indicator',
  NoConnectorsFoundLabel: 'no-connectors-found-label',
  PoweredBySesamLogo: 'powered-by-sesam-logo',
  PrimarySystemCard: 'primary-system-card',
  ProfileSection: 'profile-section',
  ReauthoriseButton: 'reauthorise-button',
  ReauthoriseNeedsAttentionLink: 'reauthorise-needs-attention-link',
  ReauthoriseSection: 'reauthorise-section',
  ReauthoriseMaskedAPiKey: 'reauthorise-masked-apikey',
  SaveAdditionalPropertiesButton: 'save-additional-properties-button',
  SettingsPageGeneralStatusLink: 'settings-page-general-status-link',
  SiteHomePageLink: 'site-home-page-link',
  SnackbarConnectedSuccessfullySuccessMessage: 'snackbar-connected-successfully-success-message',
  SnackbarErrorMessage: 'snackbar-error-message',
  SnackbarSuccessMessage: 'snackbar-success-message',
  StartFreshButton: 'start-fresh-button',
  StartFreshDialog: 'start-fresh-dialog',
  StartFreshErrorSnackbarMessage: 'start-fresh-error-snackbar-message',
  StartFreshSection: 'start-fresh-section',
  StartFreshSuccessfullySnackbarMessage: 'start-fresh-successfully-snackbar-message',
  SuperOfficeLogo: 'super-office-logo',
  SynchronisationPageVendorText: 'synch-page-vendor-text',
  SystemCard: 'system-card-',
  SystemNotificationMarkAsReadButton: 'system-notification-mark-as-read-button',
  SystemNotificationMessageDate: 'system-notification-message-date',
  SystemNotificationPanel: 'system-notification-panel',
  SystemNotificationReadToggleErrorMessage: 'system-notification-read-toggle-error-message',
  SystemNotificationsBadge: 'system-notifications-badge',
  SystemNotificationSeverityIcon: 'system-notification-severity-icon',
  SystemNotificationSeverityIconError: 'system-notification-severity-icon-error',
  SystemNotificationSeverityIconInfo: 'system-notification-severity-icon-info',
  SystemNotificationSeverityIconSuccess: 'system-notification-severity-icon-success',
  SystemNotificationSeverityIconWarning: 'system-notification-severity-icon-warning',
  SystemNotificationsPanelItem: 'system-notifications-panel-item',
  SystemNotificationsPanelLoader: 'system-notifications-panel-loader',
  SystemNotificationUnreadIndicator: 'system-notification-unread-indicator',
  SystemStatusIndicator: 'system-status-indicator',
  SystemStatusIndicatorTooltip: 'system-status-indicator-tooltip',
  TenantSettingsSections: 'tenant-settings-sections',
  TermsOfServiceForm: 'terms-of-service-form',
  ToggleSystemNotificationsButton: 'toggle-system-notification-button',
  ToggleUserMenuButton: 'toggle-user-menu-button',
  TokenRefreshBanner: 'token-refresh-banner',
  UserInfoForm: 'user-info-form',
  UserInfoFormFirstNameField: 'user-info-form-first-name-field',
  UserInfoFormLastNameField: 'user-info-form-last-name-field',
  UserInfoFormCompanyNameField: 'user-info-form-company-name-field',
  UserInfoFormCompanyEmailField: 'user-info-form-company-email-field',
  UserInfoFormCompanyPhoneField: 'user-info-form-company-phone-field',
  UserMenu: 'user-menu',
  WaveAdvanceConnectFlowBackButton: 'wave-advance-connect-flow-back-button',
  WaveAdvanceConnectFlowDoNotHaveAccountRadioButton:
    'wave-advance-connect-flow-do-not-have-account-radio-button',
  WaveAdvanceConnectFlowHaveAccountRadioButton:
    'wave-advance-connect-flow-have-account-radio-button',
  WaveAdvanceConnectFlowLoginWithYourWaveAdminAccountSection:
    'wave-advance-connect-flow-login-with-your-wave-admin-account-section',
  WaveAdvanceConnectFlowNewToWaveSection: 'wave-advance-connect-flow-new-to-wave-section',
  WaveAdvanceConnectFlowNotReadyYetSection: 'wave-advance-connect-flow-not-ready-yet-section',
  WaveAdvanceConnectFlowNotWaveAdministratorSection:
    'wave-advance-connect-flow-not-wave-administrator-section',
  WaveAdvanceConnectFlowOpenGuideButton: 'wave-advance-connect-flow-open-guide-button',
  WaveAdvanceConnectFlowSendActivateButtonButton:
    'wave-advance-connect-flow-send-activate-button-button',
  WaveAdvanceConnectFlowTestButton: 'wave-advance-connect-flow-test-button',
} as const;
