const superoffice = {
  desktop: {
    width: '320px',
  },
  mobile: {
    width: '245px',
  },
};

const tripletex = {
  desktop: {
    width: '230px',
  },
  mobile: {
    width: '205px',
  },
};

const LogoInsets: {
  [key: string]: { desktop: { width: string }; mobile: { width: string } };
} = {
  powerofficego: {
    desktop: {
      width: '75px',
    },
    mobile: {
      width: '65px',
    },
  },
  superoffice,
  'superoffice-test': superoffice,
  tripletex,
  'tripletex-test': tripletex,
  'tripletex-prod': tripletex,
};

export const getLogoInset = (connectorId: string) => {
  const inset = LogoInsets[connectorId];

  if (!inset) {
    return {
      desktop: {
        width: '280px',
      },
      mobile: {
        width: '245px',
      },
    };
  }

  return inset;
};
