export const shouldDisplayStartFresh = (args?: { nodeEnv?: string; userEmail?: string }) => {
  if (args?.nodeEnv === 'development') {
    return true;
  }

  if (
    args?.userEmail &&
    (args.userEmail?.indexOf('@sesam.io') !== -1 || args.userEmail === 'portal@sesamtalk.io')
  ) {
    return true;
  }

  return false;
};
