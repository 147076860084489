import React from 'react';
import { Stack } from '@mui/material';
import { AccountSelectionContainer } from 'containers/AccountSelection.container';

export const CompactFlowAccountSelectionPage: React.FC = () => (
  <Stack justifyContent="center" alignItems="center" sx={{ height: '80vh' }}>
    <Stack sx={{ width: '50%' }}>
      <AccountSelectionContainer isCompactFlow={true} />
    </Stack>
  </Stack>
);
