import React from 'react';
import { Stack, Typography } from '@mui/material';
import { TestID } from 'testID';
import { PoweredBySesam } from './PoweredBySesam.view';
import { Link } from '../Link/Link.view';

export const MakingConnectorTalkHeaderLogo: React.FC<{
  homepageUrl: string;
  vendorTitle: string;
}> = ({ homepageUrl, vendorTitle }) => (
  <Stack data-testid={TestID.DefaultSiteLogo}>
    <Link
      to={homepageUrl}
      underline="none"
      title="Go to homepage"
      data-testid={TestID.SiteHomePageLink}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: 'bold', textDecoration: 'none' }}
        color={'text.primary'}
      >
        {vendorTitle ? (
          <Typography variant="inherit" data-testid={TestID.MakingServiceTalkLogo}>
            Making{' '}
            <Typography variant="inherit" component="span">
              {vendorTitle}{' '}
              <Typography
                variant="inherit"
                component="span"
                color={'primary.light'}
                sx={{
                  backgroundColor: 'primary.main',
                  padding: '2px 4px',
                  paddingTop: '1px',
                  position: 'relative',
                  left: '-3px',
                }}
                px={1}
                pb={0.5}
              >
                Talk
              </Typography>
            </Typography>
          </Typography>
        ) : (
          'Sesam Talk'
        )}
      </Typography>
    </Link>
    <PoweredBySesam />
  </Stack>
);
