import React from 'react';
import { Stack } from '@mui/material';
import { TestID } from 'testID';
import { SystemState } from 'types';
import { lightTheme } from 'themes';
import { NotificationMessage } from '../NotificationMessage/NotificationMessage.view';
import { DesignedSystemStatusIndicator } from '../SystemStatusIndicator/DesignedSystemStatusIndicator.view';

export const ConnectorStatus: React.FC<{
  systemState: SystemState;
  readMoreButton?: boolean;
  connectorId?: string;
}> = ({ systemState, readMoreButton, connectorId }) => (
  <>
    {!systemState.description && !systemState.component && (
      <Stack
        sx={{
          mt: 1,
          mb: 2,
          width: 'fit-content',
        }}
      >
        <DesignedSystemStatusIndicator
          systemState={{ ...systemState, description: '' }}
          notificationType={systemState.notificationType}
          testId={systemState.testId ? systemState.testId : TestID.DesignedSystemStatusIndicator}
        />
      </Stack>
    )}
    {(systemState.description || systemState.component) && (
      <Stack
        sx={{
          mt: 1,
          mb: 6,
          width: '50%',
          [lightTheme.breakpoints.down('md')]: {
            width: '100%',
          },
        }}
      >
        <NotificationMessage
          title={systemState.title}
          notificationType={systemState.notificationType}
          testId={systemState.testId}
          readMoreButton={readMoreButton}
        >
          {systemState.description
            ? systemState.description
            : (systemState?.component?.(connectorId ?? '') as React.ReactNode)}
        </NotificationMessage>
      </Stack>
    )}
  </>
);
