import React from 'react';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ConnectFlowHeader } from '../ConnectFlowHeader/ConnectFlowHeader.view';

export const ConnectorDetails: React.FC<{
  connectorTitle: string;
  connectorLogo: string;
  hideSubTitle?: boolean;
  hasVendor: boolean;
  vendorTitle?: string;
  isReauthoriseFlow?: boolean;
}> = ({
  connectorTitle,
  connectorLogo,
  hideSubTitle = false,
  hasVendor,
  vendorTitle,
  isReauthoriseFlow = false,
}) => {
  const connectText = isReauthoriseFlow ? 'reconnect' : 'connect';

  return (
    <Stack>
      <ConnectFlowHeader connectorTitle={connectorTitle} connectorLogo={connectorLogo} />
      {hideSubTitle === false && (
        <Typography mb={2} gutterBottom>
          {isReauthoriseFlow
            ? `You are about to ${connectText} to your ${connectorTitle} account.`
            : hasVendor
            ? `You are about to ${connectText} your ${connectorTitle} account to ${vendorTitle}.`
            : `You are about to ${connectText} your ${connectorTitle} account.`}
        </Typography>
      )}
    </Stack>
  );
};
