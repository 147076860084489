import { initConnectFlowStepperService } from 'services';
import { useMemo } from 'react';
import { calculateConnectFlowType } from 'utils';
import { ConnectFlowSteps } from 'enums';

export const useConnectFlowStepper = (
  hasVendor: boolean,
  isReauthoriseFlow: boolean,
  tosAccepted?: boolean,
  currentStep?: ConnectFlowSteps
) => {
  const connectFlowStepper = useMemo(() => {
    return initConnectFlowStepperService({
      connectFlowType: calculateConnectFlowType({ hasVendor, isReauthoriseFlow }),
      tosAccepted: tosAccepted ?? false,
      currentStep,
    });
  }, [hasVendor, isReauthoriseFlow, tosAccepted, currentStep]);

  return connectFlowStepper;
};
