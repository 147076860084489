import React from 'react';
import { AppBar, Container, Stack, Toolbar } from '@mui/material';
import { BrandHeaderLogoHorizontal } from 'views/TalkLogo/BrandHeaderLogoHorizontal.view';
import { ConnectorIDs } from 'commonConstants';
import { CircularLoading } from '../CircularLoading/CircularLoading.view';
import { BrandHeaderLogo } from '../TalkLogo/BrandHeaderLogo.view';
import { MakingConnectorTalkHeaderLogo } from '../TalkLogo/MakingConnectorTalkHeaderLogo.view';

export const AppHeader: React.FC<{
  connectorId?: string;
  vendorTitle: string;
  enhancerEnd?: React.ReactNode;
  brandingLogo?: string;
  brandingHomepageUrl: string;
  isLogoLoading: boolean;
}> = ({
  connectorId,
  vendorTitle,
  enhancerEnd,
  brandingLogo,
  brandingHomepageUrl,
  isLogoLoading,
}) => (
  <AppBar
    position="sticky"
    sx={{
      backgroundColor: 'primary.light',
      py: 1,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      boxShadow: 'rgba(45, 62, 80, 0.12) 0px 1px 5px 0px',
      paddingBottom: '12px',
      paddingTop: '6px',
    }}
    elevation={0}
  >
    <Container maxWidth="xl">
      <Toolbar disableGutters sx={{ justifyContent: 'space-between', marginTop: '6px' }}>
        <Stack direction="row" alignItems={'center'}>
          {isLogoLoading ? (
            <CircularLoading size="1.3rem" />
          ) : brandingLogo ? (
            connectorId === ConnectorIDs.PowerOfficeGo ? (
              <BrandHeaderLogoHorizontal
                brandingLogo={brandingLogo}
                connectorId={connectorId}
                homepageUrl={brandingHomepageUrl}
                vendorTitle={vendorTitle}
              />
            ) : (
              <BrandHeaderLogo
                brandingLogo={brandingLogo}
                connectorId={connectorId}
                homepageUrl={brandingHomepageUrl}
                vendorTitle={vendorTitle}
              />
            )
          ) : (
            <MakingConnectorTalkHeaderLogo
              vendorTitle={vendorTitle}
              homepageUrl={brandingHomepageUrl}
            />
          )}
        </Stack>
        <Stack direction="row">{enhancerEnd}</Stack>
      </Toolbar>
    </Container>
  </AppBar>
);
