import React from 'react';
import { Stack } from '@mui/material';
import { CircularLoading } from 'views/CircularLoading/CircularLoading.view';
import { TestID } from 'testID';

export const FullScreenLoader: React.FC<{ testId?: string; height?: string }> = ({
  testId = TestID.FullScreenLoader,
  height = '95vh',
}) => (
  <Stack data-testid={testId} sx={{ height }} justifyContent="center" alignItems="center">
    <CircularLoading />
  </Stack>
);
