import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { getConnectorIdFromURL } from 'utils';

/**
 * This wrapper is needed to make Auth0 redirects work with react-router-dom
 */
export const Auth0ProviderWithRedirectCallback: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const siteId = getConnectorIdFromURL(window.location.pathname);

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain="accounts.talk.sesam.io"
      clientId="kJpPOS30v8dpD68iRJ7PMdS03Hwvq06X" // `Sesam Talk` Auth0 application
      cacheLocation="localstorage" // if we don't do this, Firefox won't work, but it has some XSS implications
      useRefreshTokens={true}
      authorizationParams={{
        audience: 'http://0.0.0.0:5010',
        scope: 'profile openid email read:connectors write:connectors',
        redirect_uri: window.location.origin + '/onboarding',
        cacheLocation: window.Cypress ? 'localstorage' : 'memory',
        'ext-site_id': siteId,
        screen_hint: searchParams.get('screen_hint') ?? undefined,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
