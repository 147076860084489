import React, { Outlet } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Breadcrumbs, SidebarLayout, SidebarLayoutContent, SidebarLayoutMenu } from 'views';

export const SettingsPage = () => (
  <SidebarLayout>
    <SidebarLayoutMenu>
      <List component="nav">
        <ListItemButton selected>
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="My Account" />
        </ListItemButton>
      </List>
    </SidebarLayoutMenu>
    <SidebarLayoutContent>
      <Breadcrumbs currentPage="My Account" />
      <Outlet />
    </SidebarLayoutContent>
  </SidebarLayout>
);
