import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { useFetchModelMapping } from 'hooks/useFetchModelMapping';
import { useFetchTenant } from 'hooks/useFetchTenant';
import { ConnectorSynchronisationContainer } from 'containers/ConnectorSynchronisation.container';
import { ErrorView, FullScreenLoader } from 'views';
import { ErrorMessages } from 'commonConstants';

export const ConnectorSynchronisationPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const connectorId = params.connectorId;

  const { connectors, connectorsLoading, invalidate: invalidateConnectors } = useFetchConnectors();
  const { tenant, isLoading: tenantLoading, invalidate: invalidateTenant } = useFetchTenant();
  const [modelMapping, modelMappingLoading, invalidateModelMapping] = useFetchModelMapping();

  const refresh = () => {
    invalidateConnectors();
    invalidateTenant();
    invalidateModelMapping();
  };

  if (connectorsLoading || tenantLoading || modelMappingLoading) {
    return <FullScreenLoader height="65vh" />;
  }

  if (!connectorId) {
    return (
      <ErrorView
        errorTitle={ErrorMessages.ConnectorIdNotFound}
        actionButtonText="Go back"
        onActionClick={() => navigate('/')}
      />
    );
  }
  if (!connectors) {
    return <ErrorView errorTitle={ErrorMessages.ConnectorsNotLoaded} onActionClick={refresh} />;
  }
  if (!tenant) {
    return <ErrorView errorTitle={ErrorMessages.TenantNotLoaded} onActionClick={refresh} />;
  }
  if (!modelMapping) {
    return <ErrorView errorTitle={ErrorMessages.ModelMappingNotLoaded} onActionClick={refresh} />;
  }

  return (
    <ConnectorSynchronisationContainer
      connectorId={connectorId}
      connectors={connectors}
      tenantInfo={tenant}
      modelMapping={modelMapping}
      invalidateConnectors={invalidateConnectors}
    />
  );
};
