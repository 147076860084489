import React from 'react';
import { Stack, Typography } from '@mui/material';
import { getSystemConnectorUrl } from 'utils';
import { TestID } from 'testID';
import { Link } from '../Link/Link.view';
import { Section } from '../Section/Section.view';

export const DocumentationSection: React.FC<{ connectorName: string; connectorId: string }> = ({
  connectorName,
  connectorId,
}) => (
  <Section header="Documentation" showBorder data-testid={TestID.DocumentationSection}>
    <Stack direction="column">
      <Typography variant="body1" gutterBottom>
        Want to know more? Read the documentation about {connectorName}{' '}
        <Link to={getSystemConnectorUrl(connectorId)} target="_blank">
          here
        </Link>
        .
      </Typography>
    </Stack>
  </Section>
);
