import React from 'react';
import cx from 'classnames';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { TestID } from 'testID';
import type { Datatypes } from 'types/connector';
import { DatatypeRelationsArray } from 'types/modelMapping';
import { lightTheme } from 'themes';
import { DatatypeSummaryCardItems } from './DatatypeSummaryCardItems.view';
import styles from './DatatypeSummaryCard.module.scss';

export const DatatypeSummaryCard: React.FC<{
  vendorIcon: string;
  connectorIcon: string;
  vendorName: string;
  vendorDatatypes: Datatypes;
  relations: DatatypeRelationsArray;
}> = ({ vendorIcon, connectorIcon, vendorName, vendorDatatypes, relations }) => (
  <Stack
    className={styles.wrapper}
    style={{ backgroundColor: lightTheme.palette.background.lighterGrey }}
    flexDirection="row"
    mb={2}
    data-testid={TestID.DatatypeRelationItem}
  >
    <Stack className={styles.vendorWrapper} flexDirection="row">
      <Box
        component="img"
        sx={{ maxWidth: '1.5rem', objectFit: 'contain' }}
        src={connectorIcon}
        alt="Vendor"
        loading="lazy"
        ml={1}
      />
      <Typography variant="body1" className={cx(styles.vendorName, styles.text)} title={vendorName}>
        {vendorName}
      </Typography>
    </Stack>
    <Stack className={styles.separatorWrapper}>
      <SyncAltIcon />
    </Stack>
    <DatatypeSummaryCardItems
      vendorIcon={vendorIcon}
      vendorDatatypes={vendorDatatypes}
      relations={relations}
    />
  </Stack>
);
