import React from 'react';
import { Stack } from '@mui/system';
import { extractEntityTypeNameFromRelation, getRelationName } from 'utils';
import type { Datatypes } from 'types/connector';
import { DatatypeRelationsArray } from 'types/modelMapping';
import { DatatypeSummaryCardItem } from './DatatypeSummaryCardItem.view';
import styles from './DatatypeSummaryCard.module.scss';

export const DatatypeSummaryCardItems: React.FC<{
  relations: DatatypeRelationsArray;
  vendorDatatypes: Datatypes;
  vendorIcon: string;
}> = ({ relations, vendorDatatypes, vendorIcon }) => {
  if (!relations) {
    return null;
  }

  return (
    <Stack className={styles.itemsWrapper}>
      {relations?.map((relation) => {
        const subDatatype = extractEntityTypeNameFromRelation(relation);
        const relationName = getRelationName(subDatatype, vendorDatatypes);

        return (
          <DatatypeSummaryCardItem
            key={relation}
            relationName={relationName}
            vendorIcon={vendorIcon}
          />
        );
      })}
    </Stack>
  );
};
