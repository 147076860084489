import cloneDeep from 'lodash/cloneDeep';
import type { DatatypeOptionState, DatatypeOptionsState } from 'types';

export type DatatypesServiceAPI = {
  /**
   * Toggles the current boolean state.
   */
  toggle: (datatypeId: string, optionName: keyof DatatypeOptionState) => void;
  /**
   * Gets the current state.
   */
  currentState: DatatypeOptionsState;
  /**
   * Resets the current state to the initial state.
   */
  resetState: () => void;
  /**
   * Resets the current counter.
   */
  resetCounter: () => void;
  /**
   * Gets the changes count.
   */
  changesCount: number;
  /**
   * Gets the initial state.
   */
  initialState: DatatypeOptionsState;
};

export type DatatypesServiceFn = (
  initialDatatypeOptionsState: DatatypeOptionsState
) => () => DatatypesServiceAPI;

const resetToInitialState = (state: DatatypeOptionsState) => cloneDeep(state);

/*
 * Stateful service that manages the `Datatypes` options.
 */
export const initDatatypeOptionsService: DatatypesServiceFn = (
  initialDatatypeOptionsState: DatatypeOptionsState
) => {
  const _datatypeOptionsSate = { current: resetToInitialState(initialDatatypeOptionsState) };
  const _changesCount = { count: 0 };

  return () => {
    const toggle = (datatypeId: string, optionName: keyof DatatypeOptionState) => {
      _datatypeOptionsSate.current[datatypeId][optionName] =
        !_datatypeOptionsSate.current[datatypeId][optionName];

      const initialState = initialDatatypeOptionsState[datatypeId];
      const currentState = _datatypeOptionsSate.current[datatypeId];

      if (initialState[optionName] !== currentState[optionName]) {
        _changesCount.count = _changesCount.count + 1;
      } else {
        _changesCount.count = _changesCount.count > 0 ? _changesCount.count - 1 : 0;
      }
    };

    const resetState = () => {
      _datatypeOptionsSate.current = resetToInitialState(initialDatatypeOptionsState);
      _changesCount.count = 0;
    };

    const resetCounter = () => (_changesCount.count = 0);

    return {
      toggle,
      resetState,
      resetCounter,
      get currentState() {
        return _datatypeOptionsSate.current;
      },
      get changesCount() {
        return _changesCount.count;
      },
      get initialState() {
        return initialDatatypeOptionsState;
      },
    };
  };
};
