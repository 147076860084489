import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import { Card, CardActionArea, Stack } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import styles from './ConnectorCard.module.scss';

export const ConnectorCardWrapper: React.FC<
  PropsWithChildren<{
    handleClick: () => void;
    testId?: string;
    className?: string;
  }>
> = ({ handleClick, testId, children, className }) => (
  <Card
    sx={{
      width: 240,
      borderRadius: '16px',
      '& .MuiCardActionArea-focusHighlight': { backgroundColor: 'white' },
    }}
    elevation={2}
    data-testid={testId}
    className={cx(styles.card, className)}
  >
    <CardActionArea onClick={handleClick} className={styles.cardActionArea}>
      <CardContent sx={{ height: 280 }}>
        <Stack justifyContent={'space-between'} sx={{ height: '100%' }}>
          {children}
        </Stack>
      </CardContent>
    </CardActionArea>
  </Card>
);
