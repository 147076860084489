import React from 'react';
import { Box, Typography } from '@mui/material';
import { TestID } from 'testID';
import { Button } from '../Button/Button.view';
import { Section } from '../Section/Section.view';

export const NewToWaveSection: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <Section
    showBorder
    header="New to Wave?"
    data-testid={TestID.WaveAdvanceConnectFlowNewToWaveSection}
  >
    <Typography sx={{ mb: 2 }}>
      Follow this simple guide to create your first Wave account and kickstart the seamless
      synchronization of your data.
    </Typography>
    <Box sx={{ display: 'block' }}>
      <Button
        variant="contained"
        fullWidth={false}
        onClick={onClick}
        data-testid={TestID.WaveAdvanceConnectFlowOpenGuideButton}
      >
        Open the guide
      </Button>
    </Box>
  </Section>
);
