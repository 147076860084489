import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { logger } from 'utils';
import { getNotifications } from 'api';
import { Notifications } from 'types/notifications';

export const useFetchNotifications = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: ['Notifications'],
    enabled: isAuthenticated,
    refetchInterval: 10000,
    queryFn: () =>
      getAccessTokenSilently({
        authorizationParams: {
          scope: 'read:connectors',
        },
      })
        .then((token) => getNotifications(token))
        .then((notifications: Notifications) => notifications)
        .catch((e) => {
          logger.error('An error occurred when trying to fetch the notifications.', e);
          return null;
        }),
  });

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['Notifications'] });
  };

  return [data, isLoading, invalidate] as const;
};
