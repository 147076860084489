import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { TestID } from 'testID';
import { Section } from '../Section/Section.view';

export const LoginWithYourWaveAccountSection: React.FC<{
  handleOnConnect: React.MouseEventHandler<HTMLButtonElement>;
  isConnectButtonDisabled: boolean;
  showButtonLoader?: boolean;
}> = ({ handleOnConnect, isConnectButtonDisabled, showButtonLoader }) => (
  <Section
    showBorder
    header="Login with your Wave account"
    data-testid={TestID.WaveAdvanceConnectFlowLoginWithYourWaveAdminAccountSection}
  >
    <Typography sx={{ mb: 2 }}>
      To start, please log in to your Wave account using an admin user. This will connect your
      account for seamless synchronization.
    </Typography>
    <LoadingButton
      variant="contained"
      onClick={handleOnConnect}
      sx={{ alignSelf: 'flex-start' }}
      disabled={isConnectButtonDisabled}
      loading={showButtonLoader}
      data-testid={TestID.ConnectSystemButton}
      fullWidth={false}
      type="submit"
    >
      Login as Admin
    </LoadingButton>
  </Section>
);
