import { useMemo } from 'react';
import flow from 'lodash/flow';
import { getTimeSince } from 'utils';
import { SystemNotification, SystemNotifications } from 'types';
import { Notifications } from 'types/notifications';

const compareTimestamps = (a: SystemNotification, b: SystemNotification) => {
  const dateA = new Date(b.time.timestamp);
  const dateB = new Date(a.time.timestamp);

  if (dateA < dateB) {
    return -1;
  } else if (dateA > dateB) {
    return 1;
  }

  return 0;
};

const addTitle = (systemNotifications: SystemNotifications): SystemNotifications =>
  systemNotifications.map((notification) => ({
    ...notification,
    title: notification.connectorIds.join(' - '),
  }));

const sortByTimestamp = (systemNotifications: SystemNotifications): SystemNotifications =>
  systemNotifications.sort(compareTimestamps);

const getMaxLatest = (systemNotifications: SystemNotifications): SystemNotifications =>
  systemNotifications.slice(0, 29);

const addSinceDateTime = (systemNotifications: SystemNotifications): SystemNotifications =>
  systemNotifications.map((notification) => ({
    ...notification,
    time: {
      timestamp: notification.time.timestamp,
      since: getTimeSince(new Date(notification.time.timestamp).toDateString()),
      dateTime: new Date(notification.time.timestamp).toLocaleString('en-GB'),
    },
  }));

const runEnhancementFlow = flow([addTitle, sortByTimestamp, getMaxLatest, addSinceDateTime]);

export const useSystemNotifications = (
  notifications?: Notifications | null
): SystemNotifications => {
  return useMemo(() => {
    if (!notifications) {
      return [];
    }

    const mapToObject = notifications.map(
      (notification): SystemNotification => ({
        id: notification._id,
        deleted: notification._deleted,
        time: {
          timestamp: notification.created_at,
          dateTime: '',
          since: '',
        },
        connectorIds: notification.connector_ids,
        datatypes: notification.datatypes,
        ruleId: notification.rule_id,
        tenantId: notification.tenant_id,
        read: notification.read ?? false,
        text: notification.text,
        severity: notification.severity,
      })
    );

    return runEnhancementFlow(mapToObject);
  }, [notifications]);
};
