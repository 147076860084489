import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ConfirmDialog } from 'views/ConfirmDialog/ConfirmDialog.view';
import { TestID } from 'testID';

export const StartFreshDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  loading: boolean;
}> = ({ open, handleClose, handleConfirm, loading }) => (
  <ConfirmDialog
    open={open}
    destructive={true}
    title="Start fresh"
    description={
      <Stack>
        <Typography>
          Proceeding will lead to the disconnection of all connected systems and the irreversible
          deletion of all collected data. Data already stored in your systems will not be deleted.
        </Typography>
        <Typography sx={{ mt: 1 }}>Are you sure you want to start fresh?</Typography>
      </Stack>
    }
    cancelText="Cancel"
    confirmText="Yes"
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    loading={loading}
    testId={TestID.StartFreshDialog}
  />
);
