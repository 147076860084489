import { marked } from 'marked';
import React from 'react';
import { Stack } from '@mui/material';
import { Section } from 'views';
import { TestID } from 'testID';
import { ConnectFlowCustomSection } from 'types/connector';

const parseContent = (content: string) => {
  const rawMarkup = marked
    .parse(content)
    ?.replace('<a ', '<a target="_blank" rel="noopener,noreferrer" ');

  return { __html: rawMarkup };
};

export const CustomSectionsContainer: React.FC<{
  customSections?: ConnectFlowCustomSection[];
  showBorder?: boolean;
}> = ({ customSections, showBorder = false }) => (
  <>
    {customSections?.map((customSection) => (
      <Section
        showBorder={showBorder}
        key={customSection.id}
        header={customSection.title}
        data-testid={TestID.ConnectFlowCustomSection}
      >
        <Stack dangerouslySetInnerHTML={parseContent(customSection.content)} />
      </Section>
    ))}
  </>
);
