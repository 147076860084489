import React from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { Accordion, AccordionDetails } from '@mui/material';
import { TestID } from 'testID';
import { DatatypeOptionState, SystemState } from 'types';
import type { Datatype, Datatypes } from 'types/connector';
import type { HiddenEntityTypeOptions } from 'types/vendor';
import { DatatypeRelationsArray, ModelMapping } from 'types/modelMapping';
import { DatatypeStatus } from 'enums';
import { DatatypeCardContent } from './DatatypeCardContent.view';
import { DatatypeCardOptions } from './DatatypeCardOptions.view';
import { DatatypeCardStatusLabel } from './DatatypeCardStatusLabel.view';
import { DatatypeCardSummary } from './DatatypeCardSummary.view';

export const DatatypeCard: React.FC<{
  dataTypeStatus: DatatypeStatus;
  modelMapping: ModelMapping;
  system: string;
  datatypeId: string;
  vendorSystem: string;
  vendorId: string;
  vendorTitle: string;
  label: string;
  description?: string;
  numOfSynced: number;
  numOfFailed: number;
  vendorDatatypes: Datatypes;
  expanded?: boolean;
  relations: DatatypeRelationsArray;
  showRelations: boolean;
  systemState: SystemState;
  icon: SvgIconComponent;
  datatypeOptionsSate: Datatype;
  inProgress: boolean;
  onOptionToggle: (datatypeId: string, optionName: keyof DatatypeOptionState) => void;
  hiddenEntityTypeOptions: HiddenEntityTypeOptions;
}> = ({
  dataTypeStatus,
  modelMapping,
  system,
  datatypeId,
  vendorSystem,
  vendorId,
  vendorTitle,
  label,
  description,
  numOfSynced,
  numOfFailed,
  vendorDatatypes,
  expanded,
  relations,
  showRelations,
  systemState,
  icon,
  datatypeOptionsSate,
  onOptionToggle,
  inProgress,
  hiddenEntityTypeOptions
}) => (
    <>
      {showRelations && (
        <Accordion defaultExpanded={expanded} data-testid={TestID.DatatypeExpendablePanel}>
          <DatatypeCardSummary
            Icon={icon}
            label={label}
            dataTypeStatus={dataTypeStatus}
            systemState={systemState}
          />
          <AccordionDetails>
            <DatatypeCardContent
              description={description}
              relations={relations}
              vendorSystem={vendorSystem}
              system={system}
              vendorId={vendorId}
              vendorTitle={vendorTitle}
              vendorDatatypes={vendorDatatypes}
              modelMapping={modelMapping}
            />
            <DatatypeCardOptions
              datatypeId={datatypeId}
              optionsToDisplay={datatypeOptionsSate}
              onOptionToggle={onOptionToggle}
              inProgress={inProgress}
              hiddenEntityTypeOptions={hiddenEntityTypeOptions}
            />
            <DatatypeCardStatusLabel numOfSynced={numOfSynced} numOfFailed={numOfFailed} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
