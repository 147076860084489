import { useQuery, useQueryClient } from '@tanstack/react-query';
import { logger } from 'utils';
import { getModelMapping } from 'api';
import { ModelMapping } from 'types/modelMapping';

export const useFetchModelMapping = () => {
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: ['ModelMapping'],
    queryFn: () =>
      getModelMapping()
        .then((modelMappingData: ModelMapping) => modelMappingData)
        .catch(() => {
          logger.error('An error occurred when trying to fetch the model mappings.');
          return null;
        }),
  });

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['ModelMapping'] });
  };

  return [data, isLoading, invalidate] as const;
};
