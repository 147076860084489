import { useSearchParams } from 'react-router-dom';
import { TOS_ACCEPT_LOCAL_QUERY_STRING, TOS_ACCEPT_LOCAL_STORAGE_KEY } from 'commonConstants';

export const useTOSAccepted = () => {
  const [searchParams] = useSearchParams();
  const isTOSAcceptedQueryString = !!searchParams.get(TOS_ACCEPT_LOCAL_QUERY_STRING);

  if (isTOSAcceptedQueryString) {
    localStorage.setItem(TOS_ACCEPT_LOCAL_STORAGE_KEY, 'true');
  }

  const tosAccepted =
    isTOSAcceptedQueryString || !!localStorage.getItem(TOS_ACCEPT_LOCAL_STORAGE_KEY);

  return tosAccepted;
};
