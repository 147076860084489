import React, { useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useFetchTenant } from 'hooks/useFetchTenant';
import { useNavigateToPreviousPage } from 'hooks/useNavigateToPreviousPage';
import { shouldDisplayCollectUserInfoForm } from 'utils';
import {
  CircularLoading,
  Section,
  TermsOfServiceForm,
  TermsOfServiceHeader,
  TermsOfServiceText,
  UserInfoForm,
} from 'views';
import { UserFormInputsValidation, UserFormInputsValues } from 'types';
import { DISPLAY_COLLECT_USER_FORM_LOCAL_STORAGE_KEY } from 'commonConstants';

export const ConnectFlowTermsOfServiceContainer: React.FC<{
  banner?: string;
  onContinue: () => void;
  connectorId: string;
  isTOSFormValid: boolean;
  setIsTOSFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  username?: string;
  userFormInputValues: UserFormInputsValues;
  setUserFormInputValues: React.Dispatch<React.SetStateAction<UserFormInputsValues>>;
}> = ({
  banner,
  onContinue,
  connectorId,
  isTOSFormValid,
  setIsTOSFormValid,
  username,
  userFormInputValues,
  setUserFormInputValues,
}) => {
  const { tenant: tenantInfo, isLoading: loading } = useFetchTenant();
  const navigateToPreviousPage = useNavigateToPreviousPage(tenantInfo?.vendor_info?.connector_id);
  const shouldDisplayForm = localStorage.getItem(DISPLAY_COLLECT_USER_FORM_LOCAL_STORAGE_KEY);

  const shouldDisplayTheCollectUserInfoForm = useMemo(
    () => shouldDisplayCollectUserInfoForm(shouldDisplayForm ?? ''),
    [shouldDisplayForm]
  );

  const [userFormInputValidation, setUserFormInputValidation] = useState<UserFormInputsValidation>({
    firstName: false,
    lastName: false,
    companyName: false,
    companyEmail: false,
  });

  const [consentTOS, setConsentTOS] = useState(false);

  const isFormValid = (isTOSconsented: boolean) => {
    if (shouldDisplayTheCollectUserInfoForm) {
      if (
        userFormInputValues.firstName &&
        userFormInputValues.lastName &&
        userFormInputValues.companyName &&
        userFormInputValues.companyEmail &&
        isTOSconsented
      ) {
        setIsTOSFormValid(true);
      } else {
        setIsTOSFormValid(false);
      }
    } else {
      isTOSconsented ? setIsTOSFormValid(true) : setIsTOSFormValid(false);
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const key = event.target.id;

    setUserFormInputValues({ ...userFormInputValues, [key]: value });
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const key = event.target.id;

    setUserFormInputValidation({ ...userFormInputValidation, [key]: !value });
    isFormValid(consentTOS);
  };

  const onTOSChange = () => {
    setConsentTOS((previous) => !previous);
    isFormValid(!consentTOS);
  };

  if (loading) {
    return <CircularLoading />;
  }

  if (tenantInfo?.['vendor_info']) {
    // This account already has a vendor associated with it, redirect to home page
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      <Stack mb={4}>
        <TermsOfServiceHeader userName={username} vendorBanner={banner} />
      </Stack>
      {shouldDisplayTheCollectUserInfoForm && (
        <UserInfoForm
          onBlur={onBlur}
          onInputChange={onInputChange}
          userFormInputValidation={userFormInputValidation}
          UserFormInputsValues={userFormInputValues}
        />
      )}
      <Section showBorder>
        <TermsOfServiceText />
      </Section>
      <TermsOfServiceForm
        consentTOS={consentTOS}
        isTOSFormValid={isTOSFormValid}
        onChange={onTOSChange}
        onContinue={onContinue}
        onCancel={() => navigateToPreviousPage(document.referrer, connectorId)}
      />
    </>
  );
};
