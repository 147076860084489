import { SystemStatus } from 'enums';

export const shouldDisplayInitialSyncInProgressMessage = (args: {
  isAnHourPastSinceConnection: boolean;
  vendorId: string;
  connectorId: string;
  systemStatus: SystemStatus;
}) =>
  !args.isAnHourPastSinceConnection &&
  args.vendorId !== args.connectorId &&
  args.systemStatus === SystemStatus.Connected;

export const shouldDisplayInitialSystemStatus = (
  isAnHourPastSinceConnection: boolean,
  systemStatus: SystemStatus
) => !isAnHourPastSinceConnection && systemStatus === SystemStatus.LookingIntoIt;
