import React from 'react';
import { Typography } from '@mui/material';
import { Link, Section } from 'views';
import { TestID } from 'testID';

export const GeneralSettingsSection: React.FC<{
  configuredByEmail: string;
  tenantInternalId: string;
  vendorId: string;
  vendorTitle: string;
}> = ({ vendorId, configuredByEmail, vendorTitle, tenantInternalId }) => (
  <Section header="Account info" data-testid={TestID.GeneralSettingsSection} showBorder>
    <Typography mt={1} variant="body1" gutterBottom>
      Tenant ID: {tenantInternalId}
    </Typography>
    <Typography variant="body1" gutterBottom>
      Authorised by: {configuredByEmail}
    </Typography>
    <Typography variant="body1" gutterBottom>
      Go to the{' '}
      <Link to={`/connector/${vendorId}`} data-testid={TestID.SettingsPageGeneralStatusLink}>
        {vendorTitle} connector
      </Link>{' '}
      to see the sync status.
    </Typography>
  </Section>
);
