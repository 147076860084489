import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import { CardMedia, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TestID } from 'testID';
import type { SystemState } from 'types';
import { SystemStatus } from 'enums';
import { lightTheme } from 'themes';
import { ConnectorCardWrapper } from './ConnectorCardWrapper.view';
import { SystemStatusIndicator } from '../SystemStatusIndicator/SystemStatusIndicator.view';
import { Tooltip } from '../Tooltip/Tooltip.view';

export const ConnectorCard: React.FC<{
  handleClick: () => void;
  logo: string;
  systemState: SystemState;
  connectorTitle: string;
  description: string;
  isVendor?: boolean;
  testId?: string;
}> = ({ handleClick, logo, systemState, connectorTitle, description, isVendor, testId }) => (
  <ConnectorCardWrapper handleClick={handleClick}>
    <Stack justifyContent={'space-between'} sx={{ height: '100%' }} data-testid={testId}>
      <Stack>
        <Stack justifyContent={'space-between'} direction="row" alignItems="flex-start">
          <CardMedia
            component="img"
            sx={{ height: 55, width: 'auto', objectFit: 'contain' }}
            alt="service logo"
            src={logo}
          />
          <Stack direction="row" gap={1}>
            {isVendor && (
              <Tooltip title="Your central service" placement="top">
                <StarIcon
                  sx={{ color: lightTheme.palette.warning.main }}
                  data-testid={TestID.DashboardCardVendorStarIcon}
                />
              </Tooltip>
            )}
            {systemState.title?.toLowerCase() !== SystemStatus.NotConnected && (
              <SystemStatusIndicator
                systemState={{
                  ...systemState,
                  description: systemState.title,
                }}
                testId={TestID.SystemStatusIndicator}
                iconOnly={true}
              />
            )}
          </Stack>
        </Stack>
        <Typography mt={3} variant="h6" sx={{ fontWeight: 'bold' }}>
          {connectorTitle}
        </Typography>
        <Typography mt={1} variant="body1">
          {description}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography gutterBottom color="text.secondary" variant="body2">
          Built by Sesam
        </Typography>
      </Stack>
    </Stack>
  </ConnectorCardWrapper>
);
