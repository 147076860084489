import { useAuth0 } from '@auth0/auth0-react';

export const useLogout = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    if (window.zE) {
      window.zE('messenger', 'logoutUser');
    }

    logout({
      logoutParams: {
        returnTo: window.location.origin + '/onboarding',
      },
    });
  };

  return handleLogout;
};
