import React from 'react';
import { ConnectApikeyContainer } from './ConnectApikey.container';
import { ConnectOauth2Container } from './ConnectOauth2.container';
import type { AdditionalProperties, Connector, ConnectorType } from 'types/connector';

export const ConnectAuthStepContainer: React.FC<{
  connectorType: ConnectorType;
  isReauthoriseFlow: boolean;
  hasVendor: boolean;
  connector: Connector;
  vendorTitle?: string;
  skipApiKey: boolean;
  showButtonLoader: boolean;
  shouldSelectAccount?: boolean;
  vendorId?: string;
  onOAuth2Connect: (additionalValues: AdditionalProperties) => void;
  onApiKeyConnect: (apiKey: string, additionalValues: AdditionalProperties) => void;
}> = ({
  connectorType,
  isReauthoriseFlow,
  hasVendor,
  connector,
  skipApiKey,
  showButtonLoader,
  shouldSelectAccount,
  onOAuth2Connect,
  onApiKeyConnect,
  vendorId,
  vendorTitle,
}) => {
  const customSections = connector?.connectFlow?.customSections;

  return (
    <>
      {connectorType === 'oauth2' && (
        <ConnectOauth2Container
          onConnect={onOAuth2Connect}
          isReauthoriseFlow={isReauthoriseFlow}
          connector={connector}
          vendorTitle={vendorTitle}
          hasVendor={hasVendor}
          shouldSelectAccount={shouldSelectAccount}
          vendorId={vendorId}
        />
      )}
      {(connectorType === 'api_key' || connectorType === 'open') && (
        <ConnectApikeyContainer
          hasVendor={hasVendor}
          vendorTitle={vendorTitle || ''}
          connector={connector}
          onConnect={onApiKeyConnect}
          skipApiKey={skipApiKey}
          isReauthoriseFlow={isReauthoriseFlow}
          showButtonLoader={showButtonLoader}
          customSections={customSections}
        />
      )}
    </>
  );
};
