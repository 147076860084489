import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNavigateToPreviousPageFn } from 'utils';

export const useNavigateToPreviousPage = (vendorId?: string) => {
  const navigate = useNavigate();

  const navigateToPreviousPage = useMemo(
    () =>
      getNavigateToPreviousPageFn({
        navigate,
        location: window.location,
        vendorId,
        homepage: {
          connectorId: 'wave',
          url: 'https://www.wave.sesam.io',
        },
      }),
    [navigate, vendorId]
  );

  return navigateToPreviousPage;
};
