import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const SimpleLoading = () => <span>Loading...</span>;

export const AuthProtectedRoute = () => {
  const location = useLocation();

  const Component = withAuthenticationRequired(Outlet, {
    onRedirecting: SimpleLoading,
    returnTo: location.pathname + location.search,
  });

  return <Component />;
};
