import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { useNotificationMessage } from 'hooks/useNotificationMessage';
import { NotificationMessageType } from 'types';
import { lightInfoBorderColor } from 'themes/variables';
import styles from './NotificationMessage.module.scss';

export const NotificationMessage: React.FC<
  React.PropsWithChildren<{
    title?: string;
    notificationType: NotificationMessageType;
    testId?: string;
    sx?: SxProps<Theme>;
    readMoreButton?: boolean;
  }>
> = ({ title, notificationType = 'info', children, testId, sx, readMoreButton }) => {
  const readMoreBox = useRef<HTMLDivElement>(null);
  const customData = useNotificationMessage(notificationType);
  const [showReadmeButton, setShowReadmeButton] = useState(false);
  const [expendedBox, setExpendedBox] = useState(false);
  const [readMoreButtonText, setReadMoreButtonText] = useState('Read more');

  useEffect(() => {
    if (readMoreBox.current && readMoreBox.current?.clientHeight === 48) {
      setShowReadmeButton(true);
      setReadMoreButtonText('Read more');
    } else {
      setShowReadmeButton(false);
      setReadMoreButtonText('See less');
    }
  }, []);

  useEffect(() => {
    if (expendedBox) {
      setReadMoreButtonText('See less');
    } else {
      setReadMoreButtonText('Read more');
    }
  }, [expendedBox]);

  const onReadMoreClick = () => {
    setExpendedBox((expended) => !expended);
  };

  return (
    <Box sx={{ ...customData.sx, ...sx }} data-testid={testId}>
      {title && (
        <Typography variant="h5" mb={1} sx={{ fontSize: '20px', fontWeight: 500 }}>
          {title}
        </Typography>
      )}
      {readMoreButton ? (
        <Box
          ref={readMoreBox}
          className={styles.readMore}
          sx={{
            display: expendedBox ? 'block' : '-webkit-box',
          }}
        >
          {children}
        </Box>
      ) : (
        children
      )}
      {showReadmeButton && (
        <Button
          onClick={onReadMoreClick}
          variant="text"
          sx={{ mt: 2, color: lightInfoBorderColor }}
          color="info"
        >
          {readMoreButtonText}
        </Button>
      )}
    </Box>
  );
};
