import React from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, Stack, Typography } from '@mui/material';
import { SystemState } from 'types';
import { DatatypeStatus } from 'enums';
import { SystemStatusIndicator } from '../SystemStatusIndicator/SystemStatusIndicator.view';

export const DatatypeCardSummary: React.FC<{
  Icon: SvgIconComponent;
  label: string;
  dataTypeStatus: DatatypeStatus;
  systemState: SystemState;
}> = ({ Icon, label, dataTypeStatus, systemState }) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
  >
    <Stack direction="row" justifyContent={'space-between'} sx={{ width: '100%' }} pr={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Icon />
        <Typography variant="h6">{label}</Typography>
      </Stack>
      {dataTypeStatus && (
        <SystemStatusIndicator systemState={systemState} testId={systemState.testId} />
      )}
    </Stack>
  </AccordionSummary>
);
