import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Stack, Typography } from '@mui/material';
import { Section, SinceTimestampText } from 'views';
import { TestID } from 'testID';

export const ProfileSection: React.FC<{
  email?: string;
  userName?: string;
  since: string;
  tenantTitle: string;
  timestamp: string;
  vendorId?: string;
}> = ({ email, since, userName, tenantTitle, timestamp, vendorId }) => (
  <Section header="Profile" data-testid={TestID.ProfileSection} showBorder>
    {!userName?.includes('@') && (
      <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 1 }} mt={1}>
        <PersonOutlineOutlinedIcon sx={{ fill: 'background.darkerGrey' }} />
        <Typography variant="body1">{userName}</Typography>
      </Stack>
    )}
    <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 1 }}>
      <MailOutlineIcon sx={{ fill: 'background.darkerGrey' }} />
      <Typography variant="body1">{email}</Typography>
    </Stack>
    {vendorId ? (
      <Typography variant="body1" gutterBottom>
        You authorized your connection to {tenantTitle}{' '}
        {timestamp && <SinceTimestampText since={since} timestamp={timestamp} />}.
      </Typography>
    ) : (
      <Typography variant="body1" gutterBottom>
        You have not authorized your connection to any vendor yet.
      </Typography>
    )}
  </Section>
);
