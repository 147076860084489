import React from 'react';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { Badge, IconButton } from '@mui/material';
import { TestID } from 'testID';

export const SystemNotificationsButton: React.FC<{
  unreadMessages: number;
  invisible: boolean;
  isOpen: boolean;
  openNotificationPanel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({ unreadMessages, invisible = false, isOpen, openNotificationPanel }) => (
  <IconButton
    size="small"
    aria-label="account of current user"
    aria-controls="menu-notifications"
    aria-haspopup="true"
    onClick={openNotificationPanel}
    color="info"
    data-testid={TestID.ToggleSystemNotificationsButton}
  >
    <Badge
      badgeContent={unreadMessages}
      color="error"
      overlap="circular"
      max={10}
      data-testid={TestID.SystemNotificationsBadge}
      sx={{
        '& .MuiBadge-badge': { border: '1px solid #ffffff' },
      }}
      invisible={invisible}
    >
      <CircleNotificationsIcon sx={{ fontSize: 40 }} color={isOpen ? 'action' : 'info'} />
    </Badge>
  </IconButton>
);
