import React from 'react';
import { Grid } from '@mui/material';
import { DashboardConnectorCardContainer } from './DashboardConnectorCard.container';
import { TestID } from 'testID';
import { Connector } from 'types/connector';

export const DashboardCardsContainer: React.FC<{
  connectors: Connector[];
  tenantId?: string;
  testId?: string;
}> = ({ connectors, tenantId, testId }) => (
  <>
    {connectors.map((connector: Connector) => (
      <Grid item key={connector.id}>
        <DashboardConnectorCardContainer
          key={connector.id + connector.connected.toString()}
          connector={connector}
          isVendor={tenantId === connector.id}
          testId={testId ?? `${TestID.SystemCard}${connector.id}`}
        />
      </Grid>
    ))}
  </>
);
