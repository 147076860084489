import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import get from 'lodash/get';
import { useFetchModelMapping } from 'hooks/useFetchModelMapping';
import { useNavigateToPreviousPage } from 'hooks/useNavigateToPreviousPage';
import { getDocsUrlForCurrentConnector } from 'utils';
import { ConnectFlowEntityTypesContainer } from './ConnectFlowEntityTypes.container';
import { Button, CircularLoading, ConnectFlowHeader, ConnectFlowReviewSections } from 'views';
import { TestID } from 'testID';
import type { ConnectorType, Connectors } from 'types/connector';

export const ConnectFlowInfoContainer: React.FC<{
  onConnect: () => void;
  connectors: Connectors;
  connectorType: ConnectorType;
  hasVendor: boolean;
  vendorId?: string;
  vendorTitle?: string;
  vendorIcon?: string;
}> = ({ onConnect, connectors, connectorType, hasVendor, vendorId, vendorTitle, vendorIcon }) => {
  const params = useParams();
  const connectorId = params.connectorId || '';
  const connector = connectors[connectorId];
  const connectorLogo = connector?.logo_symbol ?? connector?.logo;
  const isOauth = get(connector, 'auth.type') === 'oauth2';
  const [modelMapping, isModelMappingLoading] = useFetchModelMapping();
  const vendorConnector = connectors[vendorId ?? ''];
  const documentationUrl = getDocsUrlForCurrentConnector(connector, vendorConnector);
  const navigateToPreviousPage = useNavigateToPreviousPage(vendorId);

  if (isModelMappingLoading) {
    return <CircularLoading />;
  }

  return (
    <Box data-testid={TestID.ConnectFlowInfoPage}>
      <ConnectFlowHeader connectorTitle={connector.title} connectorLogo={connectorLogo} />
      <ConnectFlowReviewSections
        hasVendor={hasVendor}
        connectorTitle={connector.title}
        vendorTitle={vendorTitle}
        connectorType={connectorType}
        customSections={connector?.connectFlow?.customSections}
        documentationUrl={documentationUrl}
      >
        {connectorId !== vendorId && (
          <Stack mt={2} flexDirection="row" flexWrap="wrap">
            {vendorId !== connectorId && (
              <ConnectFlowEntityTypesContainer
                connectors={connectors}
                vendorId={vendorConnector?.connector_base ?? vendorId ?? ''}
                connectorId={connector.connector_base ?? connectorId}
                modelMapping={modelMapping || {}}
                vendorIcon={vendorIcon || ''}
                connectorIcon={connectorLogo}
                hasVendor={hasVendor}
              />
            )}
          </Stack>
        )}
      </ConnectFlowReviewSections>
      {isOauth && (
        <Typography variant="body1" gutterBottom>
          Continue to {connector.title} for authorisation.
        </Typography>
      )}
      <Stack spacing={2} direction="row" mt={2}>
        <Button variant="contained" onClick={onConnect} data-testid={TestID.ContinueButton}>
          Continue
        </Button>
        <Button
          onClick={() => navigateToPreviousPage(document.referrer, connectorId)}
          data-testid={TestID.ConnectFlowInfoCancelButton}
        >
          Cancel
        </Button>
      </Stack>
    </Box>
  );
};
