import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorView } from 'views';
import { Connectors } from 'types/connector';
import { ErrorMessages } from 'commonConstants';

const ButtonText = 'Go back to homepage';

export const ConnectorErrorContainer: React.FC<{
  connectorId?: string;
  connectors?: Connectors | null;
  connected?: boolean;
}> = ({ connectorId, connectors }) => {
  const navigate = useNavigate();

  if (!connectors) {
    return <ErrorView open />;
  }

  if (connectorId && !connectors?.[connectorId || '']) {
    return (
      <ErrorView
        errorTitle={ErrorMessages.ConnectorIdNotFound}
        actionButtonText={ButtonText}
        onActionClick={() => navigate('/')}
        open
      />
    );
  }

  if (connectorId && !connectors?.[connectorId || '']?.connected) {
    const errorTitle = `${connectors[connectorId].title} is not connected`;

    return (
      <ErrorView
        errorTitle="Service is not connected"
        errorMessage={errorTitle}
        actionButtonText={ButtonText}
        onActionClick={() => navigate('/')}
        open
      />
    );
  }

  return <ErrorView errorMessage={ButtonText} onActionClick={() => navigate('/')} />;
};
