import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useLogout } from 'hooks/useLogout';
import { UserMenu, UserMenuButton } from 'views';

export const UserMenuContainer: React.FC = () => {
  const { user } = useAuth0();
  const handleLogout = useLogout();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element>();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const handleNavigate = (path: string) => {
    handleCloseMenu();
    navigate(path);
  };

  return (
    <>
      <UserMenuButton handleOpenMenu={handleOpenMenu} isOpen={isOpen} />
      <UserMenu
        handleLogout={handleLogout}
        username={user?.name}
        anchorEl={anchorEl}
        isOpen={isOpen}
        handleCloseMenu={handleCloseMenu}
        handleNavigate={handleNavigate}
        disableMyAccount={window.location.pathname.includes('/wave/connect') ? true : false}
      />
    </>
  );
};
