import React from 'react';
import { TextField } from '@mui/material';
import get from 'lodash/get';
import { TestID } from 'testID';
import { AdditionalProperties } from 'types/connector';

export const AdditionalPropertiesInputs: React.FC<{
  additionalProperties?: AdditionalProperties;
  additionalValues: AdditionalProperties;
  getAdditionalPropertyValue: (propertyKey: string) => string | undefined;
  isReauthoriseFlow: boolean;
  firstBlur: boolean;
  setFirstBlur: React.Dispatch<React.SetStateAction<boolean>>;
  setAdditionalValues: React.Dispatch<React.SetStateAction<AdditionalProperties>>;
  shouldDisable: boolean;
}> = ({
  additionalProperties,
  additionalValues,
  getAdditionalPropertyValue,
  isReauthoriseFlow,
  firstBlur,
  setFirstBlur,
  setAdditionalValues,
  shouldDisable = false,
}) => {
  if (!additionalProperties || isReauthoriseFlow) {
    return null;
  }

  return (
    <>
      {Object.keys(additionalProperties).map((key) => {
        const obj = additionalProperties[key];
        const additionalPropertyValue = getAdditionalPropertyValue(key);

        return (
          <TextField
            key={key}
            id={key}
            onBlur={() => setFirstBlur(true)}
            required={!isReauthoriseFlow}
            label={obj.description}
            type="text"
            variant="outlined"
            margin="normal"
            value={
              isReauthoriseFlow
                ? additionalPropertyValue
                : get(additionalProperties, `${key}.value`)
            }
            error={!get(additionalValues, `${key}.value`) && firstBlur}
            onChange={({ target: { value } }) => {
              setAdditionalValues({
                ...additionalValues,
                [key]: { ...additionalValues[key], value },
              });
            }}
            data-testid={TestID.AccountIDTextField}
            disabled={isReauthoriseFlow || shouldDisable}
          />
        );
      })}
    </>
  );
};
