export enum HTTPStatus {
  NotImplemented = 501,
}

export enum SystemStatus {
  /**
   * When all entities has either the following datatypes statuses: `DatatypeStatus.Initialising | OK | Disabled`.
   */
  Connected = 'connected',
  /**
   * One or more datatypes has `DatatypeStatus.Failing` status.
   */
  LimitedService = 'limited service',
  /**
   * All datatypes has `DatatypeStatus.Failing` status.
   */
  LookingIntoIt = "we're looking into it",
  /**
   * Token status is `SystemStatus.RefreshFailed`.
   */
  NeedsAttention = 'needs attention',
  /**
   * System is not connected.
   */
  NotConnected = 'not connected',
  /**
   * Initialising the system. This status should be displayed only when the current status is `NeedsAttention`, and only for the firs 5 minutes.
   */
  InitialisingSystem = 'initialising system',
  /**
   * Initial synchronisation in progress.
   */
  InitialSync = 'initial-sync',
}

export enum DatatypeStatus {
  Disabled = 'disabled',
  Failing = 'failing',
  Initialising = 'initialising',
  OK = 'ok',
  ReadingOnly = 'reading-only',
  WritingOnly = 'writing-only',
}

export enum SystemTokenStatus {
  /**
   * Token status is `SystemStatus.RefreshFailed`.
   */
  RefreshFailed = 'refresh_failed',
  Success = 'success',
}
export enum ConnectFlowSteps {
  TermsOfService = 'terms-of-service',
  InformationReview = 'information-review',
  Authentication = 'authentication',
  Summary = 'summary',
}

export enum ConnectFlowAdditionalSteps {
  SelectAccount = 'select-account',
}

export type ConnectFlowType = 'noVendor' | 'vendor' | 'reauthorise';

export enum AddConnectorCardMode {
  FirstConnector = 'first-connector',
  NextKnownConnector = 'next-known-connector',
  NextUnknownConnector = 'next-unknown-connector',
  AddAnotherUnknownConnector = 'add-another-unknown-connector',
}

export enum AccountOptions {
  HaveAccount = 'user-have-wave-account',
  DoNotHaveAccount = 'user-do-not-have-wave-account',
}

export enum AdvancedConnectFlowTargetElement {
  RadioButton = 'radio-button',
  SendEmailButton = 'send-email-button',
  OpenWindowButton = 'open-window-button',
}

export enum CompactFlowActions {
  NavigateToHomepage = 'navigate-to-homepage',
  DoNotNavigate = 'do-not-navigate',
  DisplayLoader = 'display-loader',
  StartOAuth2CompactFlow = 'start-oauth2-compact-flow',
  StartApiKeyCompactFlow = 'start-api-key-compact-flow',
  StartOAuth2SecondCompactFlow = 'start-oauth2-second-compact-flow',
  StartSecondApiKeyCompactFlow = 'start-api-key-second-compact-flow',
}
