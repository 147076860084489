import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDatatypeRelations } from 'hooks/useDatatypeRelations';
import { DatatypeSummaryCard } from 'views';
import type { Datatypes } from 'types/connector';
import type { ModelMapping } from 'types/modelMapping';

export const DatatypeSummaryCardContainer: React.FC<{
  vendorId: string;
  systemId: string;
  datatypeId: string;
  modelMapping: ModelMapping;
  connectorIcon: string;
  vendorIcon: string;
  vendorName: string;
  vendorDatatypes: Datatypes;
}> = ({
  vendorId,
  systemId,
  datatypeId,
  modelMapping,
  connectorIcon,
  vendorIcon,
  vendorName,
  vendorDatatypes,
}) => {
  const relations = useDatatypeRelations({
    datatypeId,
    modelMapping,
    currentSystemId: systemId,
    primarySystem: vendorId,
  });

  if (isEmpty(relations)) {
    return null;
  }

  return (
    <DatatypeSummaryCard
      relations={relations}
      connectorIcon={connectorIcon}
      vendorName={vendorName}
      vendorDatatypes={vendorDatatypes}
      vendorIcon={vendorIcon}
    />
  );
};
