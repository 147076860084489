import { Account, Accounts } from 'types/connector';

export const getFirstAccount = (accounts: Accounts) => {
  if (accounts && Object.keys(accounts).length > 0) {
    return accounts[Object.keys(accounts)[0]];
  }
};

/**
 * Indicates if the given system is connected or not.
 */
export const getIsConnected = (account?: Account) => !!account?.account_id;
