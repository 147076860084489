import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import { TestID } from 'testID';
import { lightTheme } from 'themes';
import { SesamTalkLinks } from 'commonConstants';
import { BySesamSVG } from './BySesamSVG.view';
import { Link } from '../Link/Link.view';

export const PoweredBySesam: React.FC<{ text?: string }> = ({ text = 'powered by' }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="fit-content"
      data-testid={TestID.PoweredBySesamLogo}
    >
      <Typography
        paragraph={false}
        variantMapping={{ body1: 'span' }}
        color={lightTheme.palette.text.primary}
        fontSize={14}
        height={23}
      >
        {text}
      </Typography>{' '}
      <Link
        to={SesamTalkLinks.Sesam}
        underline="none"
        target="_blank"
        title="Sesam.io homepage"
        hideOpenInNewIcon={true}
      >
        <Stack sx={{ marginLeft: '4px', position: 'relative', bottom: isMobile ? '1px' : 0 }}>
          <BySesamSVG width={48} />
        </Stack>
      </Link>
    </Stack>
  );
};
