import React from 'react';
import { Typography } from '@mui/material';
import { TestID } from 'testID';
import { SesamTalkLinks } from 'commonConstants';
import { Link } from '../Link/Link.view';

export const SynchronisingText: React.FC<{
  isVendorConnector: boolean;
  vendorTitle: string;
  connectorTitle: string;
}> = ({ isVendorConnector, vendorTitle, connectorTitle }) =>
  isVendorConnector ? (
    <Typography variant="body1" gutterBottom>
      We are currently synchronising these {vendorTitle}{' '}
      <Link
        to={SesamTalkLinks.SesamTalkDocsEntityTypes}
        target="_blank"
        data-testid={TestID.ConnectorSyncPageEntityTypesLink1}
      >
        entity types
      </Link>
      .
    </Typography>
  ) : (
    <Typography variant="body1" gutterBottom>
      We are currently synchronising these {connectorTitle}{' '}
      <Link
        to={SesamTalkLinks.SesamTalkDocsEntityTypes}
        target="_blank"
        data-testid={TestID.ConnectorSyncPageEntityTypesLink2}
      >
        entity types
      </Link>{' '}
      with your {vendorTitle} account.
    </Typography>
  );
