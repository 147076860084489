import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Section } from 'views';
import { TestID } from 'testID';

export const ContactEmailSection: React.FC<{
  email?: string;
}> = ({ email }) => (
  <Section header="Contact email" data-testid={TestID.ContactEmailSection} showBorder>
    <Stack direction="column">
      <Typography variant="body1" gutterBottom>
        This is the primary email we will use if we need to reach out to you:
      </Typography>
      <Typography variant="h6" gutterBottom>
        {email}
      </Typography>
    </Stack>
  </Section>
);
