import React from 'react';
import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { TestID } from 'testID';
import type { DatatypeOptionState } from 'types';
import type { Datatype } from 'types/connector';
import type { HiddenEntityTypeOptions } from 'types/vendor';
import { lightTheme } from 'themes';

export const DatatypeCardOptions: React.FC<{
  datatypeId: string;
  inProgress: boolean;
  onOptionToggle: (datatypeId: string, optionName: keyof DatatypeOptionState) => void;
  optionsToDisplay: Datatype;
  hiddenEntityTypeOptions: HiddenEntityTypeOptions;
}> = ({ datatypeId, optionsToDisplay, inProgress, onOptionToggle, hiddenEntityTypeOptions }) => {
  const fullSyncOptionVisible =
    !hiddenEntityTypeOptions.includes('fullsync') && optionsToDisplay?.fullsync !== undefined;
  const shareEnabledOptionVisible =
    !hiddenEntityTypeOptions.includes('share_enabled') &&
    optionsToDisplay?.['share_enabled'] !== undefined;

  const allEntityTypeOptionsVisible = fullSyncOptionVisible || shareEnabledOptionVisible;
  const isFullSyncChecked = optionsToDisplay['fullsync'];
  const isShareEnabledChecked = optionsToDisplay['share_enabled'];

  const getToggleColor = (isChecked?: boolean) =>
    isChecked ? undefined : lightTheme.palette.background.darkerGrey;

  return allEntityTypeOptionsVisible ? (
    <Box my={2} data-testid={TestID.DatatypeOptionsContainer}>
      <Typography variant="body1">Options</Typography>
      <Stack data-testid={TestID.DatatypeOptionsStack}>
        {fullSyncOptionVisible && (
          <FormControlLabel
            disabled={inProgress}
            control={
              <Switch
                onClick={() => onOptionToggle(datatypeId, 'fullsync')}
                checked={isFullSyncChecked}
                style={{
                  color: getToggleColor(isFullSyncChecked),
                }}
              />
            }
            label="Full sync"
            data-testid={TestID.DatatypeOptionFullSync}
          />
        )}
        {shareEnabledOptionVisible && (
          <FormControlLabel
            disabled={inProgress}
            control={
              <Switch
                onClick={() => onOptionToggle(datatypeId, 'share_enabled')}
                checked={isShareEnabledChecked}
                style={{
                  color: getToggleColor(isShareEnabledChecked),
                }}
              />
            }
            label="Share enabled"
            data-testid={TestID.datatypeOptionShareEnabled}
          />
        )}
      </Stack>
    </Box>
  ) : null;
};
