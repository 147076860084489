import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/system';
import { TestID } from 'testID';
import { Button } from '../Button/Button.view';

export const ConnectFlowButtons: React.FC<{
  isConnectButtonDisabled: boolean;
  isReauthoriseFlow?: boolean;
  showButtonLoader?: boolean;
  onConnect: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: () => void;
  text?: string;
}> = ({
  isConnectButtonDisabled,
  onConnect,
  onCancel,
  isReauthoriseFlow,
  text,
  showButtonLoader,
}) => (
  <Stack direction="row" spacing={2} mt={2}>
    <LoadingButton
      variant="contained"
      onClick={onConnect}
      sx={{ alignSelf: 'flex-start' }}
      disabled={isConnectButtonDisabled}
      loading={showButtonLoader}
      data-testid={TestID.ConnectSystemButton}
      type="submit"
    >
      {text ? text : isReauthoriseFlow ? 'Reconnect' : 'Connect'}
    </LoadingButton>
    <Button onClick={onCancel}>Cancel</Button>
  </Stack>
);
