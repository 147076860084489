import React from 'react';
import { Box, Stack } from '@mui/material';

export const SidebarLayout: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Stack direction={{ xs: 'column', md: 'row' }}>{children}</Stack>
);

export const SidebarLayoutContent: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Box sx={{ flex: '3 1 0', pl: { xs: 0, md: 2 } }} mt={{ xs: 5, md: 0 }}>
    {children}
  </Box>
);

export const SidebarLayoutMenu: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Stack sx={{ flex: '1 1 0', mr: 4 }}>{children}</Stack>
);
