import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import styles from './DatatypeSummaryCard.module.scss';

export const DatatypeSummaryCardItem: React.FC<{ vendorIcon: string; relationName: string }> = ({
  vendorIcon,
  relationName,
}) => (
  <Stack className={styles.itemWrapper} flexDirection="row">
    <Box
      component="img"
      sx={{ maxWidth: '1.5rem', objectFit: 'contain' }}
      src={vendorIcon}
      alt={`Vendor name: ${relationName}`}
      loading="lazy"
      mr={1}
    />
    <Typography variant="body1" className={styles.text} title={relationName}>
      {relationName}
    </Typography>
  </Stack>
);
