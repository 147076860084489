import React, { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import { lightTheme } from 'themes';
import styles from './Banner.module.scss';

export const BannerWrapper: React.FC<
  PropsWithChildren<{
    position?: 'sticky' | 'static' | 'absolute' | 'fixed';
    testId?: string;
  }>
> = ({ children, position = 'sticky', testId }) => {
  return (
    <Stack
      position={position}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      className={styles.bannerWrapper}
      sx={{ backgroundColor: lightTheme.palette.primary.light }}
      data-testid={testId}
    >
      {children}
    </Stack>
  );
};
