import React, { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import { ConnectFlowStepperContainer } from './ConnectFlowStepper.container';
import { CircularLoading } from 'views';

export const ConnectFlowPageWrapperContainer: React.FC<
  PropsWithChildren<{
    step: number;
    connectorTitle: string;
    hasVendor: boolean;
    selectAccount?: { name: string };
    showScreenLoader?: boolean;
  }>
> = ({ children, step, connectorTitle, hasVendor, showScreenLoader, selectAccount }) => (
  <Stack direction="row" justifyContent="center">
    {showScreenLoader ? (
      <CircularLoading />
    ) : (
      <Stack sx={{ width: { xs: '100%', md: '50%' } }} alignSelf="center">
        <ConnectFlowStepperContainer
          step={step}
          connectorTitle={connectorTitle}
          hasVendor={hasVendor}
          selectAccount={selectAccount}
        />
        {children}
      </Stack>
    )}
  </Stack>
);
