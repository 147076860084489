import { useMemo } from 'react';
import { getAvailableConnectors, getConnectedConnectors, sortConnectors } from 'utils';
import { Connectors } from 'types/connector';

export const useConnectors = (connectors: Connectors, vendorId: string) =>
  useMemo(() => {
    const sortedConnectors = sortConnectors(connectors, vendorId);
    const connectedConnectors = getConnectedConnectors(sortedConnectors);
    const availableConnectors = getAvailableConnectors(sortedConnectors);

    const noConnectorsFound = sortedConnectors.length < 1;
    const showConnectedConnectors = connectedConnectors.length > 0;
    const showDisconnectedConnectors = availableConnectors.length > 0;

    return {
      sortedConnectors,
      connectedConnectors,
      availableConnectors,
      noConnectorsFound,
      showConnectedConnectors,
      showDisconnectedConnectors,
    };
  }, [connectors, vendorId]);
