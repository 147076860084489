import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useConnectDocumentTitle } from 'hooks/useConnectDocumentTitle';
import { useCurrentConnector } from 'hooks/useCurrentConnector';
import { useGAConnectFlowCurrentStep } from 'hooks/useGAConnectFlowCurrentStep';
import { useZendesk } from 'hooks/useZendesk';
import { sha256 } from 'utils';
import { MainLayout } from 'views';

export const MainLayoutContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { connector } = useCurrentConnector();
  const banner = connector?.vendor?.homepageLogo?.banner ?? connector?.vendor?.banner;
  const { isAuthenticated, user } = useAuth0();

  useConnectDocumentTitle(banner);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `${location.pathname}` });
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthenticated && user?.email) {
      sha256(user.email).then((hashedEmail) => {
        ReactGA.set({ userId: hashedEmail });
      });
    }
  }, [isAuthenticated, user]);

  useZendesk();

  useGAConnectFlowCurrentStep();

  return <MainLayout navigate={navigate} />;
};
