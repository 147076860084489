import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CardMedia, Stack, Typography } from '@mui/material';
import { AddConnectorCardMode } from 'enums';
import { AddConnectorIcon } from '../AddConnectorIcon/AddConnectorIcon.view';
import { ConnectorCardWrapper } from '../ConnectorCard/ConnectorCardWrapper.view';
import styles from './AddConnectorCard.module.scss';

const AlmostThereTitle = 'Almost there!';

const TitlesMap = {
  [AddConnectorCardMode.FirstConnector]: 'Getting started',
  [AddConnectorCardMode.AddAnotherUnknownConnector]: 'Connect another service',
  [AddConnectorCardMode.NextKnownConnector]: AlmostThereTitle,
  [AddConnectorCardMode.NextUnknownConnector]: AlmostThereTitle,
};

const getText = (
  addConnectorCardMode: AddConnectorCardMode,
  nextConnectorName?: string,
  centralConnectorName?: string
) =>
  (addConnectorCardMode === AddConnectorCardMode.NextKnownConnector &&
    `To start the synchronisation, please connect your ${nextConnectorName} account.`) ||
  (addConnectorCardMode === AddConnectorCardMode.FirstConnector &&
    'Begin by connecting your first system to get started.') ||
  (addConnectorCardMode === AddConnectorCardMode.NextUnknownConnector &&
    `Pick the system you want to synchronise with ${centralConnectorName}.`) ||
  (addConnectorCardMode === AddConnectorCardMode.AddAnotherUnknownConnector &&
    'Add another system to synchronise data across your business platform.');

export const AddConnectorCard: React.FC<{
  centralConnectorName?: string;
  addConnectorCardMode: AddConnectorCardMode;
  nextConnectorLogo?: string;
  nextConnectorName?: string;
  onActionClick: () => void;
}> = ({
  addConnectorCardMode,
  onActionClick,
  nextConnectorName,
  nextConnectorLogo,
  centralConnectorName,
}) => (
  <ConnectorCardWrapper handleClick={onActionClick} className={styles.addConnector}>
    <Stack
      justifyContent="space-around"
      alignItems="center"
      direction="column"
      data-testid={addConnectorCardMode}
    >
      <Stack
        justifyContent="flex-start"
        direction="row"
        alignItems="flex-start"
        sx={{ width: '100%' }}
      >
        {addConnectorCardMode === AddConnectorCardMode.NextKnownConnector ? (
          <CardMedia
            component="img"
            sx={{ height: 45, width: 'auto', objectFit: 'contain' }}
            alt="service logo"
            src={nextConnectorLogo}
          />
        ) : (
          <Stack sx={{ fontSize: 50 }}>
            <AddCircleIcon fontSize="inherit" color="info" />
          </Stack>
        )}
      </Stack>
      <Stack mt={3}>
        <Typography variant="h6" mb={1} sx={{ fontWeight: 'bold' }}>
          {TitlesMap[addConnectorCardMode]}
        </Typography>
        <Typography>
          {getText(addConnectorCardMode, nextConnectorName, centralConnectorName)}
        </Typography>
      </Stack>
    </Stack>
    {addConnectorCardMode === AddConnectorCardMode.NextKnownConnector && <AddConnectorIcon />}
  </ConnectorCardWrapper>
);
