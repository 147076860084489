import React from 'react';
import { Stack, Typography } from '@mui/material';
import { extractEntityTypeNameFromRelation, getIconFromDatatype, getRelationName } from 'utils';
import type { Datatypes } from 'types/connector';
import { DatatypeRelationsArray, ModelMapping } from 'types/modelMapping';

export const DatatypesCardRelations: React.FC<{
  relations: DatatypeRelationsArray;
  vendorDatatypes?: Datatypes;
  modelMapping: ModelMapping;
  vendorSystem: string;
}> = ({ relations, modelMapping, vendorDatatypes, vendorSystem }) => (
  <>
    {relations.map((relation) => {
      const subDatatype = extractEntityTypeNameFromRelation(relation);
      const relationName = getRelationName(subDatatype, vendorDatatypes);
      const Img = getIconFromDatatype(modelMapping, vendorSystem, subDatatype);

      return (
        <Stack direction="row" alignItems="center" gap={1} key={relation}>
          <Img />
          <Typography variant="h6">{relationName}</Typography>
        </Stack>
      );
    })}
  </>
);
