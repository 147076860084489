import React from 'react';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { TestID } from 'testID';
import type { SystemState } from 'types';
import { Tooltip } from '../Tooltip/Tooltip.view';

export const SystemStatusIndicator: React.FC<{
  systemState: SystemState;
  testId?: string;
  iconOnly?: boolean;
}> = ({ systemState, testId, iconOnly = false }) => (
  <Tooltip
    title={systemState.description ?? systemState.tooltip}
    arrow
    placement="right"
    data-testid={TestID.SystemStatusIndicatorTooltip}
  >
    <Stack direction="row" alignItems="center" gap={1} data-testid={testId}>
      <systemState.icon sx={{ fill: systemState.iconFill, ml: '-4px' }} />
      {!iconOnly && (
        <Typography variant="body1" component="span">
          {systemState.title}
        </Typography>
      )}
    </Stack>
  </Tooltip>
);
