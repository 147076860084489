import React from 'react';
import { Stack, Typography } from '@mui/material';
import { lightTheme } from 'themes';

export const Section: React.FC<
  React.PropsWithChildren<{
    header?: string;
    mt?: number;
    mb?: number;
    showBorder?: boolean;
    danger?: boolean;
  }>
> = ({ header, children, mt = 0, mb = 3, showBorder = false, danger = false, ...rest }) => {
  const borderColor = danger ? '#ffc1c1' : '#ededed';

  return (
    <Stack
      mt={mt}
      mb={mb}
      sx={
        showBorder
          ? {
              border: `1px solid ${borderColor}`,
              borderRadius: '16px',
              padding: 4,
              backgroundColor: lightTheme.palette.primary.light,
            }
          : undefined
      }
      {...rest}
    >
      <Typography variant="h5" mb={1}>
        {header}
      </Typography>
      {children}
    </Stack>
  );
};
