import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useFetchAvailableAccounts } from 'hooks/useFetchAvailableAccounts';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { useFetchTenant } from 'hooks/useFetchTenant';
import { AccountSelectionTable } from 'utils';
import { connectFlowStepsSignal } from 'signals/connectFlowSteps.signal';
import { AccountSelectionContainer } from 'containers/AccountSelection.container';
import { ConnectFlowPageWrapperContainer } from 'containers/ConnectFlowPageWrapper.container';
import { CircularLoading } from 'views';
import { AccountSelectionTableKey } from 'types';
import { ConnectFlowAdditionalSteps } from 'enums';

export const SelectAccountPage: React.FC = () => {
  const { connectors, connectorsLoading: isConnectorsLoading } = useFetchConnectors();
  const { connectorId } = useParams();
  const location = useLocation();
  const connector = connectors?.[connectorId || ''];
  const {
    availableAccounts,
    invalidate,
    isLoading: isAvailableAccountsLoading,
  } = useFetchAvailableAccounts(connectorId as string, location?.state?.apiKey);
  const { tenant } = useFetchTenant();
  const hasVendor = Boolean(tenant?.vendor_info);

  useEffect(() => {
    connectFlowStepsSignal.emit(ConnectFlowAdditionalSteps.SelectAccount);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- only on un-mounting!
  useEffect(() => () => invalidate(), []);

  if (isConnectorsLoading || isAvailableAccountsLoading) {
    return <CircularLoading />;
  }

  if (!availableAccounts || !availableAccounts?.available_accounts?.length || !connector) {
    return null;
  }

  const accountSelectionTitle =
    AccountSelectionTable[connectorId as AccountSelectionTableKey]?.name;

  return (
    <ConnectFlowPageWrapperContainer
      step={2}
      connectorTitle={connector.title}
      hasVendor={hasVendor}
      selectAccount={{ name: accountSelectionTitle }}
    >
      <AccountSelectionContainer />
    </ConnectFlowPageWrapperContainer>
  );
};
