import { NotificationMessageType } from 'types';
import { lightTheme } from 'themes';
import {
  lightAttentionBackgroundColor,
  lightAttentionFontColor,
  lightErrorBackgroundColor,
  lightInfoBackgroundColor,
  lightInfoBorderColor,
  lightSuccessFontColor,
} from 'themes/variables';

const coreStyles = {
  border: '1px solid',
  borderRadius: 2,
  p: 2,
};

export const NotificationMessagesTable = {
  plain: {
    sx: {
      ...coreStyles,
      borderColor: lightTheme.palette.text.primary,
      color: lightTheme.palette.text.primary,
    },
  },
  info: {
    sx: {
      ...coreStyles,
      borderColor: lightInfoBorderColor,
      backgroundColor: lightInfoBackgroundColor,
      color: lightInfoBorderColor,
    },
  },
  success: {
    sx: {
      ...coreStyles,
      borderColor: lightTheme.palette.success.main,
      backgroundColor: lightSuccessFontColor,
      color: lightTheme.palette.success.main,
    },
  },
  attention: {
    sx: {
      ...coreStyles,
      borderColor: lightTheme.palette.warning.main,
      backgroundColor: lightAttentionBackgroundColor,
      color: lightAttentionFontColor,
    },
  },
  error: {
    sx: {
      ...coreStyles,
      borderColor: lightTheme.palette.error.main,
      backgroundColor: lightErrorBackgroundColor,
      color: lightTheme.palette.error.main,
    },
  },
};

const getMessageCustomData = (notificationType: NotificationMessageType) => {
  const result = NotificationMessagesTable[notificationType];

  return result ? result : NotificationMessagesTable.info;
};

export const useNotificationMessage = (notificationType: NotificationMessageType) => {
  return getMessageCustomData(notificationType);
};
