import React from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { TestID } from 'testID';
import { AdditionalProperties } from 'types/connector';

export const AdditionalPropertiesView: React.FC<{
  additionalValues: AdditionalProperties;
  loading: boolean;
  setAdditionalValues: React.Dispatch<React.SetStateAction<AdditionalProperties | undefined>>;
}> = ({ additionalValues, loading, setAdditionalValues }) => (
  <>
    {Object.keys(additionalValues).map((key, index) => (
      <Stack key={key} direction="row" mt={1}>
        <Typography
          variant="body1"
          mt={1}
          mr={1}
          width="7em"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {capitalize(key?.replace('_', ' '))}:
        </Typography>
        <Stack width="18em" direction="row">
          <TextField
            size="small"
            variant="outlined"
            value={additionalValues[key].value}
            onChange={(e) =>
              setAdditionalValues((prev?: AdditionalProperties) => ({
                ...prev,
                [key]: { value: e.target.value },
              }))
            }
            fullWidth
            disabled={!additionalValues[key].editable || loading}
            data-testid={`${TestID.AdditionalParametersField}-${index + 1}`}
          />
        </Stack>
      </Stack>
    ))}
  </>
);
