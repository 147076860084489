import React from 'react';
import { Stack, Typography } from '@mui/material';
import { TestID } from 'testID';
import { Section } from '../Section/Section.view';

export const ReauthoriseSection: React.FC<
  React.PropsWithChildren<{ connectorName: string; maskedApiKey?: string; apiKeyName?: string }>
> = ({ children, connectorName, maskedApiKey, apiKeyName }) => (
  <Section header="Authorisation" showBorder data-testid={TestID.ReauthoriseSection}>
    <Stack direction="column">
      <Typography variant="body1" gutterBottom>
        In order to connect to {connectorName} you provided us with authorisation when first
        connecting to the system. Use this button if you need to re-authorise Sesam Talk again. This
        can be necessary when a new API key has to be used or there is some change in the
        permissions that we are given.
      </Typography>
      {maskedApiKey && (
        <Stack direction="row" mt={1} data-testid={TestID.ReauthoriseMaskedAPiKey}>
          <Typography>Current {apiKeyName}: </Typography>
          <code>{maskedApiKey}</code>
        </Stack>
      )}
      <Stack direction="row" mt={2}>
        {children}
      </Stack>
    </Stack>
  </Section>
);
