// info
export const lightInfoBorderColor = '#03339F';
export const lightInfoBackgroundColor = '#E5EAF5';

// success
export const lightSuccessFontColor = '#E5F1EC';

// error
export const lightAttentionFontColor = '#322302';
export const lightAttentionBackgroundColor = '#FEFAF0';

// error
export const lightErrorBackgroundColor = '#FEF9F9';
