import React from 'react';
import { FormGroup, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import { TestID } from 'testID';
import { OnChangeInputFn, UserFormInputsValidation, UserFormInputsValues } from 'types';
import { Section } from '../Section/Section.view';

export const UserInfoForm: React.FC<{
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onInputChange: OnChangeInputFn;
  userFormInputValidation: UserFormInputsValidation;
  UserFormInputsValues: UserFormInputsValues;
}> = ({ onBlur, onInputChange, userFormInputValidation, UserFormInputsValues }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Section showBorder header="Company information" data-testid={TestID.UserInfoForm}>
      <FormGroup>
        <Stack
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <TextField
            onBlur={onBlur}
            margin="normal"
            id="firstName"
            label="First Name"
            type="text"
            variant="outlined"
            value={UserFormInputsValues.firstName}
            error={userFormInputValidation['firstName']}
            onChange={onInputChange}
            data-testid={TestID.UserInfoFormFirstNameField}
            disabled={false}
            sx={{ width: '100%' }}
            required
          />
          <Stack width="1.2rem" />
          <TextField
            onBlur={onBlur}
            margin="normal"
            id="lastName"
            label="Last Name"
            type="text"
            variant="outlined"
            value={UserFormInputsValues.lastName}
            error={userFormInputValidation['lastName']}
            onChange={onInputChange}
            data-testid={TestID.UserInfoFormLastNameField}
            disabled={false}
            sx={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack>
          <TextField
            onBlur={onBlur}
            margin="normal"
            id="companyName"
            label="Company Name"
            type="text"
            variant="outlined"
            value={UserFormInputsValues.companyName}
            error={userFormInputValidation['companyName']}
            onChange={onInputChange}
            data-testid={TestID.UserInfoFormCompanyNameField}
            disabled={false}
            required
          />
          <TextField
            onBlur={onBlur}
            margin="normal"
            id="companyEmail"
            label="Company Email"
            type="email"
            variant="outlined"
            value={UserFormInputsValues.companyEmail}
            error={userFormInputValidation['companyEmail']}
            onChange={onInputChange}
            data-testid={TestID.UserInfoFormCompanyEmailField}
            disabled={false}
            required
          />
          <TextField
            onBlur={onBlur}
            margin="normal"
            id="companyPhone"
            label="Company Phone"
            type="text"
            variant="outlined"
            value={UserFormInputsValues.companyPhone}
            onChange={onInputChange}
            data-testid={TestID.UserInfoFormCompanyPhoneField}
            disabled={false}
          />
        </Stack>
      </FormGroup>
    </Section>
  );
};
