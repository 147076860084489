import { HTTPStatus } from '../enums';
import { reloadPage } from 'utils';
import type {
  APIKeyLoginArgs,
  LoginLoginApiKeyRequestBody,
  OAuth2LoginArgs,
  PrepareLoginOAuth2RequestBody,
} from 'types';
import { AdditionalProperties, Datatypes } from 'types/connector';

const getCommonHeaders = (token?: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
});

const getApiUrl = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:5010';
  }

  return '/onboarding/api';
};

/**
 * Get the connectors in the context of a user. Authentication required.
 * @param {string} token
 * @returns Promise which returns an object of connectors with user specific information
 */
export const getConnectors = (token: string) =>
  fetch(`${getApiUrl()}/connectors`, {
    method: 'GET',
    credentials: 'include',
    headers: getCommonHeaders(token),
  }).then((response) => response.json());

/**
 * Get all the vendors we have. Public.
 * @returns Promise which returns an object with the various vendors we have
 */
export const getVendors = () =>
  fetch(`${getApiUrl()}/vendors`, {
    method: 'GET',
    credentials: 'include',
    headers: getCommonHeaders(),
  }).then((response) => response.json());

/**
 * Get the model mappings. Public.
 * @returns Promise which returns an object with the model mappings
 */
export const getModelMapping = () =>
  fetch(`${getApiUrl()}/model_mapping`, {
    method: 'GET',
    credentials: 'include',
    headers: getCommonHeaders(),
  }).then((response) => response.json());

export const getTenantInfo = (token: string) =>
  fetch(`${getApiUrl()}/tenant`, {
    method: 'GET',
    credentials: 'include',
    headers: getCommonHeaders(token),
  }).then((response) => response.json());

export const getNotifications = (token: string) =>
  fetch(`${getApiUrl()}/notifications`, {
    method: 'GET',
    credentials: 'include',
    headers: getCommonHeaders(token),
  }).then((response) => response.json());

export type UpdateNotificationsArgs = {
  _id: string;
  read: boolean;
};

export const updateNotificationAsRead = (token: string, args: UpdateNotificationsArgs) =>
  fetch(`${getApiUrl()}/notifications`, {
    method: 'PUT',
    credentials: 'include',
    headers: getCommonHeaders(token),
    body: JSON.stringify({ notifications: [args] }),
  });

export const getAvailableAccounts = (token: string, connectorId: string, apiKey?: string) =>
  fetch(`${getApiUrl()}/available_accounts_${connectorId}`, {
    method: 'POST',
    credentials: 'include',
    headers: getCommonHeaders(token),
    body: JSON.stringify({ api_key: apiKey }),
  });

export const logout = () =>
  fetch(`${getApiUrl()}/logout`, {
    method: 'GET',
    credentials: 'include',
    headers: getCommonHeaders(),
  });

export const disconnect = (connectorId: string, token: string) =>
  fetch(`${getApiUrl()}/disconnect`, {
    method: 'POST',
    credentials: 'include',
    headers: getCommonHeaders(token),
    body: JSON.stringify({ connector: connectorId }),
  });

export const configure = ({
  connectorId,
  additionalProperties,
  datatypes,
  token,
}: {
  connectorId: string;
  additionalProperties?: AdditionalProperties;
  datatypes?: Datatypes;
  token: string;
}) =>
  fetch(`${getApiUrl()}/connectors/${encodeURIComponent(connectorId)}`, {
    method: 'PUT',
    credentials: 'include',
    headers: getCommonHeaders(token),
    body: JSON.stringify({ connector: connectorId, additionalProperties, datatypes }),
  });

export const oauth2Login = async (args: OAuth2LoginArgs) => {
  const { connectorId, token, redirectPath, accountId, additionalValues, contactInfo } = args;

  const connectorLoginRequestBody: PrepareLoginOAuth2RequestBody = {
    redirect_path: redirectPath,
    additionalProperties: additionalValues,
    reconnect: accountId ? { accountId, override: false } : undefined,
    contact_info: contactInfo,
  };

  const requestOptions = {
    method: 'POST',
    headers: getCommonHeaders(token),
    body: JSON.stringify(connectorLoginRequestBody),
  };

  await fetch(`${getApiUrl()}/prepare_login_${encodeURIComponent(connectorId)}`, {
    ...requestOptions,
    credentials: 'include',
  });

  reloadPage(`${getApiUrl()}/oauth2login_${encodeURIComponent(connectorId)}`);
};

export const connectorLogin = (args: APIKeyLoginArgs, token: string) => {
  const {
    connectorId,
    apiKey,
    additionalValues,
    type,
    accountId,
    nextConnector,
    contactInfo,
    override,
  } = args;

  const loginLoginApiKeyRequestBody: LoginLoginApiKeyRequestBody = {
    connectorId: connectorId,
    apiKey: apiKey,
    additionalProperties: additionalValues,
    type: type,
    reconnect: accountId ? { accountId, override: override ?? false } : undefined,
    next_connector: nextConnector,
    contact_info: contactInfo,
  };

  const requestOptions = {
    method: 'POST',
    headers: getCommonHeaders(token),
    body: JSON.stringify(loginLoginApiKeyRequestBody),
  };

  return fetch(`${getApiUrl()}/login_${encodeURIComponent(connectorId)}`, {
    ...requestOptions,
    credentials: 'include',
  });
};

export const getConnectorAccountInfo = (token: string, connectorId: string) =>
  fetch(`${getApiUrl()}/connector/${encodeURIComponent(connectorId)}/account/info`, {
    method: 'GET',
    credentials: 'include',
    headers: getCommonHeaders(token),
  }).then((response) => response.json());

export const postConnectorAccountInfo = (token: string, connectorId: string, apiKey: string) => {
  const requestOptions = {
    method: 'POST',
    headers: getCommonHeaders(token),
    body: JSON.stringify({ apiKey }),
  };

  return fetch(`${getApiUrl()}/connector/${encodeURIComponent(connectorId)}/account/info`, {
    ...requestOptions,
    credentials: 'include',
  });
};

export const deleteAccount = (token: string) => {
  const requestOptions = {
    method: 'POST',
    headers: getCommonHeaders(token),
    body: JSON.stringify({
      wipe: true,
    }),
  };
  return fetch(`${getApiUrl()}/delete`, { ...requestOptions, credentials: 'include' });
};

export const getZendeskToken = (token: string) =>
  fetch(`${getApiUrl()}/zendesk_token`, {
    method: 'GET',
    credentials: 'include',
    headers: getCommonHeaders(token),
  }).then((response) => {
    if (response.status !== HTTPStatus.NotImplemented) {
      return response.json();
    }
  });

export const finalizeLogin = async (token: string, connectorId: string, account_id: string) => {
  const requestOptions = {
    method: 'POST',
    headers: getCommonHeaders(token),
    body: JSON.stringify({
      account_id,
    }),
  };

  return fetch(`${getApiUrl()}/finalize_login_${encodeURIComponent(connectorId)}`, {
    ...requestOptions,
    credentials: 'include',
  });
};
