/**
 * Log provider. Wraps `window.console` by default.
 *
 * TODO: Implement logging user action and allow to dump log as text file.
 */
const initLoggerProvider = (consoleFn: Console = console) => ({
  // eslint-disable-next-line no-console
  info: consoleFn.info,
  warn: consoleFn.warn,
  error: consoleFn.error,
});

export const logger = initLoggerProvider();
