import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import { Button } from 'views';
import { TestID } from 'testID';

export const ReauthoriseButtonContainer: React.FC<{
  connectorId: string;
  asLinkButton?: boolean;
}> = ({ connectorId, asLinkButton = false, ...props }) => {
  const navigate = useNavigate();

  const reauthorise = () =>
    navigate(`/${connectorId}/connect`, { state: { isReauthoriseFlow: true } });

  if (asLinkButton) {
    return (
      <Link
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          reauthorise();
        }}
        data-testid={TestID.ReauthoriseNeedsAttentionLink}
      >
        reauthorise
      </Link>
    );
  }

  return (
    <Button
      variant="contained"
      onClick={() => {
        reauthorise();
      }}
      {...props}
    >
      Reauthorise
    </Button>
  );
};
