import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { getBuildReauthoriseStateFn, getFirstAccount, getIsConnected, logger } from 'utils';
import { getConnectors } from 'api';
import { BannerState } from 'types';
import { Connector, Connectors } from 'types/connector';

export const decorateConnectors = (
  buildReauthoriseState: (connector: Connector) => BannerState | undefined,
  connectors: Connectors
) => {
  if (!connectors) {
    return null;
  }
  Object.keys(connectors).forEach((connectorId) => {
    const connector = connectors[connectorId];
    const firstAccount = getFirstAccount(connector?.accounts);
    const isConnected = getIsConnected(firstAccount);
    connector.id = connectorId;
    connector.connected = isConnected;
    connector.reauthoriseState = isConnected ? buildReauthoriseState(connector) : undefined;
  });
  return connectors;
};

export const useFetchConnectors = () => {
  const navigate = useNavigate();
  const buildReauthoriseState = getBuildReauthoriseStateFn(navigate);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const queryClient = useQueryClient();

  const { isLoading, data, status, fetchStatus } = useQuery({
    queryKey: ['Connectors'],
    enabled: isAuthenticated,
    queryFn: () =>
      getAccessTokenSilently({
        authorizationParams: {
          scope: 'read:connectors',
        },
      })
        .then((token) => getConnectors(token))
        .then((connectors) => connectors)
        .catch(() => {
          logger.error('An error occurred when trying to fetch the list of connectors.');
          return null;
        }),
  });

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['Connectors'] });
  };

  const decoratedConnectors = decorateConnectors(buildReauthoriseState, data);

  return {
    connectors: decoratedConnectors,
    connectorsLoading: isLoading,
    invalidate,
    status,
    fetchStatus,
  };
};
