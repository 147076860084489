import { useFetchConnectors } from './useFetchConnectors';
import { useFetchTenant } from './useFetchTenant';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Connector } from 'types/connector';

export const useCurrentConnector = () => {
  const { connectorId } = useParams();
  const { tenant, isLoading } = useFetchTenant();
  const { connectors, connectorsLoading } = useFetchConnectors();
  const [connector, setConnector] = useState<Connector>();

  useEffect(() => {
    if (!connectorsLoading && connectors && !isLoading) {
      const id = tenant?.vendor_config?._id ?? connectorId ?? '';
      setConnector(connectors[id]);
    }
  }, [connectorId, connectors, connectorsLoading, isLoading, tenant?.vendor_config?._id]);

  return { connector, isLoading: isLoading || connectorsLoading };
};
