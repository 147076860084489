import React, { ComponentProps } from 'react';
import cx from 'classnames';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TestID } from 'testID';
import styles from './GlobalSavePanel.module.scss';

export const GlobalSavePanel: React.FC<{
  changeCount?: number;
  inProgress: boolean;
  onCancel: () => void;
  onSave: () => void;
  testId?: string;
}> = ({ changeCount = 0, inProgress, onCancel, onSave, testId = TestID.GlobalSavePanel }) => {
  const changesText = changeCount === 1 ? 'change' : changeCount > 1 ? 'changes' : '';

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="row"
      sx={{ padding: 4 }}
      className={styles.panel}
      width="100%"
      data-testid={testId}
    >
      <LoadingButton
        variant="contained"
        sx={{ ml: 4 }}
        onClick={onSave}
        loading={inProgress}
        data-testid={TestID.GlobalSavePanelSaveButton}
      >
        Save
      </LoadingButton>
      <Button
        sx={{ ml: 4 }}
        onClick={onCancel}
        disabled={inProgress}
        data-testid={TestID.GlobalSavePanelCancelButton}
      >
        Cancel
      </Button>
      {changeCount > 0 && (
        <Stack sx={{ ml: 4 }}>
          <Typography variant="body1" data-testid={TestID.GlobalSavePanelChangesCountLabel}>
            {changeCount} unsaved {changesText}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export const GlobalSavePanelPositioner: React.FC<
  { show?: boolean } & ComponentProps<typeof GlobalSavePanel>
> = ({ show, ...props }) => (
  <Stack className={cx(styles.positioner, show && styles.show)}>
    <GlobalSavePanel {...props} />
  </Stack>
);
