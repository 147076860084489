import React from 'react';
import { Location, NavigateFunction } from 'react-router-dom';
import { ConnectorView } from 'views';
import { Connectors } from 'types/connector';

export const ConnectorContainer: React.FC<{
  navigate: NavigateFunction;
  location: Location;
  connectorId: string;
  connectors: Connectors;
}> = ({ navigate, location, connectorId, connectors }) => {
  const connector = connectors?.[connectorId];
  const connectorLogo = connector?.logo;
  const connectorName = connector?.title;

  return (
    <ConnectorView
      pathname={location.pathname}
      navigate={navigate}
      connectorName={connectorName}
      logo={connectorLogo}
    />
  );
};
