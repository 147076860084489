import React from 'react';
import { NavigateFunction, Outlet } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Stack } from '@mui/system';
import { TestID } from 'testID';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs.view';
import {
  SidebarLayout,
  SidebarLayoutContent,
  SidebarLayoutMenu,
} from '../SidebarLayout/SidebarLayout.view';

export const ConnectorView: React.FC<{
  pathname: string;
  navigate: NavigateFunction;
  connectorName: string;
  logo?: string;
}> = ({ pathname, navigate, connectorName, logo }) => (
  <Stack>
    <SidebarLayout>
      <SidebarLayoutMenu>
        <List component="nav">
          <ListItemButton onClick={() => navigate('status')} selected={pathname.endsWith('status')}>
            <ListItemIcon>
              <SyncIcon />
            </ListItemIcon>
            <ListItemText
              primary="Synchronisation"
              data-testid={TestID.ConnectorSynchronisationLink}
            />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate('configure')}
            selected={pathname.endsWith('configure')}
            data-testid={TestID.ConnectorConfigurationLink}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Configuration" />
          </ListItemButton>
        </List>
      </SidebarLayoutMenu>
      <SidebarLayoutContent>
        <Breadcrumbs currentPage={connectorName} />
        <Box sx={{ width: '15rem', py: 2 }}>
          <img src={logo} alt={connectorName} width="100%" />
        </Box>
        <Outlet />
      </SidebarLayoutContent>
    </SidebarLayout>
  </Stack>
);
