import React from 'react';
import type { SvgIconComponent } from '@mui/icons-material';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { TestID } from 'testID';
import { lightTheme } from 'themes';

export const Banner: React.FC<{
  Icon?: SvgIconComponent;
  text: string;
  primaryActionText: string;
  onPrimaryAction: () => void;
  secondaryActionText?: string;
  onSecondaryAction?: () => void;
  testId?: string;
}> = ({
  Icon,
  onPrimaryAction,
  primaryActionText,
  secondaryActionText,
  onSecondaryAction,
  text,
  testId,
}) => {
  const isMobile = useMediaQuery(lightTheme.breakpoints.down('md'));
  const showSecondaryButton = secondaryActionText && onSecondaryAction;

  return (
    <Stack
      sx={{ zIndex: 1100, top: '89px', width: '720px' }}
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems={isMobile ? 'flex-start' : 'center'}
      p={1}
      data-testid={testId}
    >
      <Stack flexDirection="row">
        {Icon && (
          <Stack p={2}>
            <Icon />
          </Stack>
        )}
        <Stack>
          <Typography variant="body2">{text}</Typography>
        </Stack>
      </Stack>
      <Stack
        alignItems="flex-end"
        justifyContent="flex-end"
        flexDirection={showSecondaryButton ? 'row' : isMobile ? 'column' : 'row'}
        width={isMobile ? '100%' : 'auto'}
        height="100%"
      >
        {showSecondaryButton && (
          <Button onClick={onSecondaryAction} data-testid={TestID.BannerSecondaryActionButton}>
            {secondaryActionText}
          </Button>
        )}
        <Button onClick={onPrimaryAction} data-testid={TestID.BannerPrimaryActionButton}>
          {primaryActionText}
        </Button>
      </Stack>
    </Stack>
  );
};
