import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styles from './TermsOfServiceText.module.scss';

export const TermsOfServiceText: React.FC<{ maxHeight?: string }> = ({ maxHeight = '25vh' }) => (
  <Stack spacing={2} sx={{ maxHeight, overflowY: 'auto' }}>
    <Typography
      variant="h1"
      gutterBottom
      mb={-1}
      sx={{
        fontWeight: 400,
        fontSize: '2.125rem',
        lineHeight: '1.235',
      }}
    >
      Terms of service
    </Typography>

    <Typography gutterBottom variant="subtitle1">
      Terms of Service for Access and Use of SESAM Software as a Service (SaaS)
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      1. Introduction
    </Typography>

    <Typography variant="h6" gutterBottom>
      1.1. Purpose
    </Typography>

    <Typography gutterBottom>
      This document contains terms of service for Customers' access to and use of SESAM Software as
      a Service (“Services”) provided by SESAM.IO AS 922 409 676 (“SESAM”). The services consist of
      both free- and paid subscriptions and the term “Customer” is used regardless of whether a
      Subscription Fee applies to a particular Customer's account. Some sections of this document
      will only apply to Customers with an account with a defined Subscription Fee. Upon ordering
      access to the Services and confirming acceptance of the terms of service in this document a
      legally binding agreement (“Agreement”) is entered into between SESAM and the Customer,
      regardless of whether the Customer is ordering from SESAM directly or through a SESAM partner.
      All Services, as they are available at any time, through the portal https://portal.sesam.io or
      otherwise, are subject to the terms of this Agreement.
    </Typography>

    <Typography gutterBottom>
      Upon entering into this Agreement and the registration of the Customer in SESAM's systems, the
      Customer is given a right to use the Services on the terms in this Agreement. Once access to
      the Services has been granted, the Customer will receive an e-mail to the customers registered
      e-mail address with the necessary access and login details.
    </Typography>

    <Typography gutterBottom>
      The Services consist of various sub-services, and the right to use the Services only comprise
      the individual sub-services which the Customer has ordered and received access to. Some of the
      Services are payable. The Customer's account overview sets out active sub-services which the
      Customer has ordered, including which Services are payable.
    </Typography>

    <Typography gutterBottom>
      Some sub-services may be subject to additional terms and/or restrictions. Such additional
      terms and/or restrictions are set out in the terms of use for the sub-service together with
      the description of the sub-service in question.
    </Typography>

    <Typography variant="h6" gutterBottom>
      1.2. Description of the Services
    </Typography>

    <Typography gutterBottom>
      The Services are standardized services, and the Customer acknowledges that the functionality
      in the Services will change. The Customer is given the right to use the Services, with the
      functionality as provided from time to time. The right to use the Services is not connected to
      any specific version of the underlying software or any set functionality as provided at any
      time but is rather connected to access to and use of the Services as they are at any given
      time. Details of the functionality of each of the Services can be found at
      https://docs.sesam.io.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      2. The Right to use the Services
    </Typography>

    <Typography variant="h6" gutterBottom>
      2.1. General
    </Typography>

    <Typography gutterBottom>
      The Customer may set up Users and give these Users access to the Services with different roles
      (each individual referred to herein as a “User” and collectively as the “Users”). Users are
      individuals employed or engaged by the Customer.
    </Typography>

    <Typography gutterBottom>
      The Customer and the Customer's Users receive a worldwide, limited, terminable, non-exclusive
      and non-transferable right to use the Services in accordance with the terms of this Agreement,
      exclusively for the Customer's internal business purposes. This right is conditional upon
      payment of the applicable subscription fee and other fees (“Subscription Fee”). Any applicable
      Subscription Fee is provided on the Customer's account overview.
    </Typography>

    <Typography gutterBottom>
      The Payment of the Subscription Fee and the compliance with the terms of this Agreement is a
      precondition for the right to use the Services.
    </Typography>

    <Typography gutterBottom>
      The Customer may not allow a third party to use the Services or use the Service in a service
      bureau or similar, or offer services dependent on the Services to a third party. However, the
      Customer may allow in-hire consultants or temporary employees to use the Services for
      Customers internal business purposes. The Customer may also use the Services to
      collect/deliver data from the applications and databases of the Customer's suppliers and/or
      customers or use other integrations available in the Services.
    </Typography>

    <Typography gutterBottom>
      The Services depend on standard software. When purchasing access to the Services, the Customer
      does not purchase a copy of, or license to, the software used to deliver the Services, but
      consecutively pays for a subscription to access the Services. To the extent that separate
      license- or other terms apply for use of third-party software and/or services included in the
      Services, these are set out together with the description of the Services in question. Any
      terms for third party software and/or services take precedence over the terms of this
      Agreement.
    </Typography>

    <Typography variant="h6" gutterBottom>
      2.2. The relationship between the Customer's Users and SESAM
    </Typography>

    <Typography gutterBottom>
      The Customer commits to adhere to the terms of this Agreement, and to ensure that any of its
      Users do the same. The Customer accepts full responsibility for all activities connected to
      its Users, and its user's compliance with the terms of the Agreement. When the individual User
      accepts any terms upon logging into a Service, or on a later change of such terms, no
      Agreement is made between such individual User and SESAM. It is at all times the Customer who
      is party to any agreement with SESAM, and who has all rights and obligations set out in such
      agreement. If the Customer has ordered Services through a SESAM partner, the SESAM partner may
      act on the behalf of the Customer and thus perform Customer's actions and receive notices to
      the Customer as described in the Agreement, and SESAM partner's acts and omissions will be
      considered the Customer's.
    </Typography>

    <Typography gutterBottom>
      The Customer accepts that SESAM may contact the Customer, and any Customer administrators,
      through registered e-mail addresses and by telephone, and provide general marketing
      information or other information about the Services. Such information will not be sent to the
      Customer's other Users.
    </Typography>

    <Typography variant="h6" gutterBottom>
      2.3. User administration
    </Typography>

    <Typography gutterBottom>
      If the Customer is a physical person (i.e., an individual), the Customer will be assigned a
      single User.
    </Typography>

    <Typography gutterBottom>
      If the Customer is a legal person (e.g., a company, organisation or a governmental entity),
      Users are created and administrated by the Customer. Customer's administrators for this
      Agreement should be communicated to SESAM.
    </Typography>

    <Typography gutterBottom>
      The logon information of an individual User shall not be shared or used by more than one
      physical person but may freely be reassigned by the Customer's administrator. The Customer's
      administrator may also name further administrators and delete Users. The Customer shall ensure
      that all information about Users and administrators is always up to date.
    </Typography>

    <Typography gutterBottom>
      Each User is responsible for keeping his/her username and password confidential. The Customer
      shall inform SESAM immediately in case of unauthorized use of a User's logon information.
      Users are authorized to provide SESAM's support personnel with access to their accounts where
      such access is necessary to provide support or else asked for by the User.
    </Typography>

    <Typography variant="h6" gutterBottom>
      2.4. Requirements for use of the Services
    </Typography>

    <Typography gutterBottom>
      The Customer and any of its Users shall not transfer viruses, malware or any other harmful
      code to the Services or use the Services in a manner which puts a risk for such transfer. The
      Services shall not be used for any illegal or otherwise unauthorized purpose. When the
      Services are used outside of Norway, in cases where the Customer's Users temporarily are
      staying abroad, the Customer is responsible for complying with the Norwegian, EU and United
      States of America export restrictions and regulations, including assuring that the Services
      are not used in jurisdictions where such use would be contrary to such export restrictions and
      regulations.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      3. Technical information
    </Typography>

    <Typography gutterBottom>
      The Services will be delivered through the operating environment selected by SESAM at any
      time, unless it is agreed with the Customer that the Customer will use another operating
      environment. SESAM reserves the right to change the operating environment but will in such
      event notify the Customer of the change and of technical and legal effects of such change. The
      Customer will be given a minimum of three months' notice in case of significant changes to the
      operating environment.
    </Typography>

    <Typography gutterBottom>
      At the present time the operating environment selected by SESAM is Microsoft Azure. SESAM is a
      reseller (CSP) of Microsoft and is entitled to resell Microsoft Online Services as part of its
      Services. SESAM's role as reseller means that the Customer gets certain rights and obligations
      directly towards Microsoft. The following additional terms and conditions therefore apply for
      Microsoft Azure, as updated by Microsoft from time to time:
      https://www.microsoft.com/licensing/docs/customeragreement and
      https://www.microsoft.com/licensing/docs/view/Licensing-Use-Rights.
    </Typography>

    <Typography gutterBottom>
      A separate, direct agreement between Microsoft and the Customer based on the Microsoft Azure
      terms above is concluded when the Customer accepts this Agreement. Such a direct agreement
      means i.a. that the Customer obtains a Data Processor Agreement with Microsoft and that
      Microsoft is fully and solely responsible for errors and downtime in Azure services (Online
      Services) from Microsoft.
    </Typography>

    <Typography gutterBottom>
      If Microsoft requires documentation of, or other actions to confirm, that Customer has
      accepted Microsoft's terms and conditions or any updates thereto, the Customer shall provide
      SESAM with requested documentation thereof or take necessary actions to confirm this.
    </Typography>

    <Typography gutterBottom>
      The Customer agrees and accept that SESAM may share information about users, to the extent
      needed, in support cases towards Microsoft, such as telephone number, email and URL.
    </Typography>

    <Typography gutterBottom>
      SESAM and the Customer may agree on special requirements for the operating environment in a
      separate agreement.
    </Typography>

    <Typography gutterBottom>
      To the extent technical requirements apply to the Customer's IT systems for the use of the
      various Services, e.g., requirements for bandwidth and/or hardware, this shall be set out
      together with the description of the Services in question.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      4. SESAM's Responsibility for Quality of Service, Availability and Support
    </Typography>

    <Typography variant="h6" gutterBottom>
      4.1. Quality of Service
    </Typography>

    <Typography gutterBottom>
      SESAM warrants that the Services will perform substantially as described in applicable
      Documentation of Services. The Services will be subject to continual improvement.
    </Typography>

    <Typography gutterBottom>
      If the Services do not function as described in applicable Documentation of Services, SESAM
      will correct verified errors in the Services at SESAMS's own expense. SESAM may choose to
      replace the Services or functionality therein instead of performing a correction. If SESAM
      does not solve the verified errors according to the time-limits set out together with the
      description of the Services in question or does not replace the Service within a reasonable
      time, the Customer may cancel their subscription to that particular service in accordance with
      the terms in section 11. The Customer may not set forth any other claims due to defects or
      errors in the Services.
    </Typography>

    <Typography gutterBottom>
      Links to websites not owned or controlled by SESAM that are available in the Services, related
      web pages or the documentation are provided without any responsibility or liability for SESAM.
      SESAM is not responsible for the content and/or information on such websites. In the same
      manner, SESAM is not responsible for defects and errors arising from the Customer's own
      applications, databases or other systems.
    </Typography>

    <Typography variant="h6" gutterBottom>
      4.2. Service Level Agreements (SLA) targets, penalties
    </Typography>

    <Typography gutterBottom>
      The provisions in this section apply only to the Customers who pay a Subscription Fee.
    </Typography>

    <Typography gutterBottom>
      SESAM's Service availability targets, SLA penalties, and fixed maintenance periods are set out
      together with the description of the Services in question.
    </Typography>

    <Typography gutterBottom>
      SESAM may, from time to time, require additional maintenance periods beyond what is set out
      above, for example for major upgrades. Such maintenance periods will be notified to the
      Customer at least 48 hours in advance, and any unavailability does not count towards the
      calculation of Service availability targets.
    </Typography>

    <Typography variant="h6" gutterBottom>
      4.3. Notification of errors
    </Typography>

    <Typography gutterBottom>
      If the Customer experiences that the Services in whole or in part are unavailable, or have
      reduced performance, the Customer shall report the error via https://support.sesam.io. In
      order to ensure that SESAM gets necessary information to identify and correct the error, the
      Customer shall, together with the notification of error, provide accurate information about
      the error, including a description of how the error occurred, how many Users are affected, and
      which dataflows and/or systems are affected, or assist SESAM with gathering such information.
    </Typography>

    <Typography variant="h6" gutterBottom>
      4.4. Support
    </Typography>

    <Typography gutterBottom>
      The provisions in this section apply only to the Customers who pay a Subscription Fee.
    </Typography>

    <Typography gutterBottom>
      SESAM will provide basic technical support to the Customer. This entails technical assistance,
      but SESAM does not warrant that any solution will be found for any problems or requests. The
      administration and configuration of the Services for the Customer is not included in the
      support and may be provided according to separate agreements. This may include assistance
      connected to integrated applications/databases.
    </Typography>

    <Typography gutterBottom>
      The Customer may contact SESAM support on business days (Monday to Friday except Christmas
      Eve, New Year's Eve and other Norwegian public holidays) between 08:00 and 16:00 hours Central
      European Time, or on specified times determined by SESAM. Any contact with SESAM support shall
      take place via https://support.sesam.io.
    </Typography>

    <Typography gutterBottom>
      Support is provided in accordance with the following guidelines:
    </Typography>

    <Typography gutterBottom pl={4} component="span">
      <ul className={styles.generalList}>
        <li>
          A Customer with more than three Users shall designate a contact person for support, which
          will act as the Customer's point of contact with SESAM. Accordingly, the contact person
          shall provide first line support, and forward detailed information to SESAM's personnel.
        </li>
        <li>
          Support requests shall regard the Services, when used in a manner recommended by SESAM.
          Support shall not cover repair of information, data-correction in a database or problems
          caused by the Customer. Neither shall the support cover any matter outside of the Services
          as covered by the Customer's subscription.
        </li>
        <li>
          SESAM will recommend training or consulting services if the support request takes the form
          of being general training or education.
        </li>

        <li>Support does not include any form of consulting services.</li>
      </ul>
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      5. Security
    </Typography>

    <Typography variant="h6" gutterBottom>
      5.1. Rights to data
    </Typography>

    <Typography gutterBottom>
      The Customer retains all rights to all data that is entrusted to SESAM for processing and
      which is stored or processed with help of the Services under this Agreement.
    </Typography>

    <Typography gutterBottom>
      Upon termination of all Services or individual Services, SESAM may assist in transferring the
      data to a designated and usable format. Such a service should be ordered at least 30 days
      before the end of the subscription in question in order to ensure that the Customer can
      receive the data before deletion. Such assistance is invoiced by SESAM according to the at any
      time applicable fee schedule and terms for consultancy services. On termination of the
      subscription of the Service subscription, or subscription for an individual service, all
      Customer data will be deleted by SESAM.
    </Typography>

    <Typography variant="h6" gutterBottom>
      5.2. Security
    </Typography>

    <Typography gutterBottom>
      Customer's privacy is important to SESAM and SESAM will abide by SESAM's privacy policy. SESAM
      reserves the right to modify their privacy policy from time to time.
    </Typography>

    <Typography gutterBottom>
      SESAM provides secure and reliable services, and will at any time have in place
      administrative, physical and technical security measures including backup solutions according
      to corresponding standards:
    </Typography>

    <Typography gutterBottom pl={4} component="span">
      <ul className={styles.generalList}>
        <li>
          SESAM has established an information security governance system where systems, routines
          and processes which was set up in accordance with ISO 27001 and 27018.
        </li>

        <li>A yearly third party audit shall be carried out in accordance with ISO 27001.</li>
        <li>
          A confidential summary report of the audit shall be produced and made available to the
          Customer upon request.
        </li>

        <li>
          The summary report shall enable the Customer to assess whether the security level in
          SESAM's services are according to the Agreement and the Customer's requirements.
        </li>
      </ul>
    </Typography>

    <Typography variant="h6" gutterBottom>
      5.3 Processing of personal data
    </Typography>

    <Typography gutterBottom>
      The Services may entail processing of the Customer's personal data, e.g. storage in SESAM's
      operating environments, cf. section 3 above, unless otherwise is agreed with the Customer.
    </Typography>

    <Typography gutterBottom>
      If the Services entail processing of the Customer's personal data, the Data Processing
      Addendum comes into force. The Data Processing Addendum forms part of the Terms Of Service.
      The Data Processing Addendum are hereby incorporated by reference and shall apply to the
      extent Customer Data includes Personal Data, as defined in the DPA. The DPA further states the
      Parties' obligations and rights as Controller and Processor, regarding the Processor's
      processing of Personal Data on behalf of the Controller. SESAM as the Processor, shall only
      process data in accordance with the Data Processing Addendum.
    </Typography>

    <Typography gutterBottom>
      In the circumstance that the Services entail processing of the Customer's personal data the
      following will enter into force in addition to the Data Processing Addendum:
    </Typography>

    <Typography gutterBottom>
      The Customer is the Controller in accordance with EU's General Data Protection Regulation
      (“GDPR”) article 4 paragraph 7. SESAM is the Processor in accordance with GDPR article 4
      paragraph 8.
    </Typography>

    <Typography gutterBottom>
      The Customer as the Controller agrees and warrants that: - The Customer owns or otherwise has
      the right to transfer the personal data to the Service for processing, and that the Customer
      is responsible for the accuracy, integrity, contents, and legality of the personal data,
      including transfer and instructions; - Where applicable, that the processing of personal data
      is covered by an applicable permit, and/or has been notified to the applicable regulatory
      authorities and/or Data Subjects, and that the processing of personal data is not in violation
      of applicable law, hereunder GDPR. - It is the Customer's obligation as the Controller to
      notify the applicable regulatory authorities and/or Data Subjects in case of breach or
      unauthorized processing of personal data, incl. special categories. - The Customer, by way of
      its risk assessment, has verified that SESAM's security measures are effective and appropriate
      for the processing in question; - SESAM has provided sufficient guarantees in terms of
      logical, technical, physical and organizational security measures. - SESAM generally
      recommends that the Customer uses standard-level SLA or higher when processing personal data.
      - If it is set forth in the agreement that the processing of personal data includes processing
      of special categories of personal data or data processing that entails high risk, SESAM
      requires that the Customer uses standard-level SLA or higher.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      6. Fees and Payment Terms
    </Typography>

    <Typography gutterBottom>
      For Services included in this Agreement, the Customer may have paid a Subscription Fee as set
      out together with the description of the Services in question.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      7. Right to Audit and Control
    </Typography>

    <Typography gutterBottom>
      The provisions in this section apply only to the Customers who pay a Subscription Fee.
    </Typography>

    <Typography gutterBottom>
      SESAM shall, to the extent required by applicable audit standards or applicable governmental
      requirements/legislation, allow the Customer's internal or external auditors to observe
      SESAM's delivery of the Services with related Customer data and any documentation for the
      Services for the Customer. The Customer shall give reasonable notice before such audits, at
      least 20 calendar days, and the audit shall be carried out during normal business hours. The
      notice shall include information as to which external auditor is chosen by the Customer. The
      Customer acknowledges that scope of audit shall be limited to SESAM's own delivery of Service,
      as well as applicable documentation.
    </Typography>

    <Typography gutterBottom>
      The Customers may not utilize auditors who are in direct competition with SESAM. SESAM shall
      have the right to approve or deny the Customer's choice of auditor, though approval shall not
      be unreasonably withheld. The auditor(s) shall sign a confidentiality statement. The Customer
      shall adhere to SESAM's applicable regulations when access is given to SESAM's facilities.
    </Typography>

    <Typography gutterBottom>
      Any costs which SESAM may have in relation to the audit, control and any possible further
      quality assurance that the Customer may require, will be invoiced to the Customer in
      accordance with SESAM's applicable rates.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      8. Changes to the Agreement
    </Typography>

    <Typography gutterBottom>
      SESAM reserves the right to change the terms of this Agreement upon at least 30 days' notice.
    </Typography>

    <Typography gutterBottom>
      Reference is made to SESAM's limited opportunity to change the terms in sections 5.2 and 5.
      regarding the processing of data. SESAM may not change section 5.1. to the detriment of the
      Customer.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      9. Infringement of Third Party Rights
    </Typography>

    <Typography gutterBottom>
      The provisions in this section apply only to the Customers who pay a Subscription Fee.
    </Typography>

    <Typography gutterBottom>
      SESAM shall defend the Customer against claims or lawsuits set forth by third parties claiming
      that the Customer's use of the Services infringes that third party's Norwegian or EU copyright
      or database rights in Norway or EU. In the event of such claims the Customer shall immediately
      inform SESAM in writing.
    </Typography>

    <Typography gutterBottom>
      SESAM shall, to the extent SESAM is responsible for the infringement, hold the Customer
      harmless against all costs, damages, expenses or losses that the Customer is ordered to pay by
      a court or agrees to pay in a settlement, including attorney fees. This is subject to the full
      co- operation of the Customer with SESAM and that SESAM is in full control of the legal
      process and negotiations for a settlement. SESAM may at its own discretion (i) modify the
      Services so that there is no longer any infringement of third party rights, (ii) replace the
      Services with functionally equivalent services, (iii) provide a right for the Customer's
      continued use of the Services. If these options are not commercially reasonable available,
      SESAM may terminate the Customer's access to the Products and Services with a refund of any
      fees paid for the subscription after the date of termination. The Customer may not set forth
      any other claims as a result of infringement of third party rights.
    </Typography>

    <Typography gutterBottom>
      The previous right to be held harmless does not apply if the Services have been used in
      violation of these terms and conditions or if the claim arises out of any modification,
      integration or customization of the Services not performed by SESAM.
    </Typography>

    <Typography gutterBottom>
      The Customer shall defend SESAM against any claims or lawsuits in which a third-party claim
      that the Customer's data or use of the Services in combination with the Customer's own
      applications, databases or other systems, is inconsistent with or infringes a third party's
      intellectual property rights, including without limitations, patents, copyright, trade
      secrets, trademark or any other intellectual property rights. SESAM shall immediately notify
      the Customer in writing in the event of such claims.
    </Typography>

    <Typography gutterBottom>
      The Customer shall hold SESAM harmless against all costs, damages, expenses or losses that
      SESAM is sentenced to pay by a court or agrees to in a settlement, including attorney fees,
      provided that SESAM cooperates with the Customer at the Customers own expense and that SESAM
      provides the Customer with full control over the legal process and settlement, and that the
      settlement releases SESAM from all liability.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      10. Liability, Limitation of Liability etc.
    </Typography>

    <Typography variant="h6" gutterBottom>
      10.1. Limitation of liability
    </Typography>

    <Typography gutterBottom>
      If SESAM is held responsible for paying damages to the Customer as a consequence of breaches
      of any of the obligations under this Agreement, such damages will under no condition include
      compensation for indirect loss or damages of any kind that may arise as a result of, or in
      connection with, such breach. Indirect loss includes, but is not limited to, loss of profit of
      any kind, losses as a consequence of disrupted operations, loss due to loss of data, lost
      savings, losses due to deprivation and claims from third parties (except as set out in section
      9 above). SESAM's liability under this Agreement is therefore limited to direct loss, unless
      otherwise set out in mandatory applicable law, for example damages due to gross negligence or
      intent. Any refunds or compensation for direct loss and costs during any 12-month period shall
      not exceed an amount equivalent to 6 month's Subscription Fee's ex. VAT for the Services
      during the same period.
    </Typography>

    <Typography gutterBottom>
      If standardized sanctions are agreed, these standardized sanctions shall be the sole remedy
      and no other claims may be made based on the same situation.
    </Typography>

    <Typography variant="h6" gutterBottom>
      10.2. Force majeure
    </Typography>

    <Typography gutterBottom>
      If the use and execution of the Services is wholly or partly prevented or materially impeded
      by circumstances beyond the parties' control, both parties' obligations are suspended for as
      long as the circumstances are relevant and as long as these circumstances lasts. Such
      circumstances include, but are not limited to, strikes, lockouts, and any relationship which
      under Norwegian law will be regarded as force majeure. Each party may, however, in accordance
      with section 11 of this Agreement, terminate the Agreement if the force majeure makes it
      particularly burdensome for that party to continue the Agreement.
    </Typography>

    <Typography gutterBottom>
      In the event that law, rules or regulations applicable to the use or delivery of the Services
      is changed or new rules or regulations are adopted after the Services have been made available
      on the market and this prevents SESAM from fulfilling the Customer's instructions pursuant to
      the Data Processing Addendum or other obligations in this Agreement and/or this requires full
      or partial termination of access to the Services for a limited or indefinite period of time,
      this shall be considered as a force majeure circumstance. SESAM is not in any way responsible
      for any such or other force majeure circumstance.
    </Typography>

    <Typography variant="h6" gutterBottom>
      10.3. Circumstances for which SESAM is in any event is not responsible
    </Typography>

    <Typography gutterBottom>
      Even though SESAM will use appropriate care to ensure secure transmission of information
      between the Customer and the Services, the Customer recognizes that the Internet is an open
      system, and that SESAM cannot warrant that a third party cannot or will not intercept or alter
      data during the transmission. SESAM takes no responsibility for such unauthorized access to,
      use or publication or loss of data.
    </Typography>

    <Typography gutterBottom>
      Neither is SESAM responsible for lack of availability of the Services when this is directly or
      indirectly caused by the Customer or by circumstances for which the Customer is responsible or
      the reconstruction of data regardless of cause.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      11. Cancellation and Suspension
    </Typography>

    <Typography gutterBottom>
      The Customer may cancel the Services or individual sub-services and thereby cancel the entire
      subscription for SESAM's Service using the Customer's account tool with applicable notice
      period. The cancellation takes effect from the start of the first month after the end of the
      notice period.
    </Typography>

    <Typography gutterBottom>
      The Customer may also cancel the Services if the Customer does not accept SESAM's announced
      changes to the Agreement. In such case the cancellation takes effect from the date announced
      changes become effective.
    </Typography>

    <Typography gutterBottom>
      For non-paying Customers SESAM has the right to suspend or terminate access to all or any part
      of the Service at any time, with or without cause, with 14 days' prior notice. In case of
      abuse, access to Services may be suspended or terminated without notice, effective
      immediately.
    </Typography>

    <Typography gutterBottom>
      The provisions for the reminding of this section apply only to the Customers who pay a
      Subscription Fee.
    </Typography>

    <Typography gutterBottom>
      If a minimum term applies for some of the Services, the termination by the Customer takes
      effect after the expiration of such minimum term.
    </Typography>

    <Typography gutterBottom>
      If payment is not made within the due date, SESAM may suspend the Customer's access to the
      Services until payment is made. Suspension shall be notified in writing by SESAM, with a final
      and reasonable deadline of 7 days for the Customer to settle the amount outstanding before
      suspension is made effective. SESAM may terminate the Customer's accounts for the Services if
      payment is not made to SESAM within 14 days after such suspension is made effective. The
      Customer shall pay delayed interest in accordance with applicable law for all Subscription
      Fees that are not settled before their due date. SESAM may make renewal of the Customer's
      subscription conditional on a shorter payment due date or increased invoice frequency after
      one case of delayed payment.
    </Typography>

    <Typography gutterBottom>
      SESAM may also terminate the Customer's subscription with 7 days' written notice if the
      Customer is in breach of any of his obligations under this Agreement, or if it becomes
      apparent that the Customer will materially breach this Agreement in the future. SESAM may with
      7 days' written notice to the Customer also suspend the Customer's subscription to the
      Services if the Agreement is breached by the Customer. Such suspension may be in effect until
      the matter has been resolved.
    </Typography>

    <Typography gutterBottom>
      SESAM reserves the right to terminate any service in its entirety, or its availability in any
      market, with 6 months' notice before such termination takes effect or in case of force majeure
      with such notice which is reasonable under the circumstances.
    </Typography>

    <Typography gutterBottom>
      When the Services, hereunder Users, are terminated, all data and copies of such data will be
      deleted from SESAMs servers upon the termination taking effect. The Customer will get access
      to the data as set out in section 5.1.
    </Typography>

    <Typography gutterBottom>
      The limitation period for any claims arising in connection with this Agreement or breach of
      this Agreement is one year after the termination of the agreement. Claims forwarded after the
      limitation period is out of date and hence have no validity.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      12. Confidentiality
    </Typography>

    <Typography gutterBottom>
      Information that comes into the possession of the parties in connection with implementation of
      the Agreement shall be kept confidential and shall not be disclosed to any third party without
      the consent of the other party.
    </Typography>

    <Typography gutterBottom>
      If the Customer is a public body, the scope of the confidentiality obligation under this
      provision shall not go beyond that laid down by the Act of 10 February 1967 relating to
      Procedure in Cases concerning the Public Administration (Public Administration Act) or
      corresponding sector-specific regulations.
    </Typography>

    <Typography gutterBottom>
      The confidentiality obligation pursuant to this provision shall not prevent the disclosure of
      information if such disclosure is demanded pursuant to laws or regulations, including any
      disclosure or right of access pursuant to the Act of 19 May 2006 relating to the Right of
      Access to Documents in the Public Administration (Freedom of Information Act). The other party
      shall, if possible, be notified prior to the disclosure of such information.
    </Typography>

    <Typography gutterBottom>
      The confidentiality obligation shall not prevent the information from being used when there is
      no legitimate interest in keeping it confidential, for example when it is in the public domain
      or is accessible to the public elsewhere.
    </Typography>

    <Typography gutterBottom>
      The parties shall take all necessary precautions to prevent unauthorized persons from gaining
      access to, or knowledge of, confidential information.
    </Typography>

    <Typography gutterBottom>
      The confidentiality obligation shall apply to the parties' employees, subcontractors and other
      third parties who act on behalf of the parties in connection with the implementation of the
      Agreement. The parties may only transmit confidential information to such subcontractors and
      third parties to the extent necessary for the implementation of the Agreement, and provided
      that they are subjected to a confidentiality obligation corresponding to that stipulated in
      this clause.
    </Typography>

    <Typography gutterBottom>
      The confidentiality obligation shall not prevent the parties from utilizing experience and
      expertise developed in connection with the implementation of the Agreement.
    </Typography>

    <Typography gutterBottom>
      The confidentiality obligation shall continue to apply after the expiry of the Agreement.
      Employees or others who resign from their positions with one of the parties shall be subjected
      to a confidentiality obligation following their resignation as well, as far as factors
      mentioned above are concerned. The confidentiality obligation shall lapse five (5) years after
      the Agreement comes to an end, unless otherwise is stipulated by law or regulations.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      13. Transfer
    </Typography>

    <Typography gutterBottom>
      Without obtaining SESAM's prior written permission, the Customer is not entitled to transfer
      all or part of the right to use the Services to another entity (either through mergers,
      de-mergers, bankruptcy, change of ownership or control or to affiliates or otherwise). SESAM
      may fully or partially transfer its rights and obligations under the Agreement to subsidiaries
      or other companies within the same group, hereunder use these as sub-contractors, provided
      that this is done in a manner assuring compliance with the obligations under the GDPR from the
      Customer's perspective.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      14. Choice of Law
    </Typography>

    <Typography gutterBottom>
      This Agreement will be construed, regulated and interpreted in accordance with and governed by
      Norwegian laws, without giving effect to its conflicts of law principles. Further, Customer
      and SESAM agree to submit to the jurisdiction of Oslo, Norway for any legal disputes regarding
      this Agreement or its subject matter herein.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      15. Miscellaneous
    </Typography>

    <Typography variant="h6" gutterBottom>
      15.1. Severability
    </Typography>

    <Typography gutterBottom>
      If any part of this Agreement is held to be unenforceable, the rest of the Agreement will
      remain in full force and effect.
    </Typography>

    <Typography variant="h6" gutterBottom>
      15.2. Waiver
    </Typography>

    <Typography gutterBottom>
      Failure to enforce any provision of this Agreement will not constitute a waiver. Any waiver
      must be in writing and signed by the waiving party.
    </Typography>

    <Typography variant="h6" gutterBottom>
      15.3. Survival
    </Typography>

    <Typography gutterBottom>
      All provisions survive termination of this Agreement except those requiring performance only
      during the term of the Agreement.
    </Typography>

    <Typography variant="h6" gutterBottom>
      15.4. Notices
    </Typography>

    <Typography gutterBottom>
      SESAM will primarily publish notices on https://portal.sesam.io . In case of notices of high
      importance, SESAM may in addition notify the Customer's administrators by through registered
      e-mail address.
    </Typography>

    <Typography gutterBottom>
      The Customer will send notices to SESAM through https://support.sesam.io.
    </Typography>

    <Typography variant="h5" gutterBottom py={2}>
      16. Disputes
    </Typography>

    <Typography gutterBottom>
      Any dispute or disagreement arising between the parties will be resolved by negotiations.
    </Typography>

    <Typography gutterBottom>
      If such negotiations fail, either party may request that the case is brought before a
      Norwegian court. If the parties so agree, the case shall be decided by arbitration after
      Norwegian Act of 14. May 2004 no. 25 on arbitration. If the parties require confidential
      treatment of the arbitration proceedings, hereunder the arbitration court's verdict, this
      shall be agreed between the parties in writing together with the arbitration agreement.
    </Typography>

    <Typography gutterBottom>
      The agreed legal venue shall be the location where SESAM has its registered address when the
      case is made before the court or arbitration court.
    </Typography>

    <Typography gutterBottom>
      The following dispute resolution shall apply to the Customers who pay a Subscription Fee:
    </Typography>

    <Typography gutterBottom>
      The parties will strive to resolve all disputes at the project management level. If any such
      dispute cannot be mutually resolved by the project managers within 7 days, then such dispute
      will immediately be referred to the parties' respective division vice presidents (or
      equivalents) for discussion and resolution. If such parties fail to resolve the dispute within
      14 days, then such dispute will be referred to the party's respective Chief Operating Officer
      (or equivalent) for discussion and attempted resolution. If such dispute cannot be mutually
      resolved by such parties within 14 days, then either party may request that the case is
      brought before a Norwegian court. If the parties so agree, the case shall be decided by
      arbitration after Norwegian Act of 14. May 2004 no. 25 on arbitration. If the parties require
      confidential treatment of the arbitration proceedings, hereunder the arbitration court's
      verdict, this shall be agreed between the parties in writing together with the arbitration
      agreement.
    </Typography>

    <Typography gutterBottom>
      The agreed legal venue shall be the location SESAM has its registered address when the case is
      made before the court or arbitration court.
    </Typography>
  </Stack>
);
