import React from 'react';
import { Box, Typography } from '@mui/material';
import { TestID } from 'testID';
import { Button } from '../Button/Button.view';

export const ConnectFlowSummary: React.FC<{
  onClick: () => void;
  connectorTitle: string;
  vendorTitle?: string;
}> = ({ onClick, connectorTitle, vendorTitle }) => (
  <Box>
    <Typography variant="h5" gutterBottom>
      Summary
    </Typography>
    <Typography variant="body1" gutterBottom>
      Congratulations! You have now connected your {connectorTitle} account to {vendorTitle}. We
      will now start synchronizing your data.
    </Typography>
    <Typography variant="body1" mb={2}>
      We'll let you know when we are done with the initial synchronization via email.
    </Typography>
    <Button variant="contained" onClick={onClick} data-testid={TestID.FinishConnectButton}>
      Finish
    </Button>
  </Box>
);
