import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@mui/material';
import { Link } from '../Link/Link.view';

export const Breadcrumbs: React.FC<{ currentPage: string }> = ({ currentPage }) => (
  <MUIBreadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
    <Link to="/">Home</Link>
    <Typography color="text.primary">{currentPage}</Typography>
  </MUIBreadcrumbs>
);
