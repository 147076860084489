import { useIsAnHourPastSinceConnection } from './useIsAnHourPastSinceConnection';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import { useInitialSystemStatus } from 'hooks/useInitialSystemStatus';
import { useRuntimeInfo } from 'hooks/useRuntimeInfo';
import { useSystemStatus } from 'hooks/useSystemStatus';
import { getFirstAccount } from 'utils';
import { ConnectorCard } from 'views';
import { Connector } from 'types/connector';

export const DashboardConnectorCardContainer: React.FC<{
  connector: Connector;
  isVendor?: boolean;
  testId?: string;
}> = ({ connector, isVendor, testId }) => {
  const navigate = useNavigate();
  const connectorId = connector.id;
  const isConnected = connector.connected;
  const firstAccount = getFirstAccount(connector.accounts);
  const { systemStatus } = useRuntimeInfo(firstAccount);
  const connectorTitle = connector.title ?? capitalize(connectorId);
  const connectorLogo = connector?.logo_symbol ?? connector.logo;
  const description = connector.description ?? 'Synchronising your customer data';

  const accountId = firstAccount?.['account_id'];
  const timestamp = accountId && connector?.accounts?.[accountId]?.audit?.configured_by?.timestamp;
  const isAnHourPastSinceConnection = useIsAnHourPastSinceConnection(timestamp || '');
  const systemState = useSystemStatus(systemStatus);
  const systemStateToDisplay = useInitialSystemStatus({
    isAnHourPastSinceConnection,
    systemStatus,
    systemState,
  });

  const handleClick = () => {
    if (isConnected) {
      navigate(`/connector/${connectorId}`);
    } else {
      navigate(`/${connectorId}/connect`);
    }
  };

  return (
    <ConnectorCard
      handleClick={handleClick}
      logo={connectorLogo}
      systemState={systemStateToDisplay}
      connectorTitle={connectorTitle}
      description={description}
      isVendor={isVendor}
      testId={testId}
    />
  );
};
