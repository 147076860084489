import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TestID } from 'testID';
import { Button } from '../Button/Button.view';

export const ConfirmDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  onCloseDialog?: (event: object, reason: string) => void;
  title: string;
  description: React.ReactNode;
  cancelText: string;
  handleConfirm: () => void;
  destructive: boolean;
  confirmText: string;
  loading?: boolean;
  testId?: string;
}> = ({
  open,
  handleClose,
  onCloseDialog,
  title,
  description,
  cancelText,
  handleConfirm,
  destructive,
  confirmText,
  loading,
  testId,
}) => (
  <Dialog
    open={open}
    onClose={onCloseDialog}
    aria-labelledby="draggable-dialog-title"
    sx={{ '& .MuiDialog-paper': { width: '60%', maxHeight: 300, p: 2 } }}
    disableEscapeKeyDown={loading}
    data-testid={testId}
  >
    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <Typography variant="body1" paragraph={false} variantMapping={{ body1: 'span' }}>
        {description}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        autoFocus
        onClick={handleClose}
        disabled={loading}
        data-testid={TestID.ConfirmDialogCancelButton}
      >
        {cancelText}
      </Button>
      <LoadingButton
        onClick={handleConfirm}
        variant="contained"
        color={destructive ? 'error' : 'info'}
        loading={loading}
        data-testid={TestID.ConfirmDialogConfirmButton}
      >
        {confirmText}
      </LoadingButton>
    </DialogActions>
  </Dialog>
);
