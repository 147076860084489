import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getTenantInfo } from 'api';
import { Tenant } from 'types/tenant';

export const useFetchTenant = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const queryClient = useQueryClient();

  const useQueryResult = useQuery({
    queryKey: ['TenantInfo'],
    enabled: isAuthenticated,
    retry: false,
    queryFn: () =>
      getAccessTokenSilently({
        authorizationParams: {
          scope: 'read:connectors',
        },
      })
        .then((token) => getTenantInfo(token))
        .then((tenant: Tenant) => tenant),
  });

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['TenantInfo'] });
  };

  return { ...useQueryResult, tenant: useQueryResult.data, invalidate };
};
