import { useMemo } from 'react';
import { getDatatypeRelations } from 'utils';
import type { DatatypeRelationsArray, ModelMapping } from 'types/modelMapping';

export const useDatatypeRelations = (state: {
  datatypeId: string;
  modelMapping: ModelMapping;
  currentSystemId: string;
  primarySystem?: string;
}): DatatypeRelationsArray => {
  const { datatypeId, modelMapping, currentSystemId, primarySystem } = state;

  return useMemo(() => {
    return getDatatypeRelations({ datatypeId, modelMapping, currentSystemId, primarySystem });
  }, [datatypeId, modelMapping, currentSystemId, primarySystem]);
};
