import React from 'react';
import { useDatatypeRelations } from 'hooks/useDatatypeRelations';
import { useEntityTypeStatus } from 'hooks/useEntityTypeStatus';
import { getIconFromDatatype } from 'utils';
import { DatatypeCard } from 'views';
import type { DatatypeOptionState } from 'types';
import type { Datatype, Datatypes } from 'types/connector';
import type { ModelMapping } from 'types/modelMapping';
import type { HiddenEntityTypeOptions } from 'types/vendor';
import { DatatypeStatus } from 'enums';

export const DatatypeCardContainer: React.FC<{
  dataTypeStatus: DatatypeStatus;
  modelMapping: ModelMapping;
  currentConnectorId: string;
  datatypeId: string;
  vendorSystem: string;
  datatypeOptions: Datatype;
  vendorId: string;
  vendorTitle: string;
  label: string;
  description?: string;
  numOfSynced: number;
  numOfFailed: number;
  vendorDatatypes: Datatypes;
  expanded?: boolean;
  inProgress: boolean;
  datatypeOptionSate: DatatypeOptionState;
  onOptionToggle: (datatypeId: string, optionName: keyof DatatypeOptionState) => void;
  hiddenEntityTypeOptions: HiddenEntityTypeOptions;
}> = ({
  dataTypeStatus,
  modelMapping,
  currentConnectorId,
  datatypeId,
  vendorSystem,
  vendorId,
  vendorTitle,
  label,
  description,
  numOfSynced,
  numOfFailed,
  vendorDatatypes,
  expanded,
  onOptionToggle,
  datatypeOptionSate,
  inProgress,
  hiddenEntityTypeOptions,
}) => {
  const systemState = useEntityTypeStatus(dataTypeStatus);
  const Icon = getIconFromDatatype(modelMapping, currentConnectorId, datatypeId);
  const relations = useDatatypeRelations({
    datatypeId,
    modelMapping,
    currentSystemId: currentConnectorId,
    primarySystem: vendorSystem,
  });
  const showRelations = relations.length > 0 || vendorSystem === currentConnectorId;

  return (
    <DatatypeCard
      dataTypeStatus={dataTypeStatus}
      modelMapping={modelMapping}
      system={currentConnectorId}
      datatypeId={datatypeId}
      vendorSystem={vendorSystem}
      vendorId={vendorId}
      vendorTitle={vendorTitle}
      label={label}
      description={description}
      numOfSynced={numOfSynced}
      numOfFailed={numOfFailed}
      vendorDatatypes={vendorDatatypes}
      expanded={expanded}
      relations={relations}
      showRelations={showRelations}
      systemState={systemState}
      icon={Icon}
      datatypeOptionsSate={datatypeOptionSate}
      onOptionToggle={onOptionToggle}
      inProgress={inProgress}
      hiddenEntityTypeOptions={hiddenEntityTypeOptions}
    />
  );
};
