import { getFirstAccount } from './accountUtils';
import { stringCompare } from './commonUtils';
import { getRuntimeInfo } from './runtimeInfoUtils';
import { NavigateFunction } from 'react-router-dom';
import { TestID } from 'testID';
import { BannerState } from 'types';
import { Connector, Connectors } from 'types/connector';
import { SupportedConnectors } from 'types/vendor';
import { SystemStatus } from 'enums';

export const getSupportedConnectors = (
  availableConnectors?: Connector[],
  supportedConnectors?: SupportedConnectors | string[]
) => {
  // FIXME: remove fallback logic when connector configs updated https://sesam.myjetbrains.com/youtrack/issue/IS-16550/Support-docsurl-for-vendors-supportedconnectors
  const supportedConnectorsArray = !Array.isArray(supportedConnectors)
    ? Object.keys(supportedConnectors ?? {})
    : supportedConnectors;

  if (!availableConnectors?.length) {
    return [];
  }

  if (!supportedConnectorsArray?.length) {
    return availableConnectors;
  }

  return availableConnectors?.filter((connector) =>
    supportedConnectorsArray?.includes(connector.id)
  );
};

export const sortConnectors = (connectors: Connectors, vendorId: string) =>
  Object.keys(connectors)
    .map((connectorId) => connectors[connectorId])
    .sort((a, b) => customConnectorsSort(a, b, vendorId));

export const customConnectorsSort = (a: Connector, b: Connector, vendorId: string) =>
  a._id === vendorId ? -1 : stringCompare(a.title, b.title);

export const getConnectedConnectors = (connectors: Connector[]) =>
  connectors.filter((connector) => connector.connected);

export const getAvailableConnectors = (connectors: Connector[]) =>
  connectors.filter((connector) => !connector.connected);

export const getBuildReauthoriseStateFn =
  (navigate: NavigateFunction) =>
  (connector: Connector): BannerState | undefined => {
    const firstAccount = getFirstAccount(connector.accounts);
    const runtimeInfo = getRuntimeInfo(firstAccount);

    if (runtimeInfo.systemStatus === SystemStatus.NeedsAttention) {
      return {
        id: connector.id,
        show: true,
        text: `Unable to access your ${connector.title} account. Please re-authorize.`,
        severity: 'warning',
        primaryActionText: 're-authorise',
        secondaryActionText: 'learn more',
        onPrimaryAction: () => {
          navigate(`/${connector._id}/connect`, { state: { isReauthoriseFlow: true } });
        },
        onSecondaryAction: () => {
          window.open(
            'https://docs.sesam.io/talk/reauthorise/index.html',
            '_blank',
            'rel=noopener noreferrer'
          );
        },
        testId: TestID.TokenRefreshBanner,
      };
    }
  };
