import React, { useEffect, useState } from 'react';
import { snackbarSignal } from 'signals/snackbars.signal';
import { Snackbars } from 'views';
import type { SnackbarState } from 'types';

const FIVE_SECONDS = 5000;

export const SnackbarContainer: React.FC = () => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'info',
    anchorOrigin: { vertical: 'top', horizontal: 'center' },
    autoHideDuration: FIVE_SECONDS,
  });

  useEffect(() => {
    const abandon = snackbarSignal.watch((nextState) => {
      if (nextState) {
        setSnackbarState({ ...snackbarState, ...nextState });
      }
    });

    return () => {
      abandon();
    };
  }, [snackbarState]);

  return (
    <Snackbars
      open={snackbarState.open}
      message={snackbarState.message}
      severity={snackbarState.severity}
      anchorOrigin={snackbarState.anchorOrigin}
      autoHideDuration={snackbarState.autoHideDuration}
      onClose={() => setSnackbarState({ ...snackbarState, open: false })}
      testId={snackbarState.testId}
    />
  );
};
