import React from 'react';
import { Box } from '@mui/material';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { makeFilterHiddenEntityTypes } from 'utils';
import { DatatypeEntityContainer } from './DatatypeEntity.container';
import { DatatypeSummaryCardContainer } from './DatatypeSummaryCard.container';
import type { Connectors, Datatypes } from 'types/connector';
import { ModelMapping } from 'types/modelMapping';

export const ConnectFlowEntityTypesContainer: React.FC<{
  connectors: Connectors;
  connectorId: string;
  vendorId: string;
  modelMapping: ModelMapping;
  vendorIcon: string;
  connectorIcon: string;
  hasVendor: boolean;
}> = ({
  connectors,
  connectorId,
  vendorId,
  modelMapping,
  vendorIcon,
  connectorIcon,
  hasVendor,
}) => {
  const connector = connectors[connectorId];
  const vendorDatatypes: Datatypes = connectors?.[vendorId]?.['available_datatypes'];
  const availableDatatypes = hasVendor ? connector?.['available_datatypes'] : {};

  const baseConnectorId = get(connector, 'connector_base', connectorId);
  const filterHiddenEntityTypes = makeFilterHiddenEntityTypes(
    get(connector, 'hidden_entity_types', [])
  );

  if (isEmpty(availableDatatypes)) {
    return (
      <DatatypeEntityContainer
        connectorId={baseConnectorId ?? connector._id}
        connectorIcon={connectorIcon}
        modelMapping={modelMapping}
        filterHiddenEntityTypes={filterHiddenEntityTypes}
      />
    );
  }

  return (
    <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
      {Object.keys(availableDatatypes)
        .filter(filterHiddenEntityTypes)
        .map((key) => {
          const vendorName = availableDatatypes[key].label ?? capitalize(key);

          return (
            <DatatypeSummaryCardContainer
              key={key}
              vendorId={vendorId}
              systemId={baseConnectorId}
              datatypeId={key}
              modelMapping={modelMapping}
              vendorName={vendorName}
              vendorIcon={vendorIcon}
              connectorIcon={connectorIcon}
              vendorDatatypes={vendorDatatypes}
            />
          );
        })}
    </Box>
  );
};
