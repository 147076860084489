import { Router } from './Router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/system';
import { SnackbarContainer } from 'containers/Snackbar.container';
import { lightTheme } from 'themes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => (
  <ThemeProvider theme={lightTheme}>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <Container disableGutters maxWidth={false} sx={{ height: '100%' }}>
        <Router />
        <SnackbarContainer />
      </Container>
      {window.Cypress ? null : (
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      )}
    </QueryClientProvider>
  </ThemeProvider>
);
