import React, { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AlertColor, Box } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { useRuntimeInfo } from 'hooks/useRuntimeInfo';
import { useSystemStatus } from 'hooks/useSystemStatus';
import { getApiKeyConnectorFieldName, getFirstAccount, getIsConnected, getTimeSince } from 'utils';
import { configure, disconnect } from 'api';
import { snackbarSignal } from 'signals/snackbars.signal';
import { ConnectorConfiguration, ConnectorStatus, DisconnectDialog } from 'views';
import { TestID } from 'testID';
import { AdditionalProperties, Connectors } from 'types/connector';
import { ConnectorAccountInfo } from 'types/connectorAccountInfo';
import { Tenant } from 'types/tenant';

const buildAdditionalPropertiesPayload = (additionalValues?: AdditionalProperties) =>
  Object.fromEntries(Object.entries(additionalValues || {}).filter((o) => o[1].editable));

export const ConnectorConfigurationContainer: React.FC<{
  navigate: NavigateFunction;
  connectorId: string;
  connectors: Connectors;
  tenantInfo: Tenant;
  connectorAccountInfo?: ConnectorAccountInfo;
}> = ({ navigate, connectorId, connectors, tenantInfo, connectorAccountInfo }) => {
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [additionalValues, setAdditionalValues] = useState<AdditionalProperties>();
  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const connectorName = capitalize(connectorId);
  const connector = connectors?.[connectorId];
  const connectorTitle = connector?.title;
  const firstAccount = getFirstAccount(connector?.accounts);
  const isConnected = getIsConnected(firstAccount);
  const { systemStatus } = useRuntimeInfo(firstAccount);
  const systemState = useSystemStatus(systemStatus);
  const connectorType = firstAccount?.type;
  const additionalProperties = firstAccount?.['additional-properties'];
  const vendorAccountId = tenantInfo?.vendor_info?.account_id;
  const accountId = firstAccount?.['account_id'] ?? vendorAccountId;
  const vendorId = tenantInfo?.vendor_config?._id;
  const timestamp = accountId && connector?.accounts?.[accountId]?.audit?.configured_by?.timestamp;
  const since = getTimeSince(timestamp);
  const userEmail = accountId && connector?.accounts?.[accountId]?.audit?.configured_by?.user_email;
  const [shouldDisabledSaveButton, setShouldDisabledSaveButton] = useState(false);

  useEffect(() => {
    const shouldDisabled = Object.values(additionalValues || {}).every(
      (val) => val.editable === false
    );

    setShouldDisabledSaveButton(shouldDisabled);
  }, [additionalValues]);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setAdditionalValues(additionalProperties);
  }, [additionalProperties]);

  const showDisconnectDialog = () => {
    setDisconnectModalOpen(true);
  };

  const handleDisconnect = () => {
    setShowButtonLoader(true);
    getAccessTokenSilently({
      authorizationParams: {
        scope: 'write:connectors',
      },
    })
      .then((token) => disconnect(connectorId, token))
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response?.statusText);
        }

        navigate(`/`, { state: { info: `${connectorTitle} is disconnected!` } });
        setDisconnectModalOpen(false);
      })
      .catch(() => {
        showSnackMessage(
          'Failed to disconnect!',
          'error',
          TestID.ConnectorDisconnectedErrorSnackbarMessage
        );
      })
      .finally(() => setShowButtonLoader(false));
  };

  const handleSaveAdditionalProperties = () => {
    setDisconnectModalOpen(false);
    setShowButtonLoader(true);
    const additionalProperties = buildAdditionalPropertiesPayload(additionalValues);

    getAccessTokenSilently({
      authorizationParams: {
        scope: 'write:connectors',
      },
    })
      .then((token) => configure({ connectorId, additionalProperties, token }))
      .then(() => {
        setShowButtonLoader(false);
        showSnackMessage('Changes are applied.', 'success', TestID.AdditionalPropertiesSnackbar);
      })
      .catch(() => {
        setShowButtonLoader(false);
        showSnackMessage('Failed to apply changes.', 'error', TestID.AdditionalPropertiesSnackbar);
      });
  };
  const showSnackMessage = (message: string, severity: AlertColor, testId: string) =>
    snackbarSignal.emit({
      open: true,
      message,
      severity,
      testId,
    });

  return (
    <Box>
      <ConnectorStatus
        systemState={{ ...systemState, description: '' }}
        connectorId={connectorId}
      />
      <ConnectorConfiguration
        accountId={accountId}
        userEmail={userEmail}
        timestamp={timestamp}
        since={since}
        connectorType={connectorType}
        additionalValues={additionalValues}
        setAdditionalValues={setAdditionalValues}
        handleSaveAdditionalProperties={handleSaveAdditionalProperties}
        loading={showButtonLoader}
        isConnected={isConnected}
        connectorName={connectorName}
        connectorId={connectorId}
        vendorId={vendorId}
        showDisconnectDialog={showDisconnectDialog}
        shouldDisabledSaveButton={shouldDisabledSaveButton}
        maskedApiKey={connectorAccountInfo?.maskedApiKey}
        apiKeyName={getApiKeyConnectorFieldName(connector?.auth?.name)}
      />
      <DisconnectDialog
        open={disconnectModalOpen}
        connectorName={connectorTitle}
        handleClose={() => setDisconnectModalOpen(false)}
        onDisconnect={() => handleDisconnect()}
        loading={showButtonLoader}
      />
    </Box>
  );
};
