import React from 'react';
import Typography from '@mui/material/Typography';

export const TermsOfServiceHeader: React.FC<{
  maxHeight?: string;
  userName?: string;
  vendorBanner?: string;
}> = ({ userName, vendorBanner }) => (
  <>
    <Typography variant="h5" gutterBottom mb={4}>
      Welcome to {vendorBanner}, {userName}!
    </Typography>
    <Typography>You are about to connect your {vendorBanner} account.</Typography>
    <Typography>
      As the first step, please provide contact information and review Terms of Service.
    </Typography>
  </>
);
