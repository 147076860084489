import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useFetchAvailableAccounts } from 'hooks/useFetchAvailableAccounts';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { AccountSelectionTable, getCompactFlowSecondConnectorURL } from 'utils';
import { finalizeLogin } from 'api';
import { connectFlowStepsSignal } from 'signals/connectFlowSteps.signal';
import { CircularLoading, ConnectFlowButtons, ConnectFlowHeader, Section } from 'views';
import { AccountSelectionTableKey } from 'types';
import { ConnectFlowAdditionalSteps, ConnectFlowSteps } from 'enums';

export const AccountSelectionContainer: React.FC<{ isCompactFlow?: boolean }> = ({
  isCompactFlow,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { connectors, connectorsLoading: isConnectorsLoading } = useFetchConnectors();
  const { connectorId, secondConnectorId } = useParams();
  const location = useLocation();
  const connector = connectors?.[connectorId || ''];
  const connectorLogo = connector?.logo_symbol ?? connector?.logo;
  const {
    availableAccounts,
    invalidate,
    isLoading: isAvailableAccountsLoading,
  } = useFetchAvailableAccounts(connectorId as string, location?.state?.apiKey);
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState('');

  useEffect(() => {
    connectFlowStepsSignal.emit(ConnectFlowAdditionalSteps.SelectAccount);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- only on un-mounting!
  useEffect(() => () => invalidate(), []);

  if (isConnectorsLoading || isAvailableAccountsLoading) {
    return <CircularLoading />;
  }

  if (!availableAccounts || !availableAccounts?.available_accounts?.length || !connector) {
    return null;
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAccount((event.target as HTMLInputElement).value);
    setIsValid(true);
  };

  const finalize_login = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    setShowButtonLoader(true);

    getAccessTokenSilently({
      authorizationParams: {
        scope: 'write:connectors',
      },
    })
      .then((token) => {
        return finalizeLogin(token, connectorId || '', selectedAccount);
      })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response?.statusText);
        }

        connectFlowStepsSignal.emit(ConnectFlowSteps.Summary);
        if (isCompactFlow) {
          const navigateToUrl = getCompactFlowSecondConnectorURL({
            secondConnectorId,
            env: process.env.NODE_ENV,
          });

          navigate(navigateToUrl);
        } else {
          navigate('/');
        }
      })
      .catch(() => {
        setShowButtonLoader(false);
      });
  };

  const accountSelectionTitle =
    AccountSelectionTable[connectorId as AccountSelectionTableKey]?.name;

  return (
    <>
      <ConnectFlowHeader connectorTitle={connector.title} connectorLogo={connectorLogo || ''} />
      <Typography variant="h5" mb={1}>
        {accountSelectionTitle}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {availableAccounts.available_accounts.length > 1
          ? `We found multiple ${
              AccountSelectionTable[connectorId as AccountSelectionTableKey].entityNames
            } associated with your ${
              connector.title
            } user. Please select the one you want to connect to.`
          : ` We've identified a single ${
              AccountSelectionTable[connectorId as AccountSelectionTableKey].entityName
            } associated with your ${connector.title} user. Please select it to proceed.`}
      </Typography>
      <FormControl sx={{ mt: 2 }}>
        <Section showBorder>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            onChange={handleRadioChange}
          >
            {availableAccounts.available_accounts.map((account) => (
              <FormControlLabel
                key={account.account_id}
                value={account.account_id}
                control={<Radio size="small" />}
                label={account.label}
              />
            ))}
          </RadioGroup>
        </Section>
      </FormControl>
      <ConnectFlowButtons
        onConnect={finalize_login}
        onCancel={() => navigate('/')}
        isConnectButtonDisabled={!isValid}
        showButtonLoader={showButtonLoader}
        text="Continue"
      />
    </>
  );
};
