import React from 'react';
import { Stack } from '@mui/material';
import { ReauthoriseButtonContainer } from 'containers/ReauthoriseButton.container';
import {
  AuthorisationSection,
  DisconnectSection,
  DocumentationSection,
  ReauthoriseSection,
} from 'views';
import { AdditionalParametersSection } from 'views/AdditionalParametersSection/AdditionalParameters.view';
import { TestID } from 'testID';
import { ConnectorAuthenticationType } from 'types';
import { AdditionalProperties } from 'types/connector';

export const ConnectorConfiguration: React.FC<{
  accountId: string;
  userEmail: string;
  timestamp: string;
  since: string;
  connectorType?: ConnectorAuthenticationType;
  additionalValues?: AdditionalProperties;
  setAdditionalValues: React.Dispatch<React.SetStateAction<AdditionalProperties | undefined>>;
  handleSaveAdditionalProperties: () => void;
  loading: boolean;
  isConnected: boolean;
  connectorName: string;
  connectorId: string;
  vendorId: string;
  showDisconnectDialog: () => void;
  shouldDisabledSaveButton: boolean;
  maskedApiKey?: string;
  apiKeyName?: string;
}> = ({
  accountId,
  userEmail,
  timestamp,
  since,
  connectorType,
  additionalValues,
  setAdditionalValues,
  handleSaveAdditionalProperties,
  loading,
  isConnected,
  connectorName,
  connectorId,
  vendorId,
  showDisconnectDialog,
  shouldDisabledSaveButton,
  maskedApiKey,
  apiKeyName,
}) => (
  <Stack>
    <>
      <AuthorisationSection
        accountId={accountId}
        userEmail={userEmail}
        timestamp={timestamp}
        since={since}
      />
      {connectorType === 'api_key' && additionalValues && (
        <AdditionalParametersSection
          additionalValues={additionalValues}
          disabled={loading || shouldDisabledSaveButton}
          handleSaveAdditionalProperties={handleSaveAdditionalProperties}
          loading={loading}
          setAdditionalValues={setAdditionalValues}
        />
      )}
    </>
    {isConnected && (
      <ReauthoriseSection
        connectorName={connectorName}
        maskedApiKey={maskedApiKey}
        apiKeyName={apiKeyName}
      >
        <ReauthoriseButtonContainer
          connectorId={connectorId}
          data-testid={TestID.ReauthoriseButton}
        />
      </ReauthoriseSection>
    )}
    <DocumentationSection connectorName={connectorName} connectorId={connectorId} />
    {vendorId !== connectorId && isConnected && (
      <DisconnectSection
        connectorName={connectorName}
        showDisconnectDialog={showDisconnectDialog}
      />
    )}
  </Stack>
);
