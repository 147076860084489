import { Signal, SignalType, Watcher } from './types';

/**
 * Signal observer
 */
export const signal =
  <T>(previousState?: T, emitted = false) =>
  (behavior: SignalType = SignalType.Default): Signal<T> => {
    const watchers = new Set<Watcher<T>>();

    /**
     * unsubscribes from current signal
     */
    const abandon = (watcherToRemove: Watcher<T>) => watchers.delete(watcherToRemove);

    /**
     * subscribes to current signal
     */
    const watch = (watcher: Watcher<T>) => {
      watchers.add(watcher);

      if (behavior === SignalType.ReEmit && emitted === true) {
        emitToSingleWatcher(watcher, previousState);
      }

      return () => abandon(watcher);
    };

    const emitToSingleWatcher = (watcher: Watcher<T>, nextState?: T, previousState?: T) =>
      watcher(nextState, previousState);

    /**
     * fires new data to all observers
     */
    const emit = (nextState?: T) => {
      watchers.forEach((watcher) => emitToSingleWatcher(watcher, nextState, previousState));

      previousState = nextState;
      emitted = true;
    };

    /**
     * return array of current watchers
     */
    const getWatchers = () => Array.from(watchers);

    return {
      watch,
      abandon,
      emit,
      getWatchers,
    };
  };

/**
 * create a Signal observer
 */
export const createSignal = <T>(behavior?: SignalType): Signal<T> => signal<T>()(behavior);
