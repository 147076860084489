import isEmpty from 'lodash/isEmpty';
import { CompactFlowFirstConnectorActionArgs, SecondConnectorActionArgs } from 'types';
import { CompactFlowActions } from 'enums';

type SecondConnectorUrlArgs = {
  secondConnectorId?: string;
  env?: string;
};

export const getCompactFlowSecondConnectorURL = (args: SecondConnectorUrlArgs): string => {
  const isDevMode = args.env === 'development';

  if (args.secondConnectorId) {
    return `/compact/${args.secondConnectorId}/connect`;
  }

  return isDevMode ? '/' : '/onboarding';
};

export const calculateCompactFlowFirstConnectorAction = (
  args: CompactFlowFirstConnectorActionArgs
): CompactFlowActions => {
  const statuses = [args.fetchTenantStatus, args.fetchConnectorsStatus];

  if (statuses.includes('pending')) {
    return CompactFlowActions.DisplayLoader;
  }

  if (statuses.includes('success') && isEmpty(args.connectors)) {
    return CompactFlowActions.NavigateToHomepage;
  }

  if (
    statuses.includes('success') &&
    isEmpty(args?.tenant?.ui_state?.next_connector) &&
    args.connectorType
  ) {
    return args.connectorType === 'api_key'
      ? CompactFlowActions.StartApiKeyCompactFlow
      : args.connectorType === 'oauth2'
      ? CompactFlowActions.StartOAuth2CompactFlow
      : CompactFlowActions.DisplayLoader;
  }

  return CompactFlowActions.NavigateToHomepage;
};

export const calculateCompactFlowSecondConnectorAction = (
  args: SecondConnectorActionArgs
): CompactFlowActions => {
  const statuses = [args.fetchTenantStatus, args.fetchConnectorsStatus];

  if (statuses.includes('idle') || statuses.includes('error')) {
    return CompactFlowActions.DoNotNavigate;
  }

  if (statuses.includes('pending')) {
    return CompactFlowActions.DisplayLoader;
  }

  if (
    statuses.includes('success') &&
    (!args?.nextConnector || args.isCompact === false || args?.secondConnectorId)
  ) {
    return CompactFlowActions.DoNotNavigate;
  }

  if (statuses.includes('success') && isEmpty(args.connectors)) {
    return CompactFlowActions.NavigateToHomepage;
  }

  if (statuses.includes('success') && args.nextConnector) {
    return args.connectorType === 'api_key'
      ? CompactFlowActions.StartSecondApiKeyCompactFlow
      : args.connectorType === 'oauth2'
      ? CompactFlowActions.StartOAuth2SecondCompactFlow
      : CompactFlowActions.DisplayLoader;
  }

  return CompactFlowActions.DisplayLoader;
};
