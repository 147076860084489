import { getIsConnected } from './accountUtils';
import { RuntimeData, RuntimeDataTypes } from 'types';
import type { Account, RuntimeInfo } from 'types/connector';
import { DatatypeStatus, SystemStatus, SystemTokenStatus } from 'enums';

const calculateSystemStatus = (state: {
  isConnected: boolean;
  isAllFailing: boolean;
  isSomeFailing: boolean;
  tokenStatus?: string;
}) => {
  if (!state.isConnected) {
    return SystemStatus.NotConnected;
  }

  if (state.tokenStatus === SystemTokenStatus.RefreshFailed) {
    return SystemStatus.NeedsAttention;
  }

  if (state.isAllFailing) {
    return SystemStatus.LookingIntoIt;
  }

  if (state.isSomeFailing) {
    return SystemStatus.LimitedService;
  }

  return SystemStatus.Connected;
};

export const buildRuntimeData = (runtimeInfo?: RuntimeInfo) => {
  if (!runtimeInfo) {
    return {};
  }

  const runtimeData: RuntimeData = {};

  Object.entries(runtimeInfo || {}).forEach(
    (info) =>
      (runtimeData[info[0]] = {
        share: info[1]?.share?.status,
        collect: info[1]?.collect?.status,
        calculatedEntityStatus: info[1]?.share?.status ?? DatatypeStatus.Initialising,
      })
  );

  return runtimeData;
};

const isReadOnlyRelation = (datatype: RuntimeDataTypes) =>
  datatype.collect === DatatypeStatus.OK && datatype.share !== DatatypeStatus.OK;

const isWriteOnlyRelation = (datatype: RuntimeDataTypes) =>
  datatype.collect !== DatatypeStatus.OK && datatype.share === DatatypeStatus.OK;

const isOK = (datatype: RuntimeDataTypes) =>
  [datatype.collect, datatype.share].every((item) => item === DatatypeStatus.OK);

export const calculatedDatatypesRuntimeData = (runtimeData: RuntimeData) => {
  const clonedRuntimeData = { ...runtimeData };

  Object.entries(clonedRuntimeData || {}).forEach((datatypes) => {
    const datatype = datatypes[1];

    if (isReadOnlyRelation(datatype)) {
      datatype.calculatedEntityStatus = DatatypeStatus.ReadingOnly;
    } else if (isWriteOnlyRelation(datatype)) {
      datatype.calculatedEntityStatus = DatatypeStatus.WritingOnly;
    } else if (isOK(datatype)) {
      datatype.calculatedEntityStatus = DatatypeStatus.OK;
    } else {
      datatype.calculatedEntityStatus = datatype.share ?? DatatypeStatus.Initialising;
    }
  });

  return clonedRuntimeData;
};

export const getRuntimeInfo = (firstAccount?: Account) => {
  const isConnected = getIsConnected(firstAccount);
  const runtimeData = buildRuntimeData(firstAccount?.runtime_info);
  const calculatedRuntimeData = calculatedDatatypesRuntimeData(runtimeData);

  const statuses = Object.values(calculatedRuntimeData);
  const isAllFailing = statuses.every((status) => status.share === DatatypeStatus.Failing);
  const isSomeFailing = statuses.some((status) => status.share === DatatypeStatus.Failing);

  const systemStatus = calculateSystemStatus({
    isConnected,
    isAllFailing,
    isSomeFailing,
    tokenStatus: firstAccount?.token?.status,
  });

  return { runtimeData: calculatedRuntimeData, systemStatus };
};
