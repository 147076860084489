import { withTheme } from '@emotion/react';
import React, { ErrorInfo, ReactNode } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Grid, Theme, Typography } from '@mui/material';
import { Button } from '../Button/Button.view';

type ErrorBoundaryProps = {
  navigate: NavigateFunction;
  theme: Theme;
  children: ReactNode;
};

type ErrorBoundaryState = {
  error?: Error;
  errorInfo?: ErrorInfo;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Grid container alignItems="center" direction="column" mt={10}>
          <Grid item>
            <Typography variant="h4" gutterBottom>
              Ooops! Something went wrong.
            </Typography>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                this.props.navigate('/');
              }}
              sx={{ marginTop: '30px' }}
            >
              Try again
            </Button>
          </Grid>

          <Grid item px={5}>
            <details
              style={{
                whiteSpace: 'pre-wrap',
                marginTop: '20px',
                backgroundColor: this.props.theme.palette.background.lightGrey,
                boxShadow: `1px 1px 2px ${this.props.theme.palette.background.darkGrey}`,
              }}
            >
              <summary
                style={{
                  padding: '4px',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                {this.state?.error?.toString()}
              </summary>
              <p
                style={{
                  padding: '4px',
                  margin: '0',
                }}
              >
                {this.state?.errorInfo?.componentStack}
              </p>
            </details>
          </Grid>
        </Grid>
      );
    }
    return this.props.children;
  }
}

export const ErrorBoundaryWithTheme = withTheme(ErrorBoundary);
