import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LinkOwnProps, Link as MUILink } from '@mui/material';
import { Box, Stack } from '@mui/material';
import { lightTheme } from 'themes';

export const Link: React.FC<
  LinkOwnProps &
    RouterLinkProps &
    React.PropsWithChildren<{
      testId?: string;
      hideOpenInNewIcon?: boolean;
    }>
> = ({ children, to, underline, target, testId, color, hideOpenInNewIcon = false, ...props }) => (
  <MUILink
    component={RouterLink}
    data-testid={testId}
    to={to}
    target={target}
    rel={target === '_blank' ? 'noopener,noreferrer' : ''}
    color={color === 'white' ? lightTheme.palette.primary.light : undefined}
    underline={underline}
    {...props}
  >
    <Stack
      alignItems="center"
      flexDirection="row"
      display="inline-flex"
      component="span"
      sx={{ textDecoration: 'inherit' }}
    >
      {children}
      {target === '_blank' && !hideOpenInNewIcon ? (
        <Box component="span">
          <OpenInNewIcon fontSize="small" sx={{ marginLeft: '2px', fontSize: '16px' }} />
        </Box>
      ) : null}
    </Stack>
  </MUILink>
);

export type LinkProps = React.ComponentProps<typeof Link>;
