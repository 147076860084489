import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { AdditionalProperties } from 'types/connector';

export const useBuildAdditionalProperties = (
  isReauthoriseFlow: boolean,
  additionalProperties: AdditionalProperties,
  getAdditionalPropertyValue: (propertyKey: string) => string | undefined
) => {
  return useMemo(() => {
    if (isReauthoriseFlow && !isEmpty(additionalProperties)) {
      const result = Object.keys(additionalProperties).map((key) => {
        const additionalPropertyValue = getAdditionalPropertyValue(key);

        return { [key]: { value: additionalPropertyValue } };
      });

      return Object.assign({}, ...result);
    }
  }, [additionalProperties, getAdditionalPropertyValue, isReauthoriseFlow]);
};
