import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { Button, Section } from 'views';
import { TestID } from 'testID';

export const StartFreshSection: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <Section header="Start fresh" data-testid={TestID.StartFreshSection} showBorder danger>
    <Stack direction="column">
      <Typography variant="body1" gutterBottom>
        In situations where a transition to a new primary system is desired or a reconfiguration is
        needed, opting to start afresh offers a valuable solution. This action involves
        disconnecting your systems and wiping all accumulated data.
      </Typography>
      <Grid item mt={1} width="18em">
        <Button
          variant="contained"
          color="error"
          onClick={onClick}
          data-testid={TestID.StartFreshButton}
        >
          Start fresh
        </Button>
      </Grid>
    </Stack>
  </Section>
);
