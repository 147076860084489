import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { Divider, MenuItem, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TestID } from 'testID';
import { SesamTalkLinks } from 'commonConstants';
import { Link } from '../Link/Link.view';
import { Menu } from '../Menu/Menu.view';

export const UserMenu: React.FC<{
  username?: string;
  handleNavigate: (path: string) => void;
  handleLogout: () => void;
  handleCloseMenu: () => void;
  anchorEl?: Element;
  isOpen: boolean;
  disableMyAccount?: boolean;
}> = ({
  username,
  isOpen,
  anchorEl,
  disableMyAccount = false,
  handleCloseMenu,
  handleNavigate,
  handleLogout,
}) => (
  <Menu
    id="menu-appbar"
    anchorEl={anchorEl}
    open={isOpen}
    onClose={handleCloseMenu}
    data-testid={TestID.UserMenu}
  >
    <MenuItem disabled sx={{ minWidth: '18em' }}>
      <Stack direction="row" alignItems="center" gap={1}>
        <PersonIcon />
        <Typography variant="body1">{username}</Typography>
      </Stack>
    </MenuItem>
    <Divider tabIndex={-1} />
    <MenuItem
      onClick={() => handleNavigate('/my-account')}
      data-testid={TestID.MyAccountMenuItemButton}
      tabIndex={1}
      disabled={disableMyAccount}
    >
      My account
    </MenuItem>
    <Divider />
    <MenuItem data-testid={TestID.HelpMenuItemButton}>
      <Link to={SesamTalkLinks.SesamTalkSupport} target="_blank" data-testid={TestID.DocsUrl}>
        Help
      </Link>
    </MenuItem>
    <MenuItem onClick={handleLogout} data-testid={TestID.LogoutMenuItemButton}>
      Log out
    </MenuItem>
  </Menu>
);
