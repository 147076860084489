import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { logger } from 'utils';
import { getAvailableAccounts } from 'api';
import { AvailableAccounts } from 'types/availableAccounts';

export const useFetchAvailableAccounts = (connectorId: string, apiKey?: string) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const queryClient = useQueryClient();

  const fetchAvailableAccounts = () =>
    getAccessTokenSilently({
      authorizationParams: {
        scope: 'read:connectors',
      },
    })
      .then((token) => getAvailableAccounts(token, connectorId, apiKey))
      .then(async (response) => {
        const json = response.json();

        if (response.status !== 200) {
          const error = await json;
          throw Error(error.message);
        } else {
          return json;
        }
      })
      .then((availableAccounts: AvailableAccounts) => availableAccounts)
      .catch((errorMessage) => {
        setErrorMessage(errorMessage);
        logger.error(errorMessage);
        return null;
      });

  const { isLoading, data } = useQuery({
    queryKey: ['AvailableAccounts', { type: connectorId }],
    enabled: isAuthenticated,
    queryFn: fetchAvailableAccounts,
  });

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: ['AvailableAccounts', { type: connectorId }],
      exact: true,
    });
  };

  return { availableAccounts: data, isLoading, invalidate, errorMessage };
};
