import React from 'react';
import { Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { TestID } from 'testID';
import { SesamTalkLinks } from 'commonConstants';
import { Link } from '../Link/Link.view';

export const AppFooter: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const Copyright = (
    <Typography paragraph={false} gutterBottom={false}>
      © {new Date().getFullYear()} Sesam.io AS
    </Typography>
  );

  return (
    <footer data-testid={TestID.AppFooter}>
      <Stack
        sx={{
          width: '100%',
          backgroundColor: 'background.dark',
          minHeight: '88px',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          color={'primary.light'}
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: isMobile ? 1 : 2, width: '80%' }}
        >
          {!isMobile && Copyright}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}
          >
            <Link
              to={SesamTalkLinks.SesamTalkSupport}
              target="_blank"
              color="white"
              underline="hover"
              data-testid={TestID.FooterContactSupportLink}
              style={{ marginRight: 10 }}
            >
              <Typography paragraph={false} gutterBottom={false}>
                Contact Support
              </Typography>
            </Link>
            {isMobile && Copyright}
          </Stack>
        </Stack>
      </Stack>
    </footer>
  );
};
