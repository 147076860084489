import { initDatatypeOptionsService } from 'services';
import React, { useMemo, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AlertColor, Box } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { useSystemDatatypes } from 'hooks/useSystemDatatypes';
import { getBuildDatatypeOptionsStateFn, getOnlyChangedDatatypes } from 'utils/datatypesUtils';
import { configure } from 'api';
import { snackbarSignal } from 'signals/snackbars.signal';
import { DatatypeCardContainer } from 'containers/DatatypeCard.container';
import { GlobalSavePanelPositioner } from 'views';
import { TestID } from 'testID';
import type { DatatypeOptionState, DatatypeOptionsState, RuntimeData } from 'types';
import type { Datatypes, RuntimeInfo } from 'types/connector';
import type { ModelMapping } from 'types/modelMapping';
import type { HiddenEntityTypeOptions } from 'types/vendor';

const buildDatatypeOptionsState = getBuildDatatypeOptionsStateFn();

export const DatatypesContainer: React.FC<{
  datatypes?: Datatypes;
  vendorTitle: string;
  filterHiddenEntityTypes: (datatypeId: string) => boolean;
  availableDatatypes: Datatypes;
  modelMapping: ModelMapping;
  baseConnectorId: string;
  vendorId: string;
  baseVendorConnectorId: string;
  runtimeData: RuntimeData;
  vendorDatatypes: Datatypes;
  connectorId: string;
  invalidateConnectors: () => void;
  runtimeInfo?: RuntimeInfo;
  hiddenEntityTypeOptions: HiddenEntityTypeOptions;
}> = ({
  datatypes,
  vendorTitle,
  filterHiddenEntityTypes,
  availableDatatypes,
  modelMapping,
  baseConnectorId,
  vendorId,
  baseVendorConnectorId,
  runtimeData,
  vendorDatatypes,
  connectorId,
  invalidateConnectors,
  runtimeInfo,
  hiddenEntityTypeOptions,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const { filteredAvailableDatatypes, syncedEntities, failedEntities, datatypeOptions } =
    useSystemDatatypes({
      datatypes,
      availableDatatypes,
      modelMapping,
      baseConnectorId,
      runtimeInfo,
    });
  const initialDatatypeOptionsState = useRef<DatatypeOptionsState>(
    buildDatatypeOptionsState(datatypeOptions)
  );
  const datatypesService = useMemo(
    () => initDatatypeOptionsService(initialDatatypeOptionsState.current),
    []
  );
  const [changesCount, setChangesCount] = useState(datatypesService().changesCount);
  const [datatypeOptionsSate, setDatatypeOptionsSate] = useState<DatatypeOptionsState>(
    initialDatatypeOptionsState.current
  );

  const handleSave = () => {
    setShowSaveLoader(true);

    getAccessTokenSilently({
      authorizationParams: {
        scope: 'write:connectors',
      },
    })
      .then((token) =>
        configure({
          connectorId,
          datatypes: getOnlyChangedDatatypes(datatypesService().initialState, datatypeOptionsSate),
          token,
        })
      )
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response?.statusText);
        }

        showSnackMessage('Changes are saved.', 'success', TestID.SnackbarSuccessMessage);
        invalidateConnectors();
        setChangesCount(0);
        datatypesService().resetCounter();
      })
      .catch(() => {
        showSnackMessage(
          'Failed to apply changes, please try again.',
          'error',
          TestID.SnackbarErrorMessage
        );
      })
      .finally(() => setShowSaveLoader(false));
  };

  const showSnackMessage = (message: string, severity: AlertColor, testId: string) => {
    snackbarSignal.emit({
      message,
      severity,
      open: true,
      testId,
    });
  };

  const onOptionToggle = (datatypeId: string, optionName: keyof DatatypeOptionState) => {
    datatypesService().toggle(datatypeId, optionName);
    setDatatypeOptionsSate(datatypesService().currentState);
    setChangesCount(datatypesService().changesCount);
  };

  const onCancel = () => {
    setChangesCount(0);
    datatypesService().resetState();
    setDatatypeOptionsSate(datatypesService().currentState);
  };

  return (
    <>
      <Box mt={2}>
        {Object.keys(filteredAvailableDatatypes)
          .filter(filterHiddenEntityTypes)
          .map((key: string) => (
            <DatatypeCardContainer
              key={key}
              datatypeId={key}
              datatypeOptions={datatypeOptions[key]}
              label={availableDatatypes?.[key]?.label ?? capitalize(key)}
              modelMapping={modelMapping}
              currentConnectorId={baseConnectorId}
              vendorId={vendorId}
              vendorTitle={vendorTitle}
              vendorSystem={baseVendorConnectorId}
              numOfSynced={syncedEntities[key]}
              numOfFailed={failedEntities[key]}
              dataTypeStatus={runtimeData[key]?.calculatedEntityStatus}
              vendorDatatypes={vendorDatatypes}
              inProgress={showSaveLoader}
              datatypeOptionSate={datatypeOptionsSate[key]}
              onOptionToggle={onOptionToggle}
              hiddenEntityTypeOptions={hiddenEntityTypeOptions}
            />
          ))}
      </Box>
      <GlobalSavePanelPositioner
        changeCount={changesCount}
        onCancel={onCancel}
        onSave={handleSave}
        inProgress={showSaveLoader}
        show={changesCount > 0}
      />
    </>
  );
};
