import { AuthProtectedRoute } from './AuthProtectedRoute';
import { Auth0ProviderWithRedirectCallback } from 'Auth0ProviderWithRedirectCallback';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { getBasename } from 'utils';
import { NotFound } from './pages/NotFound';
import { CompactFlowAccountSelectionPage } from 'pages/CompactFlowAccountSelection.page';
import { CompactFlowFirstConnectorPage } from 'pages/CompactFlowFirstConnector.page';
import { CompactFlowSecondConnectorPage } from 'pages/CompactFlowSecondConnector.container';
import { ConnectFlowPage } from 'pages/ConnectFlow.page';
import { ConnectFlowSummaryPage } from 'pages/ConnectFlowSummary.page';
import { ConnectorPage } from 'pages/Connector.page';
import { ConnectorConfigurationPage } from 'pages/ConnectorConfiguration.page';
import { ConnectorSynchronisationPage } from 'pages/ConnectorSynchronisation.page';
import { DashboardPage } from 'pages/Dashboard.page';
import { MyAccountPage } from 'pages/MyAccount.page';
import { SelectAccountPage } from 'pages/SelectAccount.page';
import { SettingsPage } from 'pages/Settings.page';
import { MainLayoutContainer } from 'containers/MainLayout.container';
import { ValidConnectFlowRouteContainer } from 'containers/ValidConnectFlowRoute.container';
import { ErrorView } from 'views';

const SmartNavigate: React.FC<{ to: string; replace: boolean }> = ({ to, replace = false }) => {
  const location = useLocation();
  return <Navigate to={{ pathname: to, search: location.search }} replace={replace} />;
};

export const Router = () => (
  <BrowserRouter basename={getBasename()}>
    <Auth0ProviderWithRedirectCallback>
      <React.StrictMode>
        <CompactFlowSecondConnectorPage>
          <Routes>
            <Route
              path="/compact/:connectorId/connect/:secondConnectorId?"
              element={<CompactFlowFirstConnectorPage />}
            />
            <Route
              path="/compact/:connectorId/connect/:secondConnectorId?/select-account"
              element={<CompactFlowAccountSelectionPage />}
            />
            <Route element={<MainLayoutContainer />}>
              <Route path="/" element={<AuthProtectedRoute />}>
                <Route index element={<DashboardPage />} />
                <Route path=":connectorId" element={<ValidConnectFlowRouteContainer />}>
                  <Route path="connect/:secondConnectorId?" element={<ConnectFlowPage />} />
                  <Route
                    path="connect/:secondConnectorId?/select-account"
                    element={<SelectAccountPage />}
                  />
                  <Route
                    path="connect/:secondConnectorId?/summary"
                    element={<ConnectFlowSummaryPage />}
                  />
                </Route>
                <Route path="connector/:connectorId" element={<ConnectorPage />}>
                  <Route index element={<SmartNavigate to="status" replace={true} />} />
                  <Route path="status" element={<ConnectorSynchronisationPage />} />
                  <Route path="configure" element={<ConnectorConfigurationPage />} />
                </Route>
                <Route path="my-account" element={<SettingsPage />}>
                  <Route index element={<MyAccountPage />} />
                </Route>
                <Route path="error-page" element={<ErrorView />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          </Routes>
        </CompactFlowSecondConnectorPage>
      </React.StrictMode>
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>
);
