import { stringCompare } from './commonUtils';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import BallotRoundedIcon from '@mui/icons-material/BallotRounded';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import FolderCopyRoundedIcon from '@mui/icons-material/FolderCopyRounded';
import GradingRoundedIcon from '@mui/icons-material/GradingRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import SyncIcon from '@mui/icons-material/Sync';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import type {
  DatatypeOptionState,
  DatatypeOptionsChangedOnly,
  DatatypeOptionsState,
  DatatypeRelationsState,
} from 'types';
import type { Datatypes } from 'types/connector';
import type {
  DatatypeGlobalKey,
  DatatypeRelations,
  DatatypeRelationsArray,
  ModelMapping,
} from 'types/modelMapping';

export const globalDatatypeIcons = {
  accounting: CalculateRoundedIcon,
  agreement: GradingRoundedIcon,
  asset: HomeWorkRoundedIcon,
  classification: FolderCopyRoundedIcon,
  documentation: MenuBookRoundedIcon,
  event: EventRoundedIcon,
  human: GroupsRoundedIcon,
  location: LocationOnRoundedIcon,
  organisation: CorporateFareRoundedIcon,
  product: BallotRoundedIcon,
  task: AssignmentTurnedInRoundedIcon,
  default: SyncIcon,
};

export const sortGlobals = (globalA: string, globalB: string) => {
  const leastImportantGlobals = ['classification', 'location'];
  if (leastImportantGlobals.includes(globalA)) {
    return 1;
  } else if (leastImportantGlobals.includes(globalB)) {
    return -1;
  }
  return stringCompare(globalA, globalB);
};

export const getGlobalNameFromDatatype = (
  modelMapping: ModelMapping,
  system: string,
  datatype: string
) => {
  const globals = (modelMapping?.[`${system}-${datatype}`]?.globals ?? []).sort(sortGlobals);
  if (globals.length > 0) {
    return globals[0];
  }
};

export const getIconFromDatatype = (
  modelMapping: ModelMapping,
  system: string,
  datatype: string
) => {
  const global = getGlobalNameFromDatatype(modelMapping, system, datatype) ?? '';
  return get(globalDatatypeIcons, global, globalDatatypeIcons.default);
};

export const extractEntityTypeNameFromRelation = (relation: string) =>
  relation.substring(relation.indexOf('-') + 1);

export const getRelationName = (subDatatype: string, vendorDatatypes?: Datatypes) =>
  vendorDatatypes?.[subDatatype]?.label ?? capitalize(subDatatype);

export const getRelations = (
  currentConnectorId: string,
  mapsTo: DatatypeRelations
): DatatypeRelationsArray => {
  const relations = Array.isArray(mapsTo)
    ? mapsTo
    : (Object.keys(mapsTo) as DatatypeRelationsArray);
  const result = relations.filter(
    (relation: DatatypeGlobalKey) =>
      relation.toLowerCase().indexOf(currentConnectorId.toLowerCase()) === 0
  );

  return result || [];
};

export const buildGlobalKey = (connectorId: string, datatypeId: string): DatatypeGlobalKey =>
  `${connectorId.toLowerCase()}-${datatypeId.toLowerCase()}`;

/**
 * Build and return all mapped datatypes between the `primarySystem` and the current connector.
 */
export const getDatatypeRelations = (state: DatatypeRelationsState): DatatypeRelationsArray => {
  const { datatypeId, currentSystemId, primarySystem, modelMapping } = state;

  if (!primarySystem || !modelMapping) {
    return [];
  }

  const globalKey = buildGlobalKey(currentSystemId, datatypeId);
  const mapsTo = modelMapping?.[globalKey]?.maps_to ?? [];
  const globals = modelMapping?.[globalKey]?.globals ?? [];

  if (globals && globals.includes('classification') && globals.length === 1) {
    return [];
  }

  return getRelations(primarySystem, mapsTo);
};

export const getBuildDatatypeOptionsStateFn =
  (state: DatatypeOptionsState = {}) =>
  (datatypeOptions?: Datatypes): DatatypeOptionsState => {
    Object.entries(datatypeOptions || {}).forEach((option) => {
      state[option[0]] = {
        enabled: option[1].enabled ?? false,
        fullsync: option[1].fullsync ?? false,
        share_enabled: option[1].share_enabled ?? false,
      };
    });

    return state;
  };

export const getOnlyChangedDatatypes = (
  initial: DatatypeOptionsState,
  current: DatatypeOptionsState
) => {
  const shareEnabled = 'share_enabled';
  const fullSync = 'fullsync';
  const options: Array<keyof DatatypeOptionState> = [shareEnabled, fullSync];
  const initialArr = Object.entries(initial);
  const changedOnly: DatatypeOptionsChangedOnly = {};
  const initChangedOnly = (datatypeId: string) =>
    (changedOnly[datatypeId] = !changedOnly[datatypeId] ? {} : changedOnly[datatypeId]);

  initialArr.forEach((initialArrObject: [string, DatatypeOptionState]) => {
    const datatypeId = initialArrObject[0];

    options.forEach((option: keyof DatatypeOptionState) => {
      if (initial[datatypeId][option] !== current[datatypeId][option]) {
        initChangedOnly(datatypeId);

        changedOnly[datatypeId][option] = current[datatypeId][option];
      }
    });
  });

  return changedOnly;
};
