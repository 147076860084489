import ReactGA from 'react-ga4';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  APIRequestUserFormInputsValues,
  AccountSelectionTableKey,
  AccountSelectionTableType,
} from 'types';
import { UserFormInputsValues } from 'types';
import { Accounts, AdditionalProperties, Connector } from 'types/connector';
import { AccountOptions, AdvancedConnectFlowTargetElement, ConnectFlowType } from 'enums';
import { ConnectorIDs } from 'commonConstants';

/**
 * get the accountId value from the given `Accounts` object.
 */
export const getAccountId = (accounts?: Accounts) => {
  if (!accounts) {
    return;
  }

  const key = Object.keys(accounts)?.[0];

  return accounts[key]?.account_id;
};

export const getAdditionalPropertyValueFn = (connector: Connector) => (propertyKey: string) => {
  const accountsAdditionalProperties = get(connector, 'accounts');

  if (isEmpty(accountsAdditionalProperties)) {
    return;
  }

  const dynamicKey = Object.keys(accountsAdditionalProperties)?.[0];
  const elements = accountsAdditionalProperties?.[dynamicKey]?.['additional-properties'];

  return Object.entries(elements).find((additionalProperty) => {
    const key = additionalProperty[0];

    return key === propertyKey;
  })?.[1].value;
};

export const isAdditionalValuesValid = (
  additionalProperties: AdditionalProperties,
  additionalValues: AdditionalProperties
) => {
  if (isEmpty(additionalProperties)) {
    return true;
  }

  return !Object.keys(additionalProperties).some((key) => additionalValues[key].value === '');
};

export const makeFilterHiddenEntityTypes = (hiddenEntityTypes: string[]) => {
  return (datatypeId: string): boolean => {
    return !datatypeId.includes('association') && !hiddenEntityTypes.includes(datatypeId);
  };
};

export const getAPIAuthFormText = (args: {
  label?: string;
  connectorName?: string;
  connectorId?: string;
}) => {
  const { label, connectorName, connectorId } = args;
  const inputText = getApiKeyConnectorFieldName(label);

  return {
    inputText: `Enter your ${inputText}`,
    skipApiKeyText:
      connectorId === ConnectorIDs.Tripletex
        ? 'We already received your employee token from Tripletex! Click Connect to continue with the process.'
        : `We already received the ${inputText} from ${connectorName}! Click
    Connect to continue with the process.`,
  };
};

const ConnectFlowStepName = {
  TermsOfService: 'Terms of Service',
  Review: 'Review',
  ConnectTo: 'Connect to {0}',
  SelectAccount: 'Select Account',
  Summary: 'Summary',
};

export const buildStepNames = (args: {
  connectorTitle: string;
  hasVendor: boolean;
  selectAccount?: { name: string };
  customSteps?: string[];
}) => {
  const { connectorTitle, hasVendor, selectAccount, customSteps } = args;
  let steps = [ConnectFlowStepName.Review];

  if (customSteps?.length) {
    steps = [...steps, ...customSteps];
  }

  steps = [...steps, ConnectFlowStepName.ConnectTo.replace('{0}', connectorTitle)];

  if (!hasVendor) {
    steps = [ConnectFlowStepName.TermsOfService, ...steps];
  }

  if (selectAccount && selectAccount.name) {
    steps = [...steps, selectAccount.name];
  }

  return [...steps, ConnectFlowStepName.Summary];
};

type CalculateConnectFlowTypeArgs = {
  hasVendor: boolean;
  isReauthoriseFlow: boolean;
};

export const calculateConnectFlowType = (args: CalculateConnectFlowTypeArgs): ConnectFlowType => {
  const { hasVendor, isReauthoriseFlow } = args;

  if (isReauthoriseFlow) {
    return 'reauthorise';
  }

  if (hasVendor) {
    return 'vendor';
  }

  return 'noVendor';
};

export const getDocsUrlForCurrentConnector = (
  currentConnector: Connector,
  vendorConnector?: Connector
) => {
  if (!vendorConnector) {
    return currentConnector.docs_url;
  }

  const currentConnectorDocsUrl = currentConnector.docs_url;
  const vendorConnectorDocsUrl =
    vendorConnector?.vendor?.supported_connectors?.[currentConnector.id]?.docs_url;

  return vendorConnectorDocsUrl ? vendorConnectorDocsUrl : currentConnectorDocsUrl;
};

type SendGaArgs = {
  action: string;
  category?: string;
  label?: string;
};

export const getSendEventToGAFn =
  (gaEvent = ReactGA.event) =>
  (args: SendGaArgs) => {
    const { action, category, label } = args;

    gaEvent({
      action,
      category: category ?? '',
      label,
    });
  };

const getCurrentStepToSend = (waveAccountOption?: AccountOptions) =>
  waveAccountOption === AccountOptions.HaveAccount ? 'yes' : 'no';

const getCurrentStepTypeToSend = (urlToOpen: string) => (urlToOpen !== '' ? 'test' : 'login');

export const getAdvancedConnectFlowUrl = (args: {
  targetElement: AdvancedConnectFlowTargetElement;
  accountOption?: AccountOptions;
  urlToOpen?: string;
  email?: string;
}) => {
  const { targetElement, accountOption, urlToOpen = '', email = '' } = args;

  if (targetElement === AdvancedConnectFlowTargetElement.SendEmailButton && email) {
    return `authentication/yes/request/${email}`;
  }

  if (targetElement === AdvancedConnectFlowTargetElement.RadioButton) {
    return `authentication/${getCurrentStepToSend(accountOption)}`;
  }

  return `authentication/${getCurrentStepToSend(accountOption)}/${getCurrentStepTypeToSend(
    urlToOpen
  )}`;
};

export const AccountSelectionTable: {
  [key in AccountSelectionTableKey]: AccountSelectionTableType;
} = {
  [ConnectorIDs.BusinessCentral]: {
    name: 'Account Selection',
    entityName: 'account',
    entityNames: 'accounts',
  },
  [ConnectorIDs.ExactOnline]: {
    name: 'Account Selection',
    entityName: 'account',
    entityNames: 'accounts',
  },
  [ConnectorIDs.Wix]: {
    name: 'Site Selection',
    entityName: 'site',
    entityNames: 'sites',
  },
};

export const buildUserContactInfo = (
  userInfo: UserFormInputsValues,
  acceptedTOS: string
): APIRequestUserFormInputsValues => ({
  accepted_tos: acceptedTOS,
  first_name: userInfo.firstName,
  last_name: userInfo.lastName,
  company_name: userInfo.companyName,
  company_email: userInfo.companyEmail,
  company_phone: userInfo.companyPhone,
});

export const shouldDisplayCollectUserInfoForm = (localStorageFlag?: string) =>
  localStorageFlag === 'true';

export const getApiKeyConnectorFieldName = (name?: string) => (name ? name : 'API key');

export const getConnectorAuthType = (connector?: Connector) => connector?.auth?.type;
