import React from 'react';
import { CircularProgress, CircularProgressProps, Stack, Typography } from '@mui/material';

export const CircularLoading: React.FC<
  CircularProgressProps & { testId?: string; text?: string }
> = ({ size = '4rem', text, ...props }) => (
  <Stack
    sx={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}
  >
    <CircularProgress size={size} {...props} />
    <Stack mt={2}>
      <Typography fontWeight={600}>{text}</Typography>
    </Stack>
  </Stack>
);
