import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Stack } from '@mui/system';
import { Button } from 'views';
import { TestID } from 'testID';

export const TermsOfServiceForm: React.FC<{
  onChange: () => void;
  onContinue: () => void;
  onCancel: () => void;
  consentTOS: boolean;
  isTOSFormValid: boolean;
}> = ({ onChange, onContinue, onCancel, consentTOS, isTOSFormValid }) => (
  <FormGroup data-testid={TestID.TermsOfServiceForm}>
    <Stack direction="row" spacing={2}>
      <FormControlLabel
        control={
          <Checkbox
            checked={consentTOS}
            onChange={onChange}
            data-testid={TestID.AgreementCheckbox}
          />
        }
        label="I agree"
      />
      <Button
        variant="contained"
        onClick={onContinue}
        disabled={!isTOSFormValid}
        data-testid={TestID.ContinueButton}
      >
        Continue
      </Button>
      <Button onClick={onCancel}>Cancel</Button>
    </Stack>
  </FormGroup>
);
