import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { useFetchTenant } from 'hooks/useFetchTenant';
import { useScrollToPageTop } from 'hooks/useScrollToPageTop';
import { DashboardContainer } from 'containers/Dashboard.container';
import { ErrorView, FullScreenLoader } from 'views';
import { ErrorMessages } from 'commonConstants';

export const DashboardPage = () => {
  const location = useLocation();

  const {
    connectors,
    connectorsLoading,
    invalidate: invalidateConnectors,
    status: connectorsStatus,
    fetchStatus: connectorsFetchStatus,
  } = useFetchConnectors();
  const {
    tenant,
    isLoading: tenantLoading,
    invalidate: invalidateTenant,
    status: tenantStatus,
    fetchStatus: tenantFetchStatus,
  } = useFetchTenant();

  useScrollToPageTop<boolean>(connectorsLoading || tenantLoading);

  const refresh = () => {
    invalidateConnectors();
    invalidateTenant();
  };

  const statuses = [connectorsStatus, tenantStatus];
  const fetchStatuses = [connectorsFetchStatus, tenantFetchStatus];

  if (statuses.includes('pending') || fetchStatuses.includes('fetching')) {
    return <FullScreenLoader height="65vh" />;
  }

  if (!connectors) {
    return <ErrorView errorTitle={ErrorMessages.ConnectorsNotLoaded} onActionClick={refresh} />;
  }

  if (!tenant) {
    return <ErrorView errorTitle={ErrorMessages.TenantNotLoaded} onActionClick={refresh} />;
  }

  return <DashboardContainer location={location} tenantInfo={tenant} connectors={connectors} />;
};
