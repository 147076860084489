import { CompactFlowApiKeyPage } from './CompactFlowApiKey.page';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Stack } from '@mui/material';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { useFetchTenant } from 'hooks/useFetchTenant';
import { calculateCompactFlowFirstConnectorAction, getConnectorAuthType } from 'utils';
import { CompactFlowFirstConnectorContainer } from 'containers/CompactFlowFirstConnector.container';
import { FullScreenLoader } from 'views';
import { TestID } from 'testID';
import { CompactFlowActions } from 'enums';

const CompactFlowFirstConnectorPage: React.FC = () => {
  const { connectors, status: fetchConnectorsStatus } = useFetchConnectors();
  const { tenant, status: fetchTenantStatus } = useFetchTenant();
  const params = useParams();
  const firstConnectorId = params.connectorId ?? '';
  const connectorType = getConnectorAuthType(connectors?.[firstConnectorId]);

  const compactFlowAction = calculateCompactFlowFirstConnectorAction({
    fetchTenantStatus,
    fetchConnectorsStatus,
    tenant,
    connectorType: connectorType ?? 'none',
    connectors: connectors ?? {},
  });

  if (compactFlowAction === CompactFlowActions.DisplayLoader) {
    return <FullScreenLoader testId={TestID.CompactOnboardingFlowPage} />;
  }

  if (compactFlowAction === CompactFlowActions.NavigateToHomepage) {
    return <Navigate to="/" replace={true} />;
  }

  if (compactFlowAction === CompactFlowActions.StartApiKeyCompactFlow) {
    return (
      <Stack justifyContent="center" alignItems="center" sx={{ height: '80vh' }}>
        <Stack sx={{ width: '50%' }}>
          {connectors?.[firstConnectorId] && (
            <CompactFlowApiKeyPage connector={connectors?.[firstConnectorId]} />
          )}
        </Stack>
      </Stack>
    );
  }

  if (compactFlowAction === CompactFlowActions.StartOAuth2CompactFlow && tenant) {
    return <CompactFlowFirstConnectorContainer tenant={tenant} />;
  }

  return <FullScreenLoader testId={TestID.CompactOnboardingFlowPage} />;
};

const Wrapped = withAuthenticationRequired(CompactFlowFirstConnectorPage, {
  onRedirecting: () => <FullScreenLoader />,
  returnTo: window.location.pathname.replace('/onboarding', '') + window.location.search,
});

export { Wrapped as CompactFlowFirstConnectorPage };
