import { getSystemStatus } from './useSystemStatus';
import { shouldDisplayInitialSystemStatus } from 'utils';
import { SystemState } from 'types';
import { SystemStatus } from 'enums';

export const useInitialSystemStatus = (args: {
  isAnHourPastSinceConnection: boolean;
  systemStatus: SystemStatus;
  systemState: SystemState;
}) =>
  shouldDisplayInitialSystemStatus(args.isAnHourPastSinceConnection, args.systemStatus)
    ? getSystemStatus(SystemStatus.InitialisingSystem)
    : args.systemState;
