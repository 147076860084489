import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AlertColor } from '@mui/material';
import { useConnectFlowStepper } from 'hooks/useConnectFlowStepper';
import { useScrollToPageTop } from 'hooks/useScrollToPageTop';
import { useTOSAccepted } from 'hooks/useTOSAccepted';
import {
  AccountSelectionTable,
  buildUserContactInfo,
  getAccountId,
  getApiKeyConnectorFieldName,
  getSendEventToGAFn,
  logger,
} from 'utils';
import { connectorLogin, oauth2Login, postConnectorAccountInfo } from 'api';
import { connectFlowStepsSignal } from 'signals/connectFlowSteps.signal';
import { snackbarSignal } from 'signals/snackbars.signal';
import { ConnectAuthStepContainer } from 'containers/ConnectAuthStep.container';
import { ConnectFlowInfoContainer } from 'containers/ConnectFlowInfo.container';
import { ConnectFlowPageWrapperContainer } from 'containers/ConnectFlowPageWrapper.container';
import { ConnectFlowTermsOfServiceContainer } from 'containers/ConnectFlowTermsOfService.container';
import { useContextVendor } from 'containers/ValidConnectFlowRoute.container';
import { ConfirmApiKeyDialog } from 'views';
import { TestID } from 'testID';
import { AccountSelectionTableKey, UserFormInputsValues } from 'types';
import { AdditionalProperties } from 'types/connector';
import { ConnectorAccountInfoResponse } from 'types/connectorAccountInfo';
import { ConnectFlowSteps } from 'enums';
import { TOS_ACCEPT_LOCAL_STORAGE_KEY } from 'commonConstants';

const sendEventToGA = getSendEventToGAFn();
const isOAuth2ConnectInProgress = { current: false };

export const ConnectFlowPage: React.FC = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const { secondConnectorId } = useParams();
  const [searchParams] = useSearchParams();
  const tosAccepted = useTOSAccepted();
  const { connectors, vendor, tenant: tenantInfo, connectorId } = useContextVendor();
  const skipApiKey = !!searchParams.get('skipApiKey');
  const hasVendor = Boolean(tenantInfo?.vendor_info);
  const vendorId = tenantInfo?.vendor_config?._id;
  const vendorTitle = tenantInfo?.vendor_config?.title;
  const [isTOSFormValid, setIsTOSFormValid] = useState(false);
  const [showScreenLoader, setShowScreenLoader] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [openConfirmApiKeyDialog, setOpenConfirmApiKeyDialog] = useState(false);
  const [maskedApiKey, setMaskedApiKey] = useState<string>();
  const [apiKeyArgs, setApiKeyArgs] = useState<{
    apiKey: string;
    additionalValues: AdditionalProperties;
  }>({ apiKey: '', additionalValues: {} });
  const { getAccessTokenSilently } = useAuth0();
  const isReauthoriseFlow = location?.state?.isReauthoriseFlow;
  const connector = connectors[connectorId || ''];
  const tenantConnector = connectors[tenantInfo?.vendor_config?._id || ''];
  const vendorIcon =
    connectors[tenantConnector?.id]?.logo_symbol ?? connectors[tenantConnector?.id]?.logo;
  const connectorType = connector?.auth?.type;
  const isConnected = connector?.connected;
  const currentVendor = hasVendor ? connectors[vendorId || '']?.vendor : vendor;
  const shouldSelectAccount = !!connector?.account_selection;
  const [userFormInputValues, setUserFormInputValues] = useState<UserFormInputsValues>({
    firstName: user?.given_name ?? '',
    lastName: user?.family_name ?? '',
    companyName: '',
    companyEmail: user?.email ?? '',
    companyPhone: user?.phone_number ?? '',
  });

  const connectFlowStepper = useConnectFlowStepper(hasVendor, isReauthoriseFlow, tosAccepted);

  const initialStep = useMemo(() => connectFlowStepper().getNextStep(), [connectFlowStepper]);

  const [currentStep, setCurrentStep] = useState<ConnectFlowSteps>(initialStep);

  useEffect(() => {
    connectFlowStepsSignal.emit(currentStep);
  }, [currentStep]);

  useScrollToPageTop(currentStep);

  useEffect(() => () => connectFlowStepper().resetState(), [connectFlowStepper]);

  useEffect(() => {
    if (isConnected && !isReauthoriseFlow) {
      navigate(`/connector/${connectorId}`);
    }
  }, [connectorId, isConnected, isReauthoriseFlow, navigate]);

  const resetIsOAuth2ConnectInProgressFlag = () => (isOAuth2ConnectInProgress.current = false);

  const storeTOSAccepted = () => {
    if (isTOSFormValid) {
      localStorage.setItem(TOS_ACCEPT_LOCAL_STORAGE_KEY, 'true');
    }
  };

  const getContactInfo = () =>
    tosAccepted ? undefined : buildUserContactInfo(userFormInputValues, new Date().toISOString());

  const onOAuth2Connect = (additionalValues: AdditionalProperties) => {
    if (isOAuth2ConnectInProgress.current === true) {
      return;
    }

    sendEventToGA({
      action: `user clicked on connect to ${connectorId} button`,
      category: 'connect step',
      label: 'Connect flow',
    });

    isOAuth2ConnectInProgress.current = true;

    setShowScreenLoader(true);

    const accountId = isReauthoriseFlow && getAccountId(connector?.accounts);

    getAccessTokenSilently({
      // we need to use getAccessTokenSilently here
      authorizationParams: {
        scope: 'write:connectors',
      },
    }).then(async (token) => {
      let redirectPath = '/';
      if (secondConnectorId) {
        redirectPath = `?resume-signup-flow=${secondConnectorId}`;
      }

      return await oauth2Login({
        connectorId,
        token,
        redirectPath,
        accountId,
        additionalValues,
        contactInfo: getContactInfo(),
        override: isReauthoriseFlow,
      })
        .then(() => {
          storeTOSAccepted();
          resetIsOAuth2ConnectInProgressFlag();
        })
        .catch(() => {
          resetIsOAuth2ConnectInProgressFlag();
          setShowScreenLoader(false);
        });
    });
  };

  const connectAPIKeyConnector = async (apiKey: string, additionalValues: AdditionalProperties) => {
    setShowButtonLoader(true);
    const accountId = isReauthoriseFlow && getAccountId(connector?.accounts);

    sendEventToGA({
      action: `user clicked on connect to ${connectorId} button`,
      category: 'connect step',
      label: 'Connect flow',
    });

    let status: AlertColor = 'success';

    let nextConnector: string | undefined;
    if (secondConnectorId) {
      nextConnector = secondConnectorId;
    }

    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          scope: 'write:connectors',
        },
      });

      const response = await connectorLogin(
        {
          connectorId,
          type: connectorType,
          apiKey,
          additionalValues,
          accountId,
          nextConnector,
          contactInfo: getContactInfo(),
          override: isReauthoriseFlow,
        },
        token
      );

      if (response.status !== 200) {
        status = 'error';
      }

      const data = await response.json();

      storeTOSAccepted();
      setShowButtonLoader(false);

      if (!shouldSelectAccount) {
        showSnackMessage(data.message, status);
      }

      if (status !== 'error') {
        const nextStep = connectFlowStepper().getNextStep(currentStep);
        setCurrentStep(nextStep);

        if (shouldSelectAccount) {
          navigate(`/${connectorId}/connect/select-account`, {
            state: {
              apiKey,
            },
          });
        } else if (!hasVendor) {
          connectFlowStepsSignal.emit(ConnectFlowSteps.Summary);
          navigate('/');
        }
      }
    } catch (e) {
      logger.error('An error occurred when trying to connect to the server.', e);
    } finally {
      setShowButtonLoader(false);
    }
  };

  const onApiKeyConnect = async (apiKey: string, additionalValues: AdditionalProperties) => {
    setApiKeyArgs({
      apiKey,
      additionalValues,
    });
    setShowButtonLoader(true);

    sendEventToGA({
      action: `user clicked on connect to ${connectorId} button`,
      category: 'connect step',
      label: 'Connect flow',
    });

    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          scope: 'write:connectors',
        },
      });

      if (isReauthoriseFlow) {
        const accountInfoResponse = await postConnectorAccountInfo(token, connectorId, apiKey);

        if (accountInfoResponse?.status !== 200) {
          throw Error('account info request failed.');
        }

        const accountInfoResult: ConnectorAccountInfoResponse = await accountInfoResponse.json();

        if (accountInfoResult?.masked_api_key) {
          setMaskedApiKey(accountInfoResult.masked_api_key);
          setOpenConfirmApiKeyDialog(true);
        }
      } else {
        await connectAPIKeyConnector(apiKey, additionalValues);
      }
    } catch (e) {
      logger.error('An error occurred when trying to connect to the server.', e);
    } finally {
      setShowButtonLoader(false);
    }
  };

  const onContinueClick = () => {
    const nextStep = connectFlowStepper().getNextStep(currentStep);
    setCurrentStep(nextStep);
  };

  const showSnackMessage = (message: string, severity: AlertColor) => {
    snackbarSignal.emit({
      message,
      severity,
      open: true,
      testId: TestID.SnackbarConnectedSuccessfullySuccessMessage,
    });
  };

  const handleCloseConfirmApiKeyDialog = () => {
    setOpenConfirmApiKeyDialog(false);
  };

  const handleConfirmApiKeyDialog = async () => {
    await connectAPIKeyConnector(apiKeyArgs.apiKey, apiKeyArgs.additionalValues);
  };

  const connectorTitle = connector?.title;
  const accountSelectionTitle = shouldSelectAccount
    ? AccountSelectionTable[connectorId as AccountSelectionTableKey]?.name
    : '';

  return (
    <ConnectFlowPageWrapperContainer
      step={connectFlowStepper().getStepIndex()}
      connectorTitle={connectorTitle}
      hasVendor={hasVendor}
      showScreenLoader={showScreenLoader}
      selectAccount={{ name: accountSelectionTitle }}
    >
      {currentStep === ConnectFlowSteps.TermsOfService && (
        <ConnectFlowTermsOfServiceContainer
          banner={connector?.connectFlow?.banner ?? currentVendor?.banner}
          onContinue={onContinueClick}
          connectorId={connector?.id}
          isTOSFormValid={isTOSFormValid}
          setIsTOSFormValid={setIsTOSFormValid}
          userFormInputValues={userFormInputValues}
          setUserFormInputValues={setUserFormInputValues}
        />
      )}
      {currentStep === ConnectFlowSteps.InformationReview && (
        <ConnectFlowInfoContainer
          onConnect={onContinueClick}
          connectors={connectors}
          hasVendor={hasVendor}
          vendorId={vendorId}
          vendorTitle={vendorTitle}
          vendorIcon={vendorIcon}
          connectorType={connectorType}
        />
      )}
      {currentStep === ConnectFlowSteps.Authentication && (
        <ConnectAuthStepContainer
          onApiKeyConnect={onApiKeyConnect}
          onOAuth2Connect={onOAuth2Connect}
          skipApiKey={skipApiKey}
          isReauthoriseFlow={isReauthoriseFlow}
          connector={connector}
          vendorTitle={connector?.connectFlow?.title ?? currentVendor?.title}
          hasVendor={hasVendor}
          connectorType={connectorType}
          showButtonLoader={showButtonLoader}
          shouldSelectAccount={shouldSelectAccount}
          vendorId={vendorId}
        />
      )}
      {currentStep === ConnectFlowSteps.Summary && (
        <Navigate to={`/${connectorId}/connect/summary`} />
      )}
      {maskedApiKey && (
        <ConfirmApiKeyDialog
          open={openConfirmApiKeyDialog}
          maskedApiKey={maskedApiKey}
          loading={showButtonLoader}
          handleClose={handleCloseConfirmApiKeyDialog}
          handleConfirm={handleConfirmApiKeyDialog}
          fieldName={getApiKeyConnectorFieldName(connector?.auth?.name)}
        />
      )}
    </ConnectFlowPageWrapperContainer>
  );
};
