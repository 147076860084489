export const ConnectorIDs = {
  Asana: 'asana',
  BusinessCentral: 'businesscentral',
  ExactOnline: 'exactonline',
  Freshteam: 'freshteam',
  HubSpot: 'hubspot',
  OpenSesam: 'opensesam',
  PowerOfficeGo: 'powerofficego',
  SuperOffice: 'superoffice',
  SuperOfficeTest: 'superoffice-test',
  Tripletex: 'tripletex',
  TripletexProd: 'tripletex-prod',
  UniEconomy: 'unieconomy',
  Wave: 'wave',
  Wix: 'wix',
  YouTrack: 'youtrack',
  Zendesk: 'zendesk',
  ZohoCRM: 'zohocrm',
} as const;

export const SesamTalkLinks = {
  Sesam: 'https://sesam.io',
  SesamTalk: 'https://info.sesam.io/talk',
  SesamTalkSupport: 'https://support.talk.sesam.io/',
  SesamTalkDocs: 'https://docs.sesam.io/talk',
  SesamTalkDocsEntityTypes: 'https://docs.sesam.io/talk/entity-types/index.html',
} as const;

export const WaveSiteLinks = {
  SetUpWaveWithATestAccount:
    'https://www.wave.sesam.io/post/set-up-making-wave-talk-with-a-test-account',
  SimpleGuideToGettingStartedWithWave:
    'https://www.wave.sesam.io/post/a-simple-guide-to-getting-started-with-wave',
} as const;

export const ErrorMessages = {
  ConnectorIdNotFound: 'Service id was not found in the list of provided parameters.',
  ConnectorsNotLoaded: 'Failed to load systems.',
  TenantNotLoaded: 'Failed to load the tenant info.',
  ModelMappingNotLoaded: 'Failed to load model mapping data.',
} as const;

export const TOS_ACCEPT_LOCAL_STORAGE_KEY = '_acceptTOS_';

export const TOS_ACCEPT_LOCAL_QUERY_STRING = 'tos_accepted';

export const DISPLAY_COLLECT_USER_FORM_LOCAL_STORAGE_KEY = '_display_CUIF_';
