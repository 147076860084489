import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { calculateAddConnectorCardMode } from 'utils';
import { DashboardCardsContainer } from './DashboardCards.container';
import { AddConnectorCard, DashboardCardsWrapper } from 'views';
import { TestID } from 'testID';
import { Connector, Connectors } from 'types/connector';
import { AddConnectorCardMode } from 'enums';

const AppHeaderHeight = 100;

export const AddConnectorCardContainer: React.FC<{
  connectors: Connectors;
  connectedConnectors: Connector[];
  secondConnectorId?: string;
  tenantId?: string;
}> = ({ connectors, tenantId, secondConnectorId, connectedConnectors }) => {
  const navigate = useNavigate();
  const centralConnector = connectors[tenantId || ''];
  const centralConnectorName = centralConnector?.title;
  const nextConnector = connectors[secondConnectorId || ''];
  const nextConnectorName = nextConnector?.title;
  const nextConnectorLogo = nextConnector?.logo;

  const addConnectorCardMode = calculateAddConnectorCardMode({
    centralConnectorName,
    nextConnectorName,
    nextConnectorLogo,
    alreadyConnectedCount: connectedConnectors.length,
  });

  const scrollToAnchor = (anchorId: string) => {
    const domRect = document.getElementById(anchorId)?.getBoundingClientRect();

    if (domRect) {
      window?.scrollTo({ top: domRect.top - AppHeaderHeight, behavior: 'smooth' });
    }
  };

  return (
    <DashboardCardsWrapper>
      <DashboardCardsContainer
        connectors={connectedConnectors}
        tenantId={tenantId}
        testId={
          connectedConnectors.length === 1
            ? TestID.CentralConnectedConnector
            : TestID.ConnectedSystemsCard
        }
      />
      <Grid item>
        <AddConnectorCard
          addConnectorCardMode={addConnectorCardMode}
          centralConnectorName={centralConnectorName}
          nextConnectorLogo={nextConnectorLogo}
          nextConnectorName={nextConnector?.title}
          onActionClick={
            addConnectorCardMode === AddConnectorCardMode.NextKnownConnector
              ? () => navigate(`/${nextConnector.id}/connect`)
              : () => scrollToAnchor(TestID.AvailableSystemsSection)
          }
        />
      </Grid>
    </DashboardCardsWrapper>
  );
};
