import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import { Stack, Typography } from '@mui/material';
import { TestID } from 'testID';
import { lightTheme } from 'themes';

export const SyncPageVendorText: React.FC<{ connectorTitle: string }> = ({ connectorTitle }) => (
  <Stack direction="row">
    <StarIcon sx={{ color: lightTheme.palette.warning.main }} />
    <Typography variant="body1" data-testid={TestID.SynchronisationPageVendorText} mb={4} ml={1}>
      {connectorTitle} is your central system. It syncs data like your other connected systems, and
      doesn't act as master for your data.
    </Typography>
  </Stack>
);
