import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AlertColor, Stack } from '@mui/material';
import { getTimeSince, logger, shouldDisplayStartFresh } from 'utils';
import { deleteAccount } from 'api';
import { snackbarSignal } from 'signals/snackbars.signal';
import {
  ContactEmailSection,
  GeneralSettingsSection,
  PageTitle,
  ProfileSection,
  StartFreshDialog,
  StartFreshSection,
} from 'views';
import { TestID } from 'testID';
import { Connectors } from 'types/connector';
import { Tenant } from 'types/tenant';

export const MyAccount: React.FC<{
  connectors: Connectors;
  tenant: Tenant;
}> = ({ tenant, connectors }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const vendorId = tenant?.vendor_config?._id;
  const tenantInternalId = tenant._id;
  const accountId = tenant?.vendor_info?.account_id;
  const configuredByEmail =
    connectors?.[vendorId || '']?.accounts?.[accountId || '']?.audit?.configured_by?.user_email;
  const vendorTitle = connectors[vendorId]?.vendor?.title;
  const tenantTitle = tenant?.vendor_config?.title;
  const timestamp = connectors?.[vendorId]?.accounts?.[accountId]?.audit?.configured_by?.timestamp;
  const since = getTimeSince(timestamp);

  const handleDeleteAccount = () => {
    setLoading(true);

    getAccessTokenSilently({
      authorizationParams: {
        scope: 'write:connectors',
      },
    })
      .then((token) => deleteAccount(token))
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response?.statusText);
        }

        showSnackMessage(
          'You can now start fresh!',
          'success',
          TestID.StartFreshSuccessfullySnackbarMessage
        );
        navigate('/');
      })
      .catch((e) => {
        logger.error(e);
        showSnackMessage(
          'Failed to delete the user account!',
          'error',
          TestID.StartFreshErrorSnackbarMessage
        );
      })
      .finally(() => setLoading(false));
  };

  const showSnackMessage = (message: string, severity: AlertColor, testId: string) => {
    snackbarSignal.emit({
      message,
      severity,
      open: true,
      testId,
    });
  };

  return (
    <>
      <PageTitle gutterBottom mb={4}>
        My Account
      </PageTitle>
      <ProfileSection
        userName={user?.name}
        email={user?.email}
        since={since}
        timestamp={timestamp}
        vendorId={vendorId}
        tenantTitle={tenantTitle}
      />
      {tenantInternalId && (
        <Stack data-testid={TestID.TenantSettingsSections}>
          <GeneralSettingsSection
            configuredByEmail={configuredByEmail}
            vendorId={vendorId}
            vendorTitle={vendorTitle}
            tenantInternalId={tenantInternalId}
          />
          <ContactEmailSection email={user?.email} />
          {shouldDisplayStartFresh({ nodeEnv: process.env.NODE_ENV, userEmail: user?.email }) && (
            <StartFreshSection onClick={() => setModalOpen(true)} />
          )}
          <StartFreshDialog
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
            handleConfirm={() => handleDeleteAccount()}
            loading={loading}
          />
        </Stack>
      )}
    </>
  );
};
