import React from 'react';
import { NotificationMessageType } from 'types';
import { SystemStatusIndicator } from './SystemStatusIndicator.view';
import { NotificationMessage } from '../NotificationMessage/NotificationMessage.view';

export const DesignedSystemStatusIndicator: React.FC<
  React.ComponentProps<typeof SystemStatusIndicator> & {
    notificationType: NotificationMessageType;
  }
> = ({ notificationType, ...props }) => (
  <NotificationMessage notificationType={notificationType} sx={{ padding: '4px 12px' }}>
    <SystemStatusIndicator {...props} />
  </NotificationMessage>
);
