import React, { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import { CustomSectionsContainer } from 'containers/CustomSections.container';
import { Section } from 'views/Section/Section.view';
import { TestID } from 'testID';
import { ConnectFlowCustomSection, ConnectorType } from 'types/connector';
import { Link } from '../Link/Link.view';

export const ConnectFlowReviewSections: React.FC<
  PropsWithChildren<{
    hasVendor: boolean;
    connectorTitle: string;
    vendorTitle?: string;
    connectorType: ConnectorType;
    customSections?: ConnectFlowCustomSection[];
    documentationUrl?: string;
  }>
> = ({
  hasVendor,
  customSections,
  connectorType,
  connectorTitle,
  documentationUrl,
  vendorTitle,
  children,
}) => (
  <>
    <Typography mb={4} gutterBottom>
      {hasVendor
        ? `You are about to connect your ${connectorTitle} account to ${vendorTitle}.`
        : `You are about to connect your ${connectorTitle} account.`}
    </Typography>
    {connectorType === 'oauth2' && (
      <CustomSectionsContainer customSections={customSections} showBorder />
    )}
    <Section header="Your data is secure" showBorder>
      <Typography variant="body1">
        At Sesam.io, we prioritize the safeguarding of your data. Our{' '}
        <Link
          to="https://docs.sesam.io/talk/enhanced-data-security/index.html"
          target="_blank"
          data-testid={TestID.ISOCertificationUrl}
        >
          ISO/IEC 27001 certification
        </Link>
        is a testament to our commitment to your data safety and security across all facets of our
        operations.
      </Typography>
    </Section>
    {hasVendor && (
      <Section header="Selective data synchronisation" showBorder>
        <Typography variant="body1">
          As soon as you connect your {connectorTitle} account we create a synchronisation
          automation towards {vendorTitle}. But the systems will not exchange data until there are
          actual changes in either system.
        </Typography>
      </Section>
    )}
    <Section header="Only sync your most valuable data" showBorder>
      <Typography variant="body1">
        We synchronise your most valuable data, ensuring its seamless flow across all your systems,
        and simplifying the integration process.
      </Typography>
      {children}
    </Section>
    <Section header="Automatic deletions" showBorder>
      <Typography variant="subtitle2">Unavailable for {connectorTitle}</Typography>
      <Typography variant="body1">
        We do not perform automatic deletion of data. If you need to delete data from{' '}
        {connectorTitle}, please refer to their documentation.
      </Typography>
    </Section>
    {documentationUrl && (
      <Section header="Documentation" showBorder data-testid={TestID.DocumentationSection}>
        <Typography variant="body1" component="span">
          Read documentation for{' '}
          <Link to={documentationUrl} target="_blank" data-testid={TestID.DocsUrl}>
            frequently asked questions
          </Link>{' '}
          and more.
        </Typography>
      </Section>
    )}
  </>
);
