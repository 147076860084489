import { useEffect } from 'react';

export const useUnreadMessagesTitle = (
  setHaveRed: (value: React.SetStateAction<boolean>) => void,
  originalDocumentTitle: string,
  unreadMessages?: number
) => {
  useEffect(() => {
    setHaveRed(false);

    if (unreadMessages && unreadMessages > 0) {
      document.title = `(${unreadMessages}) ${document.title}`;
    } else {
      document.title = originalDocumentTitle;
    }
  }, [setHaveRed, unreadMessages, originalDocumentTitle]);
};
