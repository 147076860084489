import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { TestID } from 'testID';
import { Button } from '../Button/Button.view';
import { Section } from '../Section/Section.view';

export const NotWaveAdministratorSection: React.FC<{
  email: string;
  isDisabled: boolean;
  sendActivateButtonText: string;
  setEmail: (value: React.SetStateAction<string>) => void;
  sendEmailPageViewEvent: () => void;
}> = ({ email, setEmail, isDisabled, sendEmailPageViewEvent, sendActivateButtonText }) => (
  <Section
    showBorder
    header="Not a Wave administrator?"
    data-testid={TestID.WaveAdvanceConnectFlowNotWaveAdministratorSection}
  >
    <Typography>
      To link our service, you must have admin access to your Wave account. If you're not the admin,
      don't worry - we can help! Just provide us with the email address of your company's Wave
      Administrator, and we'll send them an activation request on your behalf.
    </Typography>
    <TextField
      margin="normal"
      onChange={(e) => setEmail(e.target.value)}
      id="emailaddress"
      label="Email"
      type="Email"
      variant="outlined"
      value={email}
      disabled={isDisabled}
    />
    <Box sx={{ display: 'block', mt: 1 }}>
      <Button
        variant="contained"
        fullWidth={false}
        sx={{ display: 'block' }}
        onClick={sendEmailPageViewEvent}
        disabled={isDisabled}
        data-testid={TestID.WaveAdvanceConnectFlowSendActivateButtonButton}
      >
        {sendActivateButtonText}
      </Button>
    </Box>
  </Section>
);
