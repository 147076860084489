import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import ReportIcon from '@mui/icons-material/Report';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { TestID } from 'testID';
import { NotificationSeverity } from 'types/notifications';
import { lightTheme } from 'themes';
import styles from './SystemNotifications.module.scss';

const getSeverityIcon = (severity: NotificationSeverity) => {
  const testId = `${TestID.SystemNotificationSeverityIcon}-${severity}`;

  switch (severity) {
    case 'error':
      return <ReportProblemIcon color={severity} data-testid={testId} />;
    case 'warning':
      return <ReportIcon color={severity} data-testid={testId} />;
    case 'success':
      return <CheckIcon color={severity} data-testid={testId} />;
    default:
      return <InfoIcon color={severity} data-testid={testId} />;
  }
};

export const SystemNotificationsPanelItem: React.FC<
  PropsWithChildren<{
    dateTime: string;
    id: string;
    since: string;
    title?: string;
    read: boolean;
    notificationId: string;
    severity?: NotificationSeverity;
    onHasReadToggle?: (notificationId: string, read: boolean) => void;
  }>
> = ({
  children,
  dateTime,
  id,
  notificationId,
  read,
  onHasReadToggle,
  since,
  severity = 'info',
  title,
}) => (
  <MenuItem
    sx={{
      backgroundColor: lightTheme.palette.primary.light,
      color: lightTheme.palette.text.primary,
      py: 2,
    }}
    className={cx(styles.panelItem, read && styles.read)}
    data-testid={TestID.SystemNotificationsPanelItem}
    id={id}
  >
    <Stack direction="column" sx={{ width: '100%' }}>
      <Stack justifyContent="space-between" alignItems="center" direction="row" pb={1}>
        <Stack justifyContent="flex-start" alignItems="center" direction="row">
          {getSeverityIcon(severity)}
          {title && (
            <Typography variant="h6" sx={{ marginLeft: 1 }} fontWeight={600} fontSize="1.1rem">
              {title}
            </Typography>
          )}
        </Stack>
        <Stack></Stack>
      </Stack>
      <Stack>
        <Typography variant="body1">{children}</Typography>
      </Stack>
      <Stack mt={1} direction="row" alignItems="center" justifyContent="space-between">
        <Stack sx={{ fontSize: '24px' }}>
          {read ? (
            <Button
              title={`${read ? 'Marked' : 'Click to mark'} as read`}
              onClick={read ? undefined : () => onHasReadToggle?.(notificationId, !read)}
              data-testid={TestID.SystemNotificationUnreadIndicator}
              disabled={true}
              size="small"
            >
              <Stack direction="row" alignItems="center">
                <CheckCircleOutlineIcon fontSize="inherit" sx={{ marginRight: '4px' }} />
                <Typography variant="caption">Read</Typography>
              </Stack>
            </Button>
          ) : (
            <Button
              title={`${read ? 'Marked' : 'Click to mark'} as read`}
              onClick={read ? undefined : () => onHasReadToggle?.(notificationId, !read)}
              data-testid={TestID.SystemNotificationMarkAsReadButton}
              color="inherit"
              size="small"
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ color: lightTheme.palette.text.primary }}
              >
                <CheckCircleIcon fontSize="inherit" sx={{ marginRight: '4px' }} />
                <Typography variant="caption">Unread</Typography>
              </Stack>
            </Button>
          )}
        </Stack>
        <Typography
          variant="caption"
          title={dateTime}
          data-testid={TestID.SystemNotificationMessageDate}
        >
          {since}
        </Typography>
      </Stack>
    </Stack>
  </MenuItem>
);
