import isEmpty from 'lodash/isEmpty';
import { ModelMapping } from 'types/modelMapping';

export const getDataTypesFromModelMapping = (modelMapping?: ModelMapping, connectorId?: string) => {
  if (isEmpty(modelMapping) || !connectorId) {
    return [];
  }

  return Object.keys(modelMapping)
    .map((entityType) => entityType.split('-'))
    .filter((entityType) => entityType[0].toLowerCase() === connectorId)
    .map((modelName) => {
      let entityType = '';

      modelName.forEach((name, i) => {
        if (i !== 0) {
          entityType += `${name} `;
        }
      });

      return entityType.trimEnd();
    });
};
