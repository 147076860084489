import { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { FailedEntities, SyncedEntities } from 'types';
import { Datatypes, RuntimeInfo } from 'types/connector';
import { ModelMapping } from 'types/modelMapping';

type SystemDatatypesState = {
  datatypes: Datatypes | undefined;
  availableDatatypes: Datatypes;
  modelMapping: ModelMapping;
  baseConnectorId: string;
  runtimeInfo: RuntimeInfo | undefined;
};

export const useSystemDatatypes = (systemDatatypesState: SystemDatatypesState) => {
  const { datatypes, availableDatatypes, modelMapping, baseConnectorId, runtimeInfo } =
    systemDatatypesState;

  return useMemo(() => {
    const syncedEntities: SyncedEntities = {};
    const failedEntities: FailedEntities = {};
    const datatypeOptions: Datatypes = {};
    const filteredAvailableDatatypes = cloneDeep(availableDatatypes);

    if (availableDatatypes && modelMapping) {
      const modelMappingKeys = Object.keys(modelMapping);
      Object.keys(availableDatatypes).forEach((key) => {
        const modelKey = `${baseConnectorId}-${key}`;
        if (modelMappingKeys.includes(modelKey)) {
          syncedEntities[key] = runtimeInfo?.[key]?.['synced_entities'] ?? 0;
          failedEntities[key] = runtimeInfo?.[key]?.['failed_entities'] ?? 0;
          datatypeOptions[key] = datatypes?.[key] ?? {};
        } else {
          delete filteredAvailableDatatypes[key];
        }
      });
    }

    return { filteredAvailableDatatypes, syncedEntities, failedEntities, datatypeOptions };
  }, [datatypes, availableDatatypes, modelMapping, baseConnectorId, runtimeInfo]);
};
