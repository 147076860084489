import React, { useEffect, useState } from 'react';
import { FormGroup, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useBuildAdditionalProperties } from 'hooks/useBuildAdditionalProperties';
import { useNavigateToPreviousPage } from 'hooks/useNavigateToPreviousPage';
import {
  getAdditionalPropertyValueFn,
  getSystemConnectorUrl,
  isAdditionalValuesValid,
} from 'utils';
import { ConnectAccountWizardContainer } from './ConnectAccountWizard.container';
import {
  AdditionalPropertiesInputs,
  CircularLoading,
  ConnectFlowButtons,
  ConnectorAuthorisationSectionHeader,
  ConnectorDetails,
} from 'views';
import { AdditionalProperties, Connector } from 'types/connector';
import { ConnectorIDs } from 'commonConstants';

export const ConnectOauth2Container: React.FC<{
  connector: Connector;
  isReauthoriseFlow: boolean;
  onConnect: (onConnectArgs: AdditionalProperties) => void;
  hasVendor: boolean;
  vendorTitle?: string;
  shouldSelectAccount?: boolean;
  vendorId?: string;
}> = ({
  connector,
  isReauthoriseFlow,
  shouldSelectAccount = false,
  onConnect,
  hasVendor,
  vendorTitle,
  vendorId,
}) => {
  const additionalProperties = get(connector, 'additional-properties');
  const connectorLogo = connector?.logo_symbol ?? connector.logo;
  const [additionalValues, setAdditionalValues] = useState({});
  const [shouldDisable, setShouldDisable] = useState(false);
  const [firstBlur, setFirstBlur] = useState(false);
  const getAdditionalPropertyValue = getAdditionalPropertyValueFn(connector);
  const shouldDisplayAdditionalProps = !isEmpty(additionalProperties);
  const displayAdditionalPropertiesForm = !isReauthoriseFlow && shouldDisplayAdditionalProps;
  const readOnlyAdditionalValues = useBuildAdditionalProperties(
    isReauthoriseFlow,
    additionalProperties,
    getAdditionalPropertyValue
  );
  const navigateToPreviousPage = useNavigateToPreviousPage(vendorId);
  const isWave = connector.id === ConnectorIDs.Wave;

  useEffect(() => {
    if (isWave) {
      return;
    }

    if (!displayAdditionalPropertiesForm || shouldSelectAccount) {
      onConnect(readOnlyAdditionalValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- should be called only when component mounted
  }, []);

  const isFormValid = () => {
    // TODO:

    if (isReauthoriseFlow) {
      return isAdditionalValuesValid(additionalProperties, readOnlyAdditionalValues);
    }

    for (const key in additionalProperties) {
      if (!get(additionalValues, `${key}.value`)) {
        return false;
      }
    }

    return true;
  };

  const connect = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    setShouldDisable(true);
    onConnect(isReauthoriseFlow ? readOnlyAdditionalValues : additionalValues);
  };

  if (!displayAdditionalPropertiesForm && !isWave) {
    return <CircularLoading />;
  }

  return (
    <Stack>
      <ConnectorDetails
        connectorTitle={connector?.title}
        connectorLogo={connectorLogo}
        vendorTitle={vendorTitle}
        hasVendor={hasVendor}
        hideSubTitle={isWave}
      />
      {shouldDisplayAdditionalProps && (
        <ConnectorAuthorisationSectionHeader
          linkToDocumentation={
            connector.docs_url ? connector.docs_url : getSystemConnectorUrl(connector._id)
          }
          connectorTitle={connector?.title}
        />
      )}
      {isWave && (
        <ConnectAccountWizardContainer
          onConnect={connect}
          isConnectButtonDisabled={shouldDisable}
          connectorId={connector.id}
        />
      )}
      <form>
        <FormGroup>
          {shouldDisplayAdditionalProps && (
            <>
              <Typography variant="body1" gutterBottom>
                We already received your API key from {connector.title}! Click Connect to continue
                with the process.
              </Typography>
              <AdditionalPropertiesInputs
                additionalProperties={additionalProperties}
                additionalValues={additionalValues}
                getAdditionalPropertyValue={getAdditionalPropertyValue}
                isReauthoriseFlow={isReauthoriseFlow}
                firstBlur={firstBlur}
                setFirstBlur={setFirstBlur}
                setAdditionalValues={setAdditionalValues}
                shouldDisable={shouldDisable}
              />
            </>
          )}
          {!isWave && (
            <ConnectFlowButtons
              onConnect={connect}
              onCancel={() => navigateToPreviousPage(document.referrer, connector.id)}
              isConnectButtonDisabled={!isFormValid() || shouldDisable}
            />
          )}
        </FormGroup>
      </form>
    </Stack>
  );
};
