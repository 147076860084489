import React from 'react';
import { useCurrentConnector } from 'hooks/useCurrentConnector';
import { SystemNotificationsContainer } from 'containers/SystemNotifications.container';
import { UserMenuContainer } from 'containers/UserMenu.container';
import { AppHeader } from 'views';

export const HeaderContainer: React.FC = () => {
  const { connector, isLoading } = useCurrentConnector();
  const vendorTitle = connector?.title ?? '';
  const brandingLogo = connector?.vendor?.homepageLogo?.logo;
  const brandingHomepageUrl = connector?.vendor?.homepageLogo?.homepageUrl ?? '/';

  return (
    <AppHeader
      vendorTitle={vendorTitle}
      brandingLogo={brandingLogo}
      brandingHomepageUrl={brandingHomepageUrl}
      isLogoLoading={isLoading}
      connectorId={connector?.connector_base ? connector.connector_base : connector?._id}
      enhancerEnd={
        <>
          <SystemNotificationsContainer />
          <UserMenuContainer />
        </>
      }
    />
  );
};
