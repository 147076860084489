import { useMemo } from 'react';
import { buildStepNames } from 'utils';

type ConnectFlowStepsArgs = {
  hasVendor: boolean;
  connectorTitle: string;
  step: number;
  selectAccount?: { name: string };
  customSteps?: string[];
};

export const useConnectFlowSteps = (args: ConnectFlowStepsArgs) =>
  useMemo(
    () =>
      buildStepNames({
        connectorTitle: args.connectorTitle,
        hasVendor: args.hasVendor,
        selectAccount: args.selectAccount,
        customSteps: args.customSteps,
      }),
    [args.connectorTitle, args.hasVendor, args.selectAccount, args.customSteps]
  );
