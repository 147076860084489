import { isTimePassedSince } from 'utils';

const AnHour = 60 * 60 * 1000;

export const useIsAnHourPastSinceConnection = (timestamp: string) => {
  const isAnHourPastSinceConnection = isTimePassedSince({
    startTime: new Date(timestamp).getTime(),
    nowTime: new Date().getTime(),
    timeRange: AnHour,
  });

  return isAnHourPastSinceConnection;
};
