import { Theme, createTheme } from '@mui/material';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    default: string;
    lightGrey: string;
    lighterGrey: string;
    darkGrey: string;
    darkerGrey: string;
    dark: string;
  }

  interface PaletteOptions {
    systemStatus: {
      default: string;
      attentionComplimentary: string;
      limited: string;
      limitedComplimentary: string;
      failing: string;
      failingComplimentary: string;
    };
  }
}

export const lightTheme: Theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#fbfbfb',
      lightGrey: '#eeeeee',
      lighterGrey: '#f5f8fa',
      darkGrey: '#bbbbbb',
      darkerGrey: '#666',
      dark: '#413940',
    },
    primary: {
      main: '#009fdc',
      light: '#fff',
    },
    info: {
      main: '#009fdc',
    },
    success: {
      main: '#037749',
    },
    warning: {
      main: '#FAB20E',
    },
    secondary: {
      main: '#008681',
    },
    error: {
      main: '#de2510',
      contrastText: '#fff',
    },
    text: {
      primary: '#333',
      secondary: '#a1a1a1',
    },
    systemStatus: {
      default: '#787878',
      attentionComplimentary: '#FFF6C6',
      limited: '#ed6c02',
      limitedComplimentary: '#FFE5C6',
      failing: '#B00020',
      failingComplimentary: '#FFD3C6',
    },
  },
  typography: {
    fontFamily: '"Barlow", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
        contained: {
          minWidth: '5rem',
        },
      },
    },
  },
});
