import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { TestID } from 'testID';
import { Button } from '../Button/Button.view';

export const ErrorView: React.FC<{
  errorTitle?: string;
  errorMessage?: string;
  onActionClick?: () => void;
  actionButtonText?: string;
  open?: boolean;
}> = ({ open, errorMessage, errorTitle, onActionClick, actionButtonText }) => (
  <Grid mt={5} mb={10} container justifyContent="center" textAlign="center" direction="column">
    <Grid item>
      <Typography variant="h4">
        {errorTitle ? errorTitle : 'Oops! Something went wrong.'}
      </Typography>
    </Grid>
    {errorMessage && (
      <Stack
        px={5}
        sx={{
          maxHeight: '45vh',
          overflowY: 'auto',
        }}
      >
        <details
          style={{
            whiteSpace: 'pre-wrap',
            marginTop: '20px',
            textAlign: 'center',
            lineHeight: '25px',
            padding: '0 10%',
          }}
          open={open}
        >
          {errorMessage}
        </details>
      </Stack>
    )}
    {onActionClick && (
      <Grid item mt={4}>
        <Button
          variant="contained"
          onClick={onActionClick}
          data-testid={TestID.ErrorPageTryAgainButton}
        >
          {actionButtonText ?? 'Try again'}
        </Button>
      </Grid>
    )}
  </Grid>
);
