import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

type PageTitleProps = Pick<TypographyProps, 'children' | 'gutterBottom' | 'mt' | 'mb'>;

export const PageTitle: React.FC<PageTitleProps> = ({ children, ...props }) => (
  <Typography variant="h1" sx={{ fontSize: 'h4.fontSize' }} {...props}>
    {children}
  </Typography>
);
