import { AddConnectorCardMode } from 'enums';

export const calculateAddConnectorCardMode = (state?: {
  nextConnectorLogo?: string;
  nextConnectorName?: string;
  centralConnectorName?: string;
  alreadyConnectedCount?: number;
}) => {
  if (state?.nextConnectorLogo && state?.nextConnectorName) {
    return AddConnectorCardMode.NextKnownConnector;
  }

  if (state?.centralConnectorName) {
    return state?.alreadyConnectedCount && state?.alreadyConnectedCount > 1
      ? AddConnectorCardMode.AddAnotherUnknownConnector
      : AddConnectorCardMode.NextUnknownConnector;
  }

  return AddConnectorCardMode.FirstConnector;
};
