import React from 'react';
import { Box } from '@mui/material';
import { useConnectFlowSteps } from 'hooks/useConnectFlowSteps';
import { HorizontalLinearStepper } from 'views';

export const ConnectFlowStepperContainer: React.FC<{
  hasVendor: boolean;
  connectorTitle: string;
  step: number;
  selectAccount?: { name: string };
}> = ({ hasVendor, connectorTitle, step, selectAccount }) => {
  const steps = useConnectFlowSteps({
    hasVendor,
    connectorTitle,
    step,
    selectAccount,
  });

  return (
    <Box mb={4}>
      <HorizontalLinearStepper step={step} steps={steps} />
    </Box>
  );
};
