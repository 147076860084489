import React from 'react';
import { Stack } from '@mui/material';
import { getLogoInset } from 'utils';
import { TestID } from 'testID';
import { lightTheme } from 'themes';
import { PoweredBySesam } from './PoweredBySesam.view';
import { Link } from '../Link/Link.view';

export const BrandHeaderLogo: React.FC<{
  brandingLogo: string;
  connectorId?: string;
  homepageUrl: string;
  vendorTitle: string;
}> = ({ brandingLogo, connectorId = '', homepageUrl, vendorTitle }) => (
  <Stack data-testid={TestID.SuperOfficeLogo}>
    <Link to={homepageUrl} underline="none" title={vendorTitle} height={45}>
      <Stack flexDirection="row" justifyContent="center" alignItems="center">
        <Stack
          sx={{
            marginRight: '6px',
            width: getLogoInset(connectorId).desktop.width,
            [lightTheme.breakpoints.between('xs', 'sm')]: {
              width: getLogoInset(connectorId).mobile.width,
            },
          }}
        >
          <img src={brandingLogo} alt="Site logo" />
        </Stack>
      </Stack>
    </Link>
    <Stack sx={{ position: 'relative', left: '6px' }}>
      <PoweredBySesam />
    </Stack>
  </Stack>
);
