import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { connectFlowStepsSignal } from 'signals/connectFlowSteps.signal';

export const useGAConnectFlowCurrentStep = () =>
  useEffect(() => {
    connectFlowStepsSignal.watch((currentStep) => {
      ReactGA.send({
        hitType: 'pageview',
        page: `${window.location.origin}${window.location.pathname}/step/${currentStep}`,
      });
    });
  }, []);
