import * as React from 'react';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

export const HorizontalLinearStepper: React.FC<{ step?: number; steps: string[] }> = ({
  step,
  steps,
}) => (
  <Box sx={{ width: '100%' }}>
    <Stepper
      activeStep={step}
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'start', md: 'center' },
        gap: 1,
      }}
    >
      {steps.map((label) => {
        const stepProps = {};
        const labelProps = {};

        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  </Box>
);
