import React from 'react';
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useFetchConnectors } from 'hooks/useFetchConnectors';
import { useFetchTenant } from 'hooks/useFetchTenant';
import { CircularLoading } from 'views';
import type { ConnectorId } from 'types';
import { Connectors } from 'types/connector';
import { Tenant } from 'types/tenant';
import { Vendor } from 'types/vendor';

type ContextType = {
  connectors: Connectors;
  vendor?: Vendor;
  tenant?: Tenant | null;
  connectorId: string;
};

export const ValidConnectFlowRouteContainer: React.FC = () => {
  const { connectors, connectorsLoading } = useFetchConnectors();
  const { tenant, isLoading: tenantLoading } = useFetchTenant();
  const { connectorId } = useParams();

  if (
    !connectorId ||
    (!connectorsLoading &&
      (!Object.keys(connectors ?? {}).includes(connectorId) || isEmpty(connectors)))
  ) {
    return <Navigate to={'/'} />;
  }

  const vendor = connectors?.[connectorId]?.vendor;

  if (vendor) {
    vendor._id = connectorId as ConnectorId;
  }

  if (connectorsLoading || tenantLoading) {
    return <CircularLoading />;
  }

  return (
    <Outlet
      context={{ connectors: connectors ?? {}, vendor, tenant, connectorId } satisfies ContextType}
    />
  );
};

export const useContextVendor = () => useOutletContext<ContextType>();
